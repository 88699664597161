var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect, useState } from 'react';
import { useInteriorApi } from './useInteriorApi';
import ReactDOM from 'react-dom';
import { useCreatorApi } from '../useCreatorApi';
import { useStatus } from 'common/statusContext';
import UserContext from 'common/userContext';
function getSelectedInterior(offerOptions, availableOptions) {
    var interiorToSelect = availableOptions.find(function (option) {
        return offerOptions.includes(option.versionId);
    });
    return interiorToSelect
        ? interiorToSelect.versionId
        : availableOptions.filter(function (i) { return i.versionSelected; }).map(function (i) { return i.versionId; })[0];
}
function isFromVCP() {
    return UserContext.isFromVCP() && !UserContext.hasAnyRole('VCP_EMPLOYEE_LEASING');
}
export function useInteriorTab(_a) {
    var carModelId = _a.carModelId, offer = _a.offer, token = _a.token, handleOnTokenChange = _a.handleOnTokenChange, canBeSynced = _a.canBeSynced, onSynced = _a.onSynced, onInteriorChange = _a.onInteriorChange, handleChangeOffer = _a.handleChangeOffer, employeeLeasingMode = _a.employeeLeasingMode;
    var _b = useState(), interiorElements = _b[0], setInteriorElements = _b[1];
    var _c = useState(''), selectedInterior = _c[0], setSelectedInterior = _c[1];
    var interiorApi = useInteriorApi();
    var creatorApi = useCreatorApi(employeeLeasingMode ? { context: 'employeeLeasing' } : undefined);
    var setStatus = useStatus().setStatus;
    var addOptionRequest = creatorApi.addOption;
    function handleChangeSelectedInterior(interior) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, addOptionRequest(offer.id, interior, carModelId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 2:
                        getOfferResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            handleChangeOffer(getOfferResponse);
                            handleOnTokenChange(getOfferResponse.vehicle.tokenConfiguration);
                            setSelectedInterior(getSelectedInterior(getOfferResponse.vehicle.selectedOptions, interiorElements));
                            onInteriorChange();
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        function selectDefaultInterior() {
            return __awaiter(this, void 0, void 0, function () {
                var getInteriorDetailsRequest, getInteriorDetailsResponse_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(offer && carModelId && canBeSynced && token)) return [3 /*break*/, 2];
                            getInteriorDetailsRequest = isFromVCP() ? interiorApi.getInteriorDetailsVcp : interiorApi.getInteriorDetailsDealer;
                            return [4 /*yield*/, getInteriorDetailsRequest(carModelId, token)];
                        case 1:
                            getInteriorDetailsResponse_1 = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setInteriorElements(getInteriorDetailsResponse_1);
                                setSelectedInterior(getSelectedInterior(offer.vehicle.selectedOptions, getInteriorDetailsResponse_1));
                                onSynced();
                            });
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        selectDefaultInterior();
    }, [offer, carModelId, token, canBeSynced]);
    return {
        interiorElements: interiorElements,
        selectedInterior: selectedInterior,
        onSelectedInteriorChange: handleChangeSelectedInterior,
    };
}
