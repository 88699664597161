import { fileNameFrom } from 'common/fileNameFrom';
import { useHttp } from 'http/httpService';
import { creatorContextPath } from '../CreatorContextPath';
import SessionStorage from 'common/sessionStorage';
export function useSummaryApi() {
    var http = useHttp();
    var context = SessionStorage.get('employeeLeasingMode') ? 'employeeLeasing' : 'creator';
    return {
        getConfigurationSheet: function (offerId) {
            return http
                .get("/api/" + creatorContextPath(context) + "/offer/" + offerId + "/vehicle-configuration-sheet", { responseType: 'arraybuffer' })
                .then(function (response) {
                return {
                    data: response.data,
                    filename: fileNameFrom(response.headers['content-disposition'], 'arkusz.pdf'),
                    contentType: response.headers['content-type']
                };
            });
        },
        addAdditionalInformation: function (offerId, additionalInformation) {
            return http
                .post("/api/creator/offer/" + offerId + "/add-additional-information", {
                additionalInformation: additionalInformation,
            })
                .then(function (response) { return response.data; });
        },
        registerOffer: function (offerId) {
            return http
                .post("/api/creator/offer/" + offerId + "/register")
                .then(function (response) { return response.data; });
        },
        calculateEmployeeLeasing: function (offerId, applicationNo) {
            return http
                .post("/api/creator-el/employee/leasing/" + applicationNo + "/calculate", {
                offerid: offerId
            })
                .then(function (response) { return response; });
        },
        getEmployeeLeasingInstallment: function (applicationNo) {
            return http
                .get("/api/creator-el/employee/leasing/calculation/" + applicationNo)
                .then(function (response) { return response; });
        },
        submitEmployeeLeasing: function (applicationNo, offerId) {
            return http
                .post("/api/creator-el/employee-application/" + applicationNo + "/send?offerId=" + offerId)
                .then(function (response) { return response; });
        }
    };
}
