var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { Button } from 'components/button/Button.component';
import { Checkbox } from 'components/checkbox/Checkbox.component';
import { Dialog } from 'components/dialog/Dialog.component';
import { Label } from 'components/label/Label.component';
import React, { useState } from 'react';
import { immutableSort } from 'utils/immutableSort';
import './priceListCheckboxesDialog.css';
export function PriceListCheckboxesDialog(_a) {
    var openPriceListDialog = _a.openPriceListDialog, onCloseDialog = _a.onCloseDialog, availablePriceLists = _a.availablePriceLists, lastThreePriceLists = _a.lastThreePriceLists, onChangeSelectedPriceLists = _a.onChangeSelectedPriceLists;
    var _b = useState([]), selectedPriceList = _b[0], setSelectedPriceList = _b[1];
    var sortedAvailablePriceListNames = availablePriceLists
        ? immutableSort(Object.keys(availablePriceLists), function (priceListNameA, priceListNameB) {
            return priceListNameB.localeCompare(priceListNameA);
        })
        : [];
    function handleOnSubmitClick() {
        onChangeSelectedPriceLists(selectedPriceList);
        onCloseDialog();
    }
    function onChageSelectedPriceList(checked, priceListName) {
        if (checked) {
            setSelectedPriceList(function (currentSelectedPriceList) {
                return __spreadArrays(currentSelectedPriceList, [priceListName]);
            });
        }
        else {
            setSelectedPriceList(function (currentSelectedPriceList) {
                return currentSelectedPriceList.filter(function (priceList) { return priceList !== priceListName; });
            });
        }
    }
    return (React.createElement("div", { className: "priceListCheckboxesDialog" },
        React.createElement(Dialog.RootOpen, { open: openPriceListDialog },
            React.createElement(Dialog.Content, null,
                React.createElement("div", { className: "priceListCheckboxesContentWrapper" },
                    React.createElement("div", { className: "priceListsCheckboxes", "data-testid": 'price-list-checkboxes' }, sortedAvailablePriceListNames
                        ? sortedAvailablePriceListNames.map(function (priceListName) { return (React.createElement("div", { className: "priceListCheckbox " + (lastThreePriceLists.includes(priceListName) ? 'priceListCheckboxDisabled' : ''), key: priceListName },
                            React.createElement(Checkbox.Root, { value: priceListName, id: priceListName, disabled: lastThreePriceLists.includes(priceListName), checked: selectedPriceList.includes(priceListName) ||
                                    lastThreePriceLists.includes(priceListName), onCheckedChange: function (checked) {
                                    return onChageSelectedPriceList(checked, priceListName);
                                } },
                                React.createElement(Checkbox.Indicator, null)),
                            React.createElement(Label, { htmlFor: priceListName }, priceListName))); })
                        : null),
                    React.createElement("div", { className: "priceListCheckboxesActionButton" },
                        React.createElement(Button, { size: 40, onClick: handleOnSubmitClick }, "zatwierd\u017A")))))));
}
