import React from 'react';
import './packageContent.css';
function Root(_a) {
    var _b = _a.isSummary, isSummary = _b === void 0 ? false : _b, children = _a.children;
    return React.createElement("div", { className: "packageContentRoot " + (isSummary ? 'isSummary' : '') }, children);
}
function Element(_a) {
    var name = _a.name, id = _a.id;
    return React.createElement("div", { className: 'packageContentElement' },
        React.createElement("span", { className: 'packageContentElementName' }, name),
        React.createElement("span", { className: 'packageContentElementId' }, id));
}
export var PackageContent = {
    Root: Root,
    Element: Element
};
