import { useHttp } from 'http/httpService';
import { fileNameFrom } from 'common/fileNameFrom';
export function usePriceListApi(_a) {
    var context = (_a === void 0 ? {} : _a).context;
    var http = useHttp();
    function path() {
        switch (context) {
            case 'employeeLeasing':
                return 'creator-el';
            default:
                return 'creator';
        }
    }
    return {
        getPriceListYears: function () {
            return http
                .get("/api/" + path() + "/prices/list-years")
                .then(function (response) { return response.data; });
        },
        getPriceLists: function (size, pageNumber, year) {
            return http
                .get("/api/" + path() + "/prices/list", {
                params: {
                    page: pageNumber,
                    size: size,
                    year: year
                }
            })
                .then(function (response) { return response.data; });
        },
        getImportLog: function () {
            return http
                .get("/api/" + path() + "/prices/log")
                .then(function (response) { return response.data; });
        },
        putSelectedModels: function (priceListName, models) {
            return http
                .put("/api/" + path() + "/prices/" + priceListName + "/import", models)
                .then(function (response) { return response; });
        },
        getPriceListData: function (priceList) {
            return http
                .get("/api/" + path() + "/prices/price/" + priceList)
                .then(function (response) { return response.data; });
        },
        putPublish: function (id, action) {
            return http
                .put("/api/" + path() + "/prices/price/" + id + "/" + action)
                .then(function (response) { return response; });
        },
        getImportStatus: function () {
            return http
                .get("/api/" + path() + "/prices/status")
                .then(function (response) { return response.data; });
        },
        createPrice: function (name) {
            return http
                .post("/api/" + path() + "/prices", {
                name: name
            })
                .then(function (response) { return response; });
        },
        getAvailableModel: function (priceListName) {
            return http
                .get("/api/" + path() + "/prices/" + priceListName + "/available-model/import")
                .then(function (response) { return response.data; });
        },
        putPriceListEditImport: function (name) {
            return http
                .put("/api/" + path() + "/prices", {
                name: name
            })
                .then(function (response) { return response; });
        },
        cancelImport: function (priceListId) {
            return http
                .put("/api/" + path() + "/prices/cancel-import/" + priceListId)
                .then(function (response) { return response; });
        },
        getCsvFile: function () {
            return http
                .get("/api/" + path() + "/prices/log/csv")
                .then(function (response) {
                return {
                    data: response.data,
                    filename: fileNameFrom(response.headers['content-disposition'], 'testestest.csv'),
                    contentType: response.headers['content-type']
                };
            });
        },
        applyPriceList: function (priceList, priceListData) {
            return http
                .put("/api/" + path() + "/prices/" + priceList + "/import/apply", priceListData)
                .then(function (response) { return response; });
        },
        getErrorsImportLogs: function () {
            return http
                .get("/api/" + path() + "/prices/log-errors")
                .then(function (response) { return response.data; });
        },
        getPrices: function (years) {
            return http
                .get("api/" + path() + "/prices", {
                params: {
                    years: years
                }
            })
                .then(function (response) { return response.data; });
        },
        getModels: function (years) {
            return http
                .get("api/" + path() + "/prices/cars", {
                params: {
                    years: years
                }
            })
                .then(function (response) { return response.data; });
        }
    };
}
