import { TextAmount } from 'components/textAmount/TextAmount';
import React from 'react';
import './settlementSection.css';
import UserContext from 'common/userContext';
export function SettlementSection(_a) {
    var settlementSection = _a.settlementSection, onSettlementClick = _a.onSettlementClick;
    var settlements = settlementSection.settlements;
    function canShowAuditDocuments() {
        return UserContext.hasAnyRole('VCP_SALES', 'VCP_ACCOUNTING', 'VCP_AUDIT');
    }
    function handleOnSettlementClick(orderId) {
        if (canShowAuditDocuments()) {
            onSettlementClick(orderId);
        }
        return;
    }
    function hasSomeSettlementCorrection() {
        return settlementSection.settlements.some(function (settlement) { return settlement.rawStatus === 'RECKONING_CORRECTED' || settlement.rawStatus === 'ADJUSTED'; });
    }
    return (settlements ?
        React.createElement("div", { className: 'settlementSectionWrapper', "data-testid": 'settlement-section-table-wrapper' },
            React.createElement("table", { className: "settlementSectionTable" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "settlementSectionHeadCell settlementOrderDateCell", rowSpan: 2 }, "Data zam\u00F3wienia"),
                        React.createElement("th", { className: "settlementSectionHeadCell settlementDOVINCell", rowSpan: 2 },
                            "Dealer Order",
                            React.createElement("br", null),
                            "VIN"),
                        React.createElement("th", { className: "settlementSectionHeadCell settlementCarCell", rowSpan: 2 },
                            "Model",
                            React.createElement("br", null),
                            "Silnik"),
                        React.createElement("th", { className: "settlementSectionHeadCell settlementSpecialOfferCell", rowSpan: 2 },
                            "Typ OS",
                            React.createElement("br", null),
                            "Deklarowana w VISTA"),
                        React.createElement("th", { className: "settlementSectionHeadCell settlementCatalogPriceCell", rowSpan: 2 }, "Cena konfiguracji bez akcesori\u00F3w netto"),
                        hasSomeSettlementCorrection() ? React.createElement("th", { className: "settlementSectionHeadCell settlementCatalogPriceFromInvoiceCell", rowSpan: 2 }, "Cena katalogowa z FV netto") : null,
                        React.createElement("th", { className: "settlementSectionHeadCell settlementClientDiscountNet", rowSpan: 2 }, "\u0141\u0105czny rabat klienta netto"),
                        React.createElement("th", { className: "settlementSectionHeadCell settlementSpecialOfferHeader", colSpan: 2 }, "Oferta Specjalna"),
                        React.createElement("th", { className: "settlementSectionHeadCell settlementFunding", rowSpan: 2 },
                            "Finansowanie",
                            React.createElement("br", null),
                            "Deklarowane w VISTA"),
                        React.createElement("th", { className: "settlementSectionHeadCell settlementStatus", rowSpan: 2 }, "Status")),
                    React.createElement("tr", null,
                        React.createElement("th", { className: "settlementSectionHeadCell settlementClientDiscountPercent" }, "%"),
                        React.createElement("th", { className: "settlementSectionHeadCell settlementAmountNet" }, "kwota netto"))),
                React.createElement("tbody", null, settlements.map(function (settlement, index) { return (React.createElement("tr", { key: index, className: "settlementSectionTableRow " + (canShowAuditDocuments() ? 'clickableSettlementRow' : ''), onClick: function () { return handleOnSettlementClick(settlement.orderId); } },
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementOrderDateCell' }, settlement.orderDate),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementDOVINCell' },
                        settlement.dealerOrder,
                        React.createElement("br", null),
                        settlement.vin),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementCarCell' },
                        settlement.car,
                        " (",
                        settlement.modelYear,
                        ")",
                        React.createElement("br", null),
                        settlement.drive),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementSpecialOfferCell' },
                        settlement.specialOfferType,
                        React.createElement("br", null),
                        settlement.declaredDiscountGroup),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementCatalogPriceCell' },
                        React.createElement(TextAmount, { textAlignEnd: false }, settlement.catalogPriceWithoutAccessoriesNet)),
                    hasSomeSettlementCorrection() ? React.createElement("td", { className: 'settlementSectionBodyCell settlementCatalogPriceFromInvoiceCell' }, settlement.netListPriceWithWebcon ? React.createElement(TextAmount, { textAlignEnd: false }, settlement.netListPriceWithWebcon) : null) : null,
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementClientDiscountNet' },
                        React.createElement(TextAmount, { textAlignEnd: false }, settlement.clientDiscountNet)),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementClientDiscountPercent' },
                        settlement.specialOfferDiscountPercent,
                        " %"),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementAmountNet' },
                        React.createElement(TextAmount, { textAlignEnd: false, style: { textDecoration: settlement.correctedSpecialOfferDiscountAmountNet ? 'line-through' : 'none' } }, settlement.specialOfferDiscountAmountNet),
                        settlement.correctedSpecialOfferDiscountAmountNet ? React.createElement(TextAmount, { textAlignEnd: false }, settlement.correctedSpecialOfferDiscountAmountNet) : null),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementFunding' },
                        settlement.funding,
                        React.createElement("br", null),
                        settlement.fundingVISTA),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementStatus' }, settlement.status))); })))) : null);
}
