import { Dropdown } from 'components/dropdown/Dropdown.component';
import { FileInput } from 'components/fileInput/FileInput';
import { List } from 'components/list/List.component';
import React, { useRef } from 'react';
import { GarbageIcon } from '../GarbageIcon';
import './clientSection.css';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { DatePickerInputWithIcon } from 'components/datepicker/DatePickerInputWithIcon';
import ModelDictionary from 'common/modelDictionary';
function clientPartyTypeDescription(partyType) {
    switch (partyType) {
        case 'INDIVIDUAL':
            return 'Klient indywidualny';
        case 'SELF_EMPLOYED':
            return 'Działalność';
        case 'COMPANY':
            return 'Spółka';
        default:
            return '';
    }
}
export var ClientSection = withCancellation(function (_a) {
    var clientSection = _a.clientSection;
    var parties = clientSection.parties, party = clientSection.party, handleSelectedPartyChange = clientSection.handleSelectedPartyChange, partyCars = clientSection.partyCars, requestDate = clientSection.requestDate, handleChangeRequestDate = clientSection.handleChangeRequestDate, requestAttachments = clientSection.requestAttachments, handleAddRequestAttachment = clientSection.handleAddRequestAttachment, handleDeleteRequestAttachment = clientSection.handleDeleteRequestAttachment, policyAttachments = clientSection.policyAttachments, handleAddPolicAttachment = clientSection.handleAddPolicAttachment, handleDeletePolicyAttachment = clientSection.handleDeletePolicyAttachment, handleFileNameClick = clientSection.handleFileNameClick, isFromPartyCard = clientSection.isFromPartyCard, partyDataFromGusOrKrs = clientSection.partyDataFromGusOrKrs, specialOfferTypes = clientSection.specialOfferTypes, handleSpecialOfferTypeChange = clientSection.handleSpecialOfferTypeChange, selectedSpecialOfferType = clientSection.selectedSpecialOfferType, specialOfferSubtypes = clientSection.specialOfferSubtypes, handleSpecialOfferSubtypeChange = clientSection.handleSpecialOfferSubtypeChange, selectedSpecialOfferSubtype = clientSection.selectedSpecialOfferSubtype;
    var inputRequestFileRef = useRef(null);
    var inputPolicyFileRef = useRef(null);
    var partiesDropdownOptions = parties
        ? parties.map(function (party) {
            return {
                value: party.id,
                label: clientPartyTypeDescription(party.type) + " " + party.name + ", " + party.identificationNumber.type + ": " + party.identificationNumber.value,
            };
        })
        : [];
    var cars = partyCars
        ? partyCars.map(function (car) {
            var modelCode = car.modelId.split('_')[0];
            return {
                // chwilowo nie pokazujemy daty zamówienia
                // orderDate: car.handoverDate,
                handoverDate: car.handoverDate,
                vin: car.vin,
                carModel: ModelDictionary.nameFor(modelCode),
                modelYear: car.modelId.split('_')[1],
            };
        })
        : [];
    var typeDropdownOptions = specialOfferTypes ? specialOfferTypes : [];
    var subtypeDropdownOptions = specialOfferSubtypes;
    function shouldGUSOrKRSDataShow() {
        return party && party.type !== 'INDIVIDUAL';
    }
    return (React.createElement("div", { className: "clientSectionWrapper" },
        React.createElement("div", { className: "clientSectionGridContainer" },
            React.createElement("div", { className: "clientSectionLeftContainer" },
                React.createElement("div", { className: "clientSectionParty" },
                    React.createElement("div", { className: "clientSectionLabel" }, "Strona klienta:"),
                    !isFromPartyCard ? (React.createElement("div", { className: "clientSectionPartyDropdown", "data-testid": "client-section-party-dropdown" },
                        React.createElement(Dropdown, { options: partiesDropdownOptions, name: "clientSectionParty", onChange: handleSelectedPartyChange, placeholder: "Wybierz stron\u0119", noOptionsMessage: "Brak stron" }))) : party ? (React.createElement("div", { className: "clientSectionValue", "data-testid": "client-section-party-value" },
                        party.name,
                        " ",
                        party.identificationNumber.type,
                        ": ",
                        party.identificationNumber.value)) : null,
                    React.createElement("div", { className: 'clientSectionSpecialOfferType' },
                        React.createElement("div", { className: "clientSectionLabel" }, "Typ oferty:"),
                        React.createElement("div", { className: 'clientSectionOfferTypes' },
                            React.createElement("div", { className: 'specialOfferType', "data-testid": "client-section-type" },
                                React.createElement(Dropdown, { options: typeDropdownOptions, name: 'specialOfferType', onChange: handleSpecialOfferTypeChange, placeholder: "Wybierz typ oferty", value: selectedSpecialOfferType })),
                            subtypeDropdownOptions ?
                                React.createElement("div", { className: 'specialOfferSubType', "data-testid": "client-section-subtype" },
                                    React.createElement(Dropdown, { options: subtypeDropdownOptions, name: 'specialOfferType', onChange: handleSpecialOfferSubtypeChange, placeholder: "Wybierz", value: selectedSpecialOfferSubtype }))
                                : null))),
                shouldGUSOrKRSDataShow() ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "clientSectionBusinessType" },
                        React.createElement("div", { className: "clientSectionLabel" }, "Nazwa strony:"),
                        React.createElement("div", { className: "clientSectionValue clientSectionBusinessTypeValue", "data-testid": "client-section-business-name" }, partyDataFromGusOrKrs && partyDataFromGusOrKrs.name ? partyDataFromGusOrKrs.name : 'brak danych'),
                        React.createElement("div", { className: "clientSectionLabel" }, "Charakter dzia\u0142alno\u015Bci strony:"),
                        React.createElement("div", { className: "clientSectionValue clientSectionBusinessTypeValue", "data-testid": "client-section-business-type" }, partyDataFromGusOrKrs && (partyDataFromGusOrKrs.mainPkdCode || partyDataFromGusOrKrs.mainPkdCodeDescription) ? partyDataFromGusOrKrs.mainPkdCode + " " + partyDataFromGusOrKrs.mainPkdCodeDescription : 'brak danych')))) : null,
                React.createElement("div", { className: "clientSectionRequestDate" },
                    React.createElement("div", { className: "clientSectionLabel" }, "Data zapytania:"),
                    React.createElement("div", { className: "clientSectionRequestDatePicker", "data-testid": "client-section-request-date" },
                        React.createElement(DatePickerInputWithIcon, { date: requestDate, dateOnChangeHandler: handleChangeRequestDate, filterWeekend: false })))),
            React.createElement("div", { className: "clientSectionRightContainer" },
                React.createElement("div", { className: "clientSectionCars" },
                    React.createElement("div", { className: "clientSectionLabel" }, "Samochody posiadane przez stron\u0119:"),
                    React.createElement("div", { className: "clientSectionCarsTableWrapper", "data-testid": "client-section-party-cars" },
                        React.createElement("table", { className: "clientSectionCarsTable" },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", { className: "clientSectionCarTableHeader clientSectionCarOrderDateCell" }, "Data wydania"),
                                    React.createElement("th", { className: "clientSectionCarTableHeader" }, "Model"),
                                    React.createElement("th", { className: "clientSectionCarTableHeader clientSectionCarVinCell" }, "VIN"))),
                            React.createElement("tbody", null, cars && cars.length > 0
                                ? cars.map(function (car) { return (React.createElement("tr", { className: "clientSectionCarTableRow", key: car.vin },
                                    React.createElement("td", { className: "clientSectionCarTableCell clientSectionCarOrderDateCell" }, car.handoverDate),
                                    React.createElement("td", { className: "clientSectionCarTableCell" }, car.carModel + " (" + car.modelYear + ")"),
                                    React.createElement("td", { className: "clientSectionCarTableCell clientSectionCarVinCell" }, car.vin))); })
                                : null)))),
                React.createElement("div", { className: "clientSectionRequestAttachments" },
                    React.createElement("div", { className: "clientSectionLabel", "data-testid": "client-section-request-attachment-input" },
                        "Zapytanie*:",
                        React.createElement(FileInput, { acceptFile: ".pdf", fileRef: inputRequestFileRef, onFileChange: handleAddRequestAttachment, label: "DODAJ", value: "" })),
                    React.createElement("div", null, requestAttachments.length > 0 ? (React.createElement(List.Root, null, requestAttachments.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "clientSectionAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName),
                            React.createElement("button", { type: "button", className: "clientSectionGarbageIconWrapper", onClick: function () { return handleDeleteRequestAttachment(file.fileUrl); } },
                                React.createElement(GarbageIcon, null))))); }))) : null)),
                React.createElement("div", { className: "clientSectionPolicyAttachments" },
                    React.createElement("div", { className: "clientSectionLabel" },
                        "Polisa:",
                        React.createElement(FileInput, { acceptFile: ".pdf", fileRef: inputPolicyFileRef, onFileChange: handleAddPolicAttachment, label: "DODAJ", value: "" })),
                    React.createElement("div", null, policyAttachments.length > 0 ? (React.createElement(List.Root, null, policyAttachments.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "clientSectionAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName),
                            React.createElement("button", { type: "button", className: "clientSectionGarbageIconWrapper", onClick: function () { return handleDeletePolicyAttachment(file.fileUrl); } },
                                React.createElement(GarbageIcon, null))))); }))) : null))))));
});
