var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { Button } from 'components/button/Button.component';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { Label } from 'components/label/Label.component';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { TextAmount } from 'components/textAmount/TextAmount';
import { TextInput } from 'components/textInput/TextInput.component';
import { useSummaryApi } from 'pages/creator/summary/useSummaryApi';
import React from 'react';
import './requestSubject.css';
import { components } from 'react-select';
import ModelDictionary from 'common/modelDictionary';
import { longDate } from 'utils/formatLongDate';
import { GarbageIcon } from '../GarbageIcon';
export var formDictionary = {
    CASH: 'Gotówka',
    LEASING: 'Leasing',
    LEASING_OTHER: 'Leasing - inne',
    CFM: 'Finansowanie CFM',
    CFM_OTHER: 'Finansowanie CFM - inne',
};
function CustomOfferOptionComponent(props) {
    var data = props.data;
    return (React.createElement(components.Option, __assign({}, props),
        React.createElement("div", { className: "requestSubjectOfferOptionDropdown" },
            React.createElement("div", null, data.label),
            React.createElement("div", null, data.date),
            React.createElement("div", { style: { overflow: 'hidden', textAlign: 'right' } },
                React.createElement("div", { className: "requestSubjectOfferOptionModelCell" }, data.model)))));
}
export var RequestSubject = withCancellation(function RequestSubject(_a) {
    var requestSubjectSection = _a.requestSubjectSection, index = _a.index, disabled = _a.disabled, id = _a.id;
    var onOfferChange = requestSubjectSection.onOfferChange, onOfferCarAmountChange = requestSubjectSection.onOfferCarAmountChange, onDealerMarginChange = requestSubjectSection.onDealerMarginChange, onFleetCoofinancingChange = requestSubjectSection.onFleetCoofinancingChange, onFinancingChange = requestSubjectSection.onFinancingChange, onLeasingProviderChange = requestSubjectSection.onLeasingProviderChange, onOtherLeasingProviderChange = requestSubjectSection.onOtherLeasingProviderChange, onCfmProviderChange = requestSubjectSection.onCfmProviderChange, onOtherCfmProviderChange = requestSubjectSection.onOtherCfmProviderChange, fundingForms = requestSubjectSection.fundingForms, leasingProviders = requestSubjectSection.leasingProviders, cfmProviders = requestSubjectSection.cfmProviders, availableVCPLevelPercent = requestSubjectSection.availableVCPLevelPercent, availableDealerShareMarginPercent = requestSubjectSection.availableDealerShareMarginPercent, availableOffers = requestSubjectSection.availableOffers, requestSubjects = requestSubjectSection.requestSubjects, allOffers = requestSubjectSection.allOffers, handleRequestSubjectDelete = requestSubjectSection.handleRequestSubjectDelete;
    var summaryApi = useSummaryApi();
    function onShowConfigurationClick() {
        return __awaiter(this, void 0, void 0, function () {
            var getConfigurationSheetResponse, responseBlob, url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, summaryApi.getConfigurationSheet(requestSubjects[index].selectedOffer)];
                    case 1:
                        getConfigurationSheetResponse = _a.sent();
                        responseBlob = new Blob([getConfigurationSheetResponse.data], {
                            type: getConfigurationSheetResponse.contentType,
                        });
                        url = URL.createObjectURL(responseBlob);
                        window.open(url);
                        URL.revokeObjectURL(url);
                        return [2 /*return*/];
                }
            });
        });
    }
    var leasingProvidersDropdownOptions = leasingProviders;
    var cfmProvidersDropdownOptions = cfmProviders;
    var availableOffersDropdownOptions = availableOffers ? availableOffers.filter(function (offer) { return offer.pno12; }).map(function (offer) {
        var modelCode = offer.modelId.split('_')[0];
        return {
            value: offer.id,
            label: offer.offerNo,
            date: longDate(offer.presentationTimestamp),
            model: ModelDictionary.nameFor(modelCode) + " (" + offer.modelId.split('_')[1] + ")",
        };
    }) : [];
    function selectedOfferDropdownValue(offer) {
        var modelCode = offer ? offer.modelId.split('_')[0] : '';
        return requestSubjects[index].selectedOffer ?
            {
                value: offer.id,
                label: offer.offerNo,
                date: longDate(offer.presentationTimestamp),
                model: ModelDictionary.nameFor(modelCode) + " (" + offer.modelId.split('_')[1] + ")",
            } : null;
    }
    function renderProperProviderInput(fundingForm) {
        switch (fundingForm) {
            case 'CASH':
                return null;
            case 'LEASING':
                return (React.createElement(Dropdown, { options: leasingProvidersDropdownOptions, onChange: function (_, option) { return onLeasingProviderChange(_, option, index); }, name: "leasingProvider", disabled: requestSubjects[index]['selectedFinancing'] !== 'LEASING', placeholder: "Dostawca", value: requestSubjects[index].selectedLeasingProvider }));
            case 'LEASING_OTHER':
                return (React.createElement(TextInput, { id: "LEASING_OTHER", name: "LEASING_OTHER", placeholder: "Wprowad\u017A nazw\u0119", onChange: function (event) { return onOtherLeasingProviderChange(event, index); }, value: requestSubjects[index]['otherLeasingProvider'], disabled: requestSubjects[index].selectedFinancing !== 'LEASING_OTHER' }));
            case 'CFM':
                return (React.createElement(Dropdown, { options: cfmProvidersDropdownOptions, onChange: function (_, option) { return onCfmProviderChange(_, option, index); }, name: "CFM", disabled: requestSubjects[index].selectedFinancing !== 'CFM', placeholder: "Dostawca", value: requestSubjects[index].selectedCfmProvider }));
            case 'CFM_OTHER':
                return (React.createElement(TextInput, { id: "CFM_OTHER", name: "CFM_OTHER", placeholder: "Wprowad\u017A nazw\u0119", onChange: function (event) { return onOtherCfmProviderChange(event, index); }, value: requestSubjects[index].otherCfmProvider, disabled: requestSubjects[index].selectedFinancing !== 'CFM_OTHER' }));
            default:
                return null;
        }
    }
    ;
    var availableVCPLevelPercentDropdownOptions = availableVCPLevelPercent.map(function (option) {
        return {
            value: option,
            label: option.toString().replace('.', ',') + "%"
        };
    });
    var availableDealerShareMarginPercentDropdownOptions = availableDealerShareMarginPercent.map(function (option) {
        return {
            value: option,
            label: option.toString().replace('.', ',') + "%"
        };
    });
    var selectedOfferObject = (allOffers && requestSubjects[index].selectedOffer) ? allOffers.find(function (offer) { return offer.id === requestSubjects[index].selectedOffer; }) : undefined;
    return (React.createElement("div", { className: "requestSubjectSectionWrapper" },
        React.createElement("div", { className: 'requestSubjectSectionDeleteButtonWrapper' }, requestSubjects.length > 1 ?
            React.createElement("button", { className: 'requestSubjectSectionDeleteButton', "data-testid": 'delete-request-subject-button', type: 'button', onClick: function () { return handleRequestSubjectDelete(id); } },
                React.createElement(GarbageIcon, null))
            : null),
        React.createElement("div", { className: "requestSubjectGridWrapper" },
            React.createElement("div", { className: "requestSubjectLeftContainer" },
                React.createElement("div", { className: 'requestSubjectOfferDropdownWrapper' },
                    React.createElement("div", { className: "requestSubjectLabel" }, "Oferta:"),
                    React.createElement("div", { className: "requestSubjectOfferDropdown", "data-testid": "request-subject-offer-dropdown" },
                        React.createElement(Dropdown, { options: availableOffersDropdownOptions, name: "offersDropdown", placeholder: "Wybierz ofert\u0119", onChange: function (value) { return onOfferChange(value, index); }, value: selectedOfferDropdownValue(allOffers.find(function (offer) { return offer.id === requestSubjects[index].selectedOffer; })), CustomOptionComponent: CustomOfferOptionComponent, disabled: disabled, noOptionsMessage: "Brak ofert" }))),
                React.createElement("div", { className: "requestSubjectOfferRow" },
                    React.createElement("div", { className: "requestSubjectLabel" }, "PNO12:"),
                    React.createElement("div", { className: "requestSubjectValue", "data-testid": "request-subject-pno" }, (requestSubjects[index].selectedOffer && selectedOfferObject) ? selectedOfferObject.pno12 : '')),
                React.createElement("div", { className: "requestSubjectOfferRow" },
                    React.createElement("div", { className: "requestSubjectLabel" }, "Samoch\u00F3d:"),
                    React.createElement("div", { className: "requestSubjectValue", "data-testid": "request-subject-car" }, (requestSubjects[index].selectedOffer && selectedOfferObject)
                        ? ModelDictionary.nameFor(allOffers.find(function (offer) { return offer.id === requestSubjects[index].selectedOffer; }).modelId.split('_')[0])
                        : '')),
                React.createElement("div", { className: "requestSubjectOfferRow" },
                    React.createElement("div", { className: "requestSubjectLabel" }, "Ilo\u015B\u0107 aut:"),
                    React.createElement("div", { className: "requestSubjectCarAmountInput", "data-testid": 'request-subject-amount' },
                        React.createElement(TextInput, { name: "offerCarAmount", id: "offerCarAmount", onChange: function (event) { return onOfferCarAmountChange(event, index); }, placeholder: "Sztuk", value: requestSubjects[index].offerCarAmount, disabled: !requestSubjects[index].selectedOffer }))),
                React.createElement("div", { className: "requestSubjectOfferRow" },
                    React.createElement("div", { className: "requestSubjectLabel" }, "Silnik:"),
                    React.createElement("div", { className: "requestSubjectValue", "data-testid": "request-subject-drive" }, (requestSubjects[index].selectedOffer && selectedOfferObject) ? selectedOfferObject.engine : '')),
                React.createElement("div", { className: "requestSubjectOfferRow" },
                    React.createElement("div", { className: "requestSubjectLabel" }, "Wersja wyposa\u017Cenia:"),
                    React.createElement("div", { className: "requestSubjectValue", "data-testid": "request-subject-version" }, (requestSubjects[index].selectedOffer && selectedOfferObject) ? selectedOfferObject.version : '')),
                React.createElement("div", { className: "requestSubjectOfferRow" },
                    React.createElement("div", { className: "requestSubjectLabel" }, "Status:"),
                    React.createElement("div", { className: "requestSubjectValue", "data-testid": "request-subject-status" }, "Nowy")),
                React.createElement("div", { className: "requestSubjectButtonWrapper" },
                    React.createElement(Button, { type: "button", disabled: !requestSubjects[index].selectedOffer, variant: "secondary", size: 32, onClick: onShowConfigurationClick }, "wy\u015Bwietl konfiguracj\u0119"))),
            React.createElement("div", { className: "requestSubjectRightContainer", "data-testid": "request-subject-percentages-table" },
                React.createElement("table", { className: "requestSubjectFinancingDataTable" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "requestSubjectFinancingDataHeader requestSubjectCategoryCell" }, "Kategoria"),
                            React.createElement("th", { className: "requestSubjectFinancingDataHeader requestSubjectPercentCell" }, "Procent"),
                            React.createElement("th", { className: "requestSubjectFinancingDataHeader requestSubjectAmountHeaderCell" }, "Kwota"))),
                    React.createElement("tbody", null,
                        React.createElement("tr", { className: "requestSubjectFinancingDataRow" },
                            React.createElement("td", { className: "requestSubjectCategoryCell" }, "Cena katalogowa netto:"),
                            React.createElement("td", { className: "requestSubjectPercentCell" }),
                            React.createElement("td", { className: "requestSubjectAmountCell" },
                                React.createElement(TextAmount, null, (requestSubjects[index].selectedOffer && selectedOfferObject) ? selectedOfferObject.listPriceNet : ''))),
                        React.createElement("tr", { className: "requestSubjectFinancingDataRow" },
                            React.createElement("td", { className: "requestSubjectCategoryCell" }, "Cena katalogowa brutto:"),
                            React.createElement("td", { className: "requestSubjectPercentCell" }),
                            React.createElement("td", { className: "requestSubjectAmountCell" },
                                React.createElement(TextAmount, null, (requestSubjects[index].selectedOffer && selectedOfferObject) ? selectedOfferObject.listPriceGross : ''))),
                        React.createElement("tr", { className: "requestSubjectFinancingDataRow" },
                            React.createElement("td", { className: "requestSubjectCategoryCell" }, "Udzia\u0142 mar\u017Cy Dealera w rabacie dla klienta netto:"),
                            React.createElement("td", { className: "requestSubjectPercentCell" },
                                React.createElement(Dropdown, { options: availableDealerShareMarginPercentDropdownOptions, onChange: function (_, option) { return onDealerMarginChange(_, option, index); }, name: "dealerMargin", placeholder: "%", value: requestSubjects[index].selectedDealerMargin, disabled: !requestSubjects[index].selectedOffer })),
                            React.createElement("td", { className: "requestSubjectAmountCell" },
                                React.createElement(TextAmount, null, requestSubjects[index].percentagesValues ? requestSubjects[index].percentagesValues.dealerShareMarginNetto : ''))),
                        React.createElement("tr", { className: "requestSubjectFinancingDataRow" },
                            React.createElement("td", { className: "requestSubjectCategoryCell" }, "Poziom dofinansowania flotowego VCP netto:"),
                            React.createElement("td", { className: "requestSubjectPercentCell" },
                                React.createElement(Dropdown, { options: availableVCPLevelPercentDropdownOptions, onChange: function (_, option) { return onFleetCoofinancingChange(_, option, index); }, name: "fleetCoofinancing", placeholder: "%", value: requestSubjects[index].selectedFleetCoofinancing, disabled: !requestSubjects[index].selectedOffer })),
                            React.createElement("td", { className: "requestSubjectAmountCell" },
                                React.createElement(TextAmount, null, requestSubjects[index].percentagesValues ? requestSubjects[index].percentagesValues.vcpLevelNetto : ''))),
                        React.createElement("tr", { className: "requestSubjectFinancingDataRow" },
                            React.createElement("td", { className: "requestSubjectCategoryCell" }, "Oczekiwana cena ko\u0144cowa netto:"),
                            React.createElement("td", { className: "requestSubjectPercentCell" }),
                            React.createElement("td", { className: "requestSubjectAmountCell" },
                                React.createElement(TextAmount, null, requestSubjects[index].percentagesValues ? requestSubjects[index].percentagesValues.finalPriceNetto : ''))),
                        React.createElement("tr", { className: "requestSubjectFinancingDataRow" },
                            React.createElement("td", { className: "requestSubjectCategoryCell" }, "Oczekiwana cena ko\u0144cowa brutto:"),
                            React.createElement("td", { className: "requestSubjectPercentCell" }),
                            React.createElement("td", { className: "requestSubjectAmountCell" },
                                React.createElement(TextAmount, null, requestSubjects[index].percentagesValues ? requestSubjects[index].percentagesValues.finalPriceGross : ''))))))),
        React.createElement("div", { className: 'requestSubjectFinancingWrapper' },
            React.createElement("div", { className: "requestSubjectFinancingLabel" }, "Rodzaj finansowania:"),
            React.createElement(RadioGroup.Root, { onValueChange: function (value) { return onFinancingChange(value, index); }, value: requestSubjects[index].selectedFinancing },
                React.createElement("div", { className: "requestSubjectFinancingInputsWrapper" }, fundingForms.map(function (form) { return (React.createElement("div", { className: "requestSubjectFinancingRow", key: form },
                    React.createElement("div", { className: "requestSubjectRadioLayout" },
                        React.createElement(RadioGroup.Item, { id: form, value: form },
                            React.createElement(RadioGroup.Indicator, null)),
                        React.createElement(Label, { htmlFor: form, cursor: "pointer" }, formDictionary[form] ? formDictionary[form] : form)),
                    React.createElement("div", { className: "requestSubjectFinancingDropdown", "data-testid": 'request-subject-funding-provider' }, renderProperProviderInput(form)))); }))))));
});
