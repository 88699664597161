import { useHttp } from 'http/httpService';
import SessionStorage from 'common/sessionStorage';
import { creatorContextPath } from '../CreatorContextPath';
export function useCarPreviewApi() {
    var http = useHttp();
    var context = SessionStorage.get('employeeLeasingMode') ? 'employeeLeasing' : 'creator';
    return {
        getColorVcp: function (token) {
            return http
                .get("api/" + creatorContextPath(context) + "/car/images/vcp?token=" + token)
                .then(function (response) { return response.data; });
        },
        getColor: function (token) {
            return http
                .get("api/" + creatorContextPath(context) + "/car/images?token=" + token)
                .then(function (response) { return response.data; });
        }
    };
}
