import { useHttp } from 'http/httpService';
import { creatorContextPath } from './CreatorContextPath';
export function useCreatorApi(_a) {
    var context = (_a === void 0 ? {} : _a).context;
    var http = useHttp();
    return {
        createOffer: function (offerId, carModelId) {
            return http
                .post("/api/" + creatorContextPath(context) + "/offer", {
                id: offerId,
                carModelId: carModelId
            }).then(function (response) { return response; });
        },
        createOfferVcp: function (offerId, carModelId) {
            return http
                .post("/api/" + creatorContextPath(context) + "/offer/vcp", {
                id: offerId,
                carModelId: carModelId
            }).then(function (response) { return response; });
        },
        createOfferWithClientContext: function (offerId, carModelId, clientId) {
            return http
                .post("/api/" + creatorContextPath(context) + "/offer/with-client-context", {
                id: offerId,
                carModelId: carModelId,
                clientId: clientId
            }).then(function (response) { return response; });
        },
        createOfferVcpWithClientContext: function (offerId, carModelId, clientId) {
            return http
                .post("/api/" + creatorContextPath(context) + "/offer/vcp/with-client-context", {
                id: offerId,
                carModelId: carModelId,
                clientId: clientId
            }).then(function (response) { return response; });
        },
        createOfferWithPartyClientContext: function (offerId, carModelId, clientId, partyId) {
            return http
                .post("/api/" + creatorContextPath(context) + "/offer/with-client-context/party", {
                id: offerId,
                carModelId: carModelId,
                clientId: clientId,
                partyId: partyId
            }).then(function (response) { return response; });
        },
        createOfferVcpWithPartyClientContext: function (offerId, carModelId, clientId, partyId) {
            return http
                .post("/api/" + creatorContextPath(context) + "/offer/vcp/with-client-context/party", {
                id: offerId,
                carModelId: carModelId,
                clientId: clientId,
                partyId: partyId
            }).then(function (response) { return response; });
        },
        getOffer: function (offerId) {
            return http
                .get("/api/" + creatorContextPath(context) + "/offer/" + offerId)
                .then(function (response) { return response === null || response === void 0 ? void 0 : response.data; });
        },
        addOption: function (offerId, optionId, carModelId, price) {
            return http
                .put("/api/" + creatorContextPath(context) + "/offer/" + offerId + "/car/option/" + optionId, {
                carModelId: carModelId,
                price: price
            })
                .then(function (response) { return response; });
        },
        deleteOption: function (offerId, optionId, carModelId) {
            return http["delete"]("/api/" + creatorContextPath(context) + "/offer/" + offerId + "/car/option/" + optionId, {
                data: { carModelId: carModelId }
            })
                .then(function (response) { return response; });
        },
        selectSubOption: function (offerId, packageId, elementId, carModelId) {
            return http
                .put("/api/" + creatorContextPath(context) + "/offer/" + offerId + "/car/option/" + packageId + "/sub-option/" + elementId, {
                carModelId: carModelId
            })
                .then(function (response) { return response; });
        },
        addCustomOption: function (offerId, id, price, name, type) {
            return http
                .put("/api/" + creatorContextPath(context) + "/offer/" + offerId + "/car/custom-option", {
                id: id,
                name: name,
                type: type,
                price: price
            })
                .then(function (response) { return response; });
        },
        deleteCustomOption: function (offerId, optionId) {
            return http["delete"]("/api/" + creatorContextPath(context) + "/offer/" + offerId + "/car/custom-option/" + optionId)
                .then(function (response) { return response; });
        },
        selectCarVersion: function (offerId, carModelId, token, type) {
            return http
                .put("/api/" + creatorContextPath(context) + "/offer/" + offerId + "/car/version?screen=" + type, {
                carModelId: carModelId,
                token: token
            });
        },
        createOfferFromConfigurationLink: function (link, id) {
            return http
                .post("/api/" + creatorContextPath(context) + "/offer/by-configuration-link", {
                link: link,
                id: id
            })
                .then(function (response) { return response.data; });
        },
        getClientPartiesWithIdentificationNumber: function (clientId) {
            return http
                .get("/api/clients/" + clientId + "/parties?withIdentificationNumber")
                .then(function (response) { return response.data; });
        },
        setParty: function (offerId, partyId) {
            return http
                .put("/api/" + creatorContextPath(context) + "/offer/" + offerId + "/client/party/" + partyId);
        },
        setPartyVcp: function (offerId, partyId) {
            return http
                .put("/api/" + creatorContextPath(context) + "/offer/vcp/" + offerId + "/client/party/" + partyId);
        },
        cloneOffer: function (offerIdToClone, newOfferId) {
            return http
                .post("/api/" + creatorContextPath(context) + "/offer/clone", { id: offerIdToClone, newId: newOfferId });
        },
        createOfferByConfigurationLinkAndContext: function (link, id, clientId, partyId) {
            return http
                .post("/api/" + creatorContextPath(context) + "/offer/by-configuration-link-with-client-and-party", {
                link: link,
                id: id,
                clientId: clientId,
                partyId: partyId
            });
        }
    };
}
