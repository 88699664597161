var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect } from 'react';
import { Accordion } from 'components/accordion/Accordion.component';
import { List } from 'components/list/List.component';
import { HoverCard } from 'components/hoverCard/HoverCard.component';
import { HoverCardTriggerWrapper } from 'pages/demo/components/hoverCardTriggerWrapper/HoverCardTriggerWrapper.component';
import { Checkbox } from 'components/checkbox/Checkbox.component';
import { Label } from 'components/label/Label.component';
import { ListItemElement } from 'components/listItemElement/ListItemElement.component';
import { OptionStandardChip } from 'components/chip/OptionStandardChip.component';
import { ChipPlaceholder } from 'components/chip/ChipPlaceholder.component';
import userContext from 'common/userContext';
import { LackOfPriceChip } from 'components/chip/LackOfPriceChip.component';
import { TextAmount } from 'components/textAmount/TextAmount';
import { TextAmountPlaceholder } from 'components/textAmount/TextAmountPlaceholder';
import { HoverCardPackageContent } from 'pages/demo/components/hoverCardPackageContent/HoverCardPackageContent.component';
import { PackageContent } from 'pages/demo/components/packageContent/PackageContent.component';
import { AmountInput } from 'components/amountInput/AmountInput.component';
import { Accessories } from 'components/accessories/Accesssories.component';
import { immutableSort } from 'utils/immutableSort';
import './accessoriesTab.css';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { LayoutWithWheels } from 'pages/demo/components/layoutRadioButtonsWithWheels/LayoutRadioButtonsWithWheels.component';
function isFromVCP() {
    return userContext.isFromVCP();
}
export function AccessoriesTab(_a) {
    var accessories = _a.accessories, selectedAccessoryPackages = _a.selectedAccessoryPackages, onSelectedAccessoryPackageChange = _a.onSelectedAccessoryPackageChange, selectedAccessories = _a.selectedAccessories, onSelectedAccessoryChange = _a.onSelectedAccessoryChange, additionalAccessories = _a.additionalAccessories, onAdditionalAccessoryAdd = _a.onAdditionalAccessoryAdd, onAdditionalAccessoryDelete = _a.onAdditionalAccessoryDelete, additionalServices = _a.additionalServices, onAdditionalServicesAdd = _a.onAdditionalServicesAdd, onAdditionalServicesDelete = _a.onAdditionalServicesDelete, onAccessoryPriceChange = _a.onAccessoryPriceChange, debouncedChangeAccessoryPrice = _a.debouncedChangeAccessoryPrice, accessoriessCustomPrices = _a.accessoriessCustomPrices, selectedWinterWheels = _a.selectedWinterWheels, handleChangeSelectedWinterWheels = _a.handleChangeSelectedWinterWheels;
    // pakiety
    var packageAccessories = accessories.find(function (accessory) { return accessory.category.toUpperCase() === 'PAKIETY'; });
    var sortedPackageAccessories = packageAccessories
        ? immutableSort(packageAccessories.accessories, function (packageAccessoryA, packageAccessoryB) {
            return packageAccessoryA.name.localeCompare(packageAccessoryB.name);
        })
        : [];
    var sortedPackagesAccessoriesWithSortedContent = sortedPackageAccessories.map(function (accessory) {
        return __assign(__assign({}, accessory), { content: immutableSort(accessory.content, function (contentOptionA, contentOptionB) { return contentOptionA.name.localeCompare(contentOptionB.name); }) });
    });
    // koła zimowe
    var winterWheelsAccessories = accessories.find(function (accessory) { return accessory.category.toUpperCase() === 'KOŁA ZIMOWE'; });
    var sortedWinterWheelsAccessories = winterWheelsAccessories
        ? immutableSort(winterWheelsAccessories.accessories, function (winterWheelsAccessoryA, winterWheelsAccessoryB) {
            return winterWheelsAccessoryA.name.localeCompare(winterWheelsAccessoryB.name);
        })
        : [];
    var winterWheels = winterWheelsAccessories && __assign(__assign({}, winterWheelsAccessories), { accessories: sortedWinterWheelsAccessories });
    // akcesoria
    var restAccessories = accessories.filter(function (accessory) {
        return accessory.category.toUpperCase() !== 'POZOSTAŁE' && accessory.category.toUpperCase() !== 'PAKIETY' && accessory.category.toUpperCase() !== 'KOŁA ZIMOWE';
    });
    var sortedRestAccessories = restAccessories
        ? immutableSort(restAccessories, function (accessoryA, accessoryB) {
            return accessoryA.category.localeCompare(accessoryB.category);
        })
        : [];
    var sortedRestAccessoriesWithSortedOptions = sortedRestAccessories.map(function (accessory) {
        return __assign(__assign({}, accessory), { accessories: immutableSort(accessory.accessories, function (accessoryA, accessoryB) {
                return accessoryA.name.localeCompare(accessoryB.name);
            }) });
    });
    // pozostałe
    var remainingAccessories = accessories.find(function (accessory) { return accessory.category.toUpperCase() === 'POZOSTAŁE'; });
    var sortedRemainingAccessories = remainingAccessories
        ? immutableSort(remainingAccessories.accessories, function (accessoryA, accessoryB) {
            return accessoryA.name.localeCompare(accessoryB.name);
        })
        : [];
    var remainings = remainingAccessories && __assign(__assign({}, remainingAccessories), { accessories: sortedRemainingAccessories });
    useEffect(function () {
        return function () {
            debouncedChangeAccessoryPrice.cancel();
        };
    }, []);
    return (React.createElement("div", { className: "accessoriesWrapper" }, accessories ? (React.createElement(Accordion.Root, { defaultValue: __spreadArrays(accessories.map(function (accessory) { return accessory.category; }), [
            'completingConfigurationAccessory',
            'completingConfigurationServices',
        ]) },
        packageAccessories ? (React.createElement(Accordion.Item, { value: packageAccessories.category, key: packageAccessories.category },
            React.createElement(Accordion.Trigger, null, packageAccessories.category),
            React.createElement(Accordion.Content, null,
                React.createElement(List.Root, null, sortedPackagesAccessoriesWithSortedContent.map(function (accessory) {
                    return selectedAccessoryPackages.indexOf(accessory.id) < 0 ? (React.createElement(HoverCard.Root, { key: accessory.id },
                        React.createElement(List.Item, { isPrice: accessory.price !== null, onClick: function (event) {
                                if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                    return;
                                }
                                onSelectedAccessoryPackageChange(!selectedAccessoryPackages.includes(accessory.id), accessory.id, accessory.price);
                            } },
                            React.createElement(HoverCard.Trigger, { asChild: true },
                                React.createElement(HoverCardTriggerWrapper, null,
                                    React.createElement(List.LayoutWithCodeAndChips, null,
                                        React.createElement(Checkbox.Layout, null,
                                            React.createElement(Checkbox.Root, { checked: selectedAccessoryPackages.includes(accessory.id), value: accessory.id, id: accessory.id, onCheckedChange: function (checked) {
                                                    return onSelectedAccessoryPackageChange(checked, accessory.id, accessory.price);
                                                } },
                                                React.createElement(Checkbox.Indicator, null)),
                                            React.createElement(Label, { htmlFor: accessory.id }, accessory.name)),
                                        React.createElement(ListItemElement.OptionCode, null, accessory.optionNo),
                                        React.createElement(ListItemElement.Chips, null,
                                            accessory.standard ? (React.createElement(OptionStandardChip, null)) : (React.createElement(ChipPlaceholder, null)),
                                            React.createElement(ChipPlaceholder, null)),
                                        React.createElement(ListItemElement.Price, null, accessory.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, accessory.price)) : accessory.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, accessory.price)))))),
                            React.createElement(HoverCard.Content, { align: "start", alignOffset: 190, sideOffset: -10 },
                                React.createElement(HoverCardPackageContent.Root, null, accessory.content.map(function (accessoryPackage) { return (React.createElement(HoverCardPackageContent.Element, { key: accessoryPackage.id, name: accessoryPackage.name, id: accessoryPackage.optionNo })); })))))) : (React.createElement(List.Item, { isPrice: accessory.price !== null, key: accessory.id, isSelected: true, onClick: function (event) {
                            if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                return;
                            }
                            onSelectedAccessoryPackageChange(!selectedAccessoryPackages.includes(accessory.id), accessory.id, accessory.price);
                        } },
                        React.createElement(List.LayoutWithCodeAndChips, null,
                            React.createElement(Checkbox.Layout, null,
                                React.createElement(Checkbox.Root, { checked: selectedAccessoryPackages.includes(accessory.id), value: accessory.id, id: accessory.id, onCheckedChange: function (checked) {
                                        return onSelectedAccessoryPackageChange(checked, accessory.id, accessory.price);
                                    } },
                                    React.createElement(Checkbox.Indicator, null)),
                                React.createElement(Label, { htmlFor: accessory.id }, accessory.name)),
                            React.createElement(ListItemElement.OptionCode, null, accessory.optionNo),
                            React.createElement(ListItemElement.Chips, null,
                                accessory.standard ? (React.createElement(OptionStandardChip, null)) : (React.createElement(ChipPlaceholder, null)),
                                React.createElement(ChipPlaceholder, null)),
                            React.createElement(ListItemElement.Price, null, accessory.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, accessory.price)) : accessory.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, accessory.price)))),
                        React.createElement(PackageContent.Root, null, accessory.content.map(function (element) { return (React.createElement(PackageContent.Element, { key: element.id, name: element.name, id: element.optionNo })); }))));
                }))))) : null,
        winterWheelsAccessories ?
            React.createElement(Accordion.Item, { value: winterWheelsAccessories.category },
                React.createElement(Accordion.Trigger, null, winterWheelsAccessories.category),
                React.createElement(Accordion.Content, null,
                    React.createElement(RadioGroup.Root, { value: selectedWinterWheels, onValueChange: function (value) {
                            var relatedAccessoryItem = winterWheels.accessories.find(function (a) { return a.id === value; });
                            handleChangeSelectedWinterWheels(value, relatedAccessoryItem ? relatedAccessoryItem.price : undefined);
                        } },
                        React.createElement(List.Root, null,
                            React.createElement(List.AccessoriesListHeading, null),
                            React.createElement(List.Item, { isSelected: selectedWinterWheels === 'any', onClick: function (event) {
                                    if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                        return;
                                    }
                                    handleChangeSelectedWinterWheels('any');
                                }, height: "fixed", key: 'any' },
                                React.createElement("div", { style: { display: 'flex', alignItems: 'center', gap: '10px' } },
                                    React.createElement(RadioGroup.Item, { value: 'any', id: 'any' },
                                        React.createElement(RadioGroup.Indicator, null)),
                                    React.createElement(Label, { htmlFor: 'any' }, "Brak"))),
                            winterWheels.accessories.map(function (accessory) {
                                var _a;
                                return (React.createElement(List.Item, { key: accessory.id, isPrice: accessory.price !== null, isSelected: selectedWinterWheels === accessory.id, height: "fixed", onClick: function (event) {
                                        if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                            return;
                                        }
                                        handleChangeSelectedWinterWheels(accessory.id, accessory.price);
                                    } },
                                    React.createElement(List.LayoutWithAmountInput, null,
                                        React.createElement(LayoutWithWheels, { imageSrc: '' },
                                            React.createElement(RadioGroup.Item, { value: accessory.id, id: accessory.id },
                                                React.createElement(RadioGroup.Indicator, null)),
                                            React.createElement(Label, { htmlFor: accessory.id }, accessory.name)),
                                        React.createElement(ListItemElement.OptionCode, null, accessory.optionNo),
                                        React.createElement(ListItemElement.CatalogPrice, null,
                                            React.createElement(TextAmount, { bold: true }, accessory.price)),
                                        React.createElement(AmountInput, { max: accessory.price, name: accessory.name, defaultValue: (_a = accessoriessCustomPrices[accessory.id]) !== null && _a !== void 0 ? _a : accessory.price, onValueChange: function (values) { return onAccessoryPriceChange(values, accessory.id); }, disabled: selectedWinterWheels !== accessory.id }))));
                            })))))
            : null,
        sortedRestAccessoriesWithSortedOptions.map(function (accesoryCategory) { return (React.createElement(Accordion.Item, { value: accesoryCategory.category, key: accesoryCategory.category },
            React.createElement(Accordion.Trigger, null, accesoryCategory.category),
            React.createElement(Accordion.Content, null,
                React.createElement(List.Root, null,
                    React.createElement(List.AccessoriesListHeading, null),
                    accesoryCategory.accessories.map(function (accessory) {
                        var _a;
                        return (React.createElement(List.Item, { key: accessory.id, isPrice: accessory.price !== null, isSelected: selectedAccessories.includes(accessory.id), cursor: accessory.standard ? 'default' : 'pointer', height: "fixed", onClick: function (event) {
                                var _a;
                                if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                    return;
                                }
                                if (!accessory.standard) {
                                    var accessoryPrice = (_a = accessoriessCustomPrices[accessory.id]) !== null && _a !== void 0 ? _a : accessory.price;
                                    onSelectedAccessoryChange(!selectedAccessories.includes(accessory.id), accessory.id, accessoryPrice);
                                }
                                else {
                                    return undefined;
                                }
                            } },
                            React.createElement(List.LayoutWithAmountInput, null,
                                React.createElement(Checkbox.Layout, null,
                                    accessory.standard ? (React.createElement(Checkbox.Placeholder, null)) : (React.createElement(Checkbox.Root, { checked: selectedAccessories.includes(accessory.id), id: accessory.id, value: accessory.id, onCheckedChange: function (checked) {
                                            var _a;
                                            var accessoryPrice = (_a = accessoriessCustomPrices[accessory.id]) !== null && _a !== void 0 ? _a : accessory.price;
                                            onSelectedAccessoryChange(checked, accessory.id, accessoryPrice);
                                        } },
                                        React.createElement(Checkbox.Indicator, null))),
                                    React.createElement(Label, { htmlFor: accessory.id }, accessory.name)),
                                React.createElement(ListItemElement.OptionCode, null, accessory.optionNo),
                                React.createElement(ListItemElement.CatalogPrice, null,
                                    React.createElement(TextAmount, { bold: true }, accessory.price)),
                                React.createElement(AmountInput, { max: accessory.price, name: accessory.name, defaultValue: (_a = accessoriessCustomPrices[accessory.id]) !== null && _a !== void 0 ? _a : accessory.price, onValueChange: function (values) { return onAccessoryPriceChange(values, accessory.id); }, disabled: !selectedAccessories.includes(accessory.id) }))));
                    }))))); }),
        remainingAccessories ? (React.createElement(Accordion.Item, { value: remainingAccessories.category, key: remainingAccessories.category },
            React.createElement(Accordion.Trigger, null, remainingAccessories.category),
            React.createElement(Accordion.Content, null,
                React.createElement(List.Root, null,
                    React.createElement(List.AccessoriesListHeading, null),
                    remainings.accessories.map(function (accessory) {
                        var _a;
                        return (React.createElement(List.Item, { key: accessory.id, isPrice: accessory.price !== null, isSelected: selectedAccessories.includes(accessory.id), cursor: accessory.standard ? 'default' : 'pointer', height: "fixed", onClick: function (event) {
                                var _a;
                                if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                    return;
                                }
                                if (!accessory.standard) {
                                    var accessoryPrice = (_a = accessoriessCustomPrices[accessory.id]) !== null && _a !== void 0 ? _a : accessory.price;
                                    onSelectedAccessoryChange(!selectedAccessories.includes(accessory.id), accessory.id, accessoryPrice);
                                }
                                else {
                                    return undefined;
                                }
                            } },
                            React.createElement(List.LayoutWithAmountInput, null,
                                React.createElement(Checkbox.Layout, null,
                                    accessory.standard ? (React.createElement(Checkbox.Placeholder, null)) : (React.createElement(Checkbox.Root, { checked: selectedAccessories.includes(accessory.id), id: accessory.id, value: accessory.id, onCheckedChange: function (checked) {
                                            var _a;
                                            var accessoryPrice = (_a = accessoriessCustomPrices[accessory.id]) !== null && _a !== void 0 ? _a : accessory.price;
                                            onSelectedAccessoryChange(checked, accessory.id, accessoryPrice);
                                        } },
                                        React.createElement(Checkbox.Indicator, null))),
                                    React.createElement(Label, { htmlFor: accessory.id }, accessory.name)),
                                React.createElement(ListItemElement.OptionCode, null, accessory.optionNo),
                                React.createElement(ListItemElement.CatalogPrice, null,
                                    React.createElement(TextAmount, { bold: true }, accessory.price)),
                                React.createElement(AmountInput, { max: accessory.price, name: accessory.name, defaultValue: (_a = accessoriessCustomPrices[accessory.id]) !== null && _a !== void 0 ? _a : accessory.price, onValueChange: function (values) { return onAccessoryPriceChange(values, accessory.id); }, disabled: !selectedAccessories.includes(accessory.id) }))));
                    }))))) : null,
        React.createElement(Accordion.Item, { value: "completingConfigurationAccessory" },
            React.createElement(Accordion.Trigger, null, "AKCESORIA - UZUPE\u0141NIENIE KONFIGURACJI"),
            React.createElement(Accordion.Content, null,
                React.createElement(Accessories, { accessories: additionalAccessories, onAccessoryAdd: onAdditionalAccessoryAdd, onAccessoryDelete: onAdditionalAccessoryDelete, placeholder: "Nazwa akcesorium", validationMessage: "Przed dodaniem kolejnego komponentu uzupe\u0142nij nazw\u0119 akcesorium i jego cen\u0119." }))),
        React.createElement(Accordion.Item, { value: "completingConfigurationServices" },
            React.createElement(Accordion.Trigger, null, "US\u0141UGI - UZUPE\u0141NIENIE KONFIGURACJI"),
            React.createElement(Accordion.Content, null,
                React.createElement(Accessories, { accessories: additionalServices, onAccessoryAdd: onAdditionalServicesAdd, onAccessoryDelete: onAdditionalServicesDelete, placeholder: "Nazwa us\u0142ugi", validationMessage: "Przed dodaniem kolejnego komponentu uzupe\u0142nij nazw\u0119 us\u0142ugi i jej cen\u0119." }))))) : null));
}
