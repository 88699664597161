var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useStatus } from 'common/statusContext';
import { sameMembers } from 'pages/priceListImport/priceListImportContainer/rangeOfModels/RangeOfModels.component';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useFinancialProductsApi } from '../useFinancialProductsApi';
export function useExclusionsTab(_a) {
    var productDetails = _a.productDetails, productId = _a.productId, handleChangeProductDetails = _a.handleChangeProductDetails;
    var _b = useState(undefined), models = _b[0], setModels = _b[1];
    var _c = useState(undefined), powerTrains = _c[0], setPowerTrains = _c[1];
    var _d = useState(false), selectedAllModels = _d[0], setSelectedAllModels = _d[1];
    var _e = useState(false), selectedAllPowerTrains = _e[0], setSelectedAllPowerTrains = _e[1];
    var _f = useState(false), exclusionsHasAnyChange = _f[0], setExclusionsHasAnyChange = _f[1];
    var _g = useState([]), selectedModels = _g[0], setSelectedModels = _g[1];
    var _h = useState([]), selectedPowerTrains = _h[0], setSelectedPowerTrains = _h[1];
    var _j = useState(''), pnoCodes = _j[0], setPnoCodes = _j[1];
    var financialProductsApi = useFinancialProductsApi();
    var _k = useStatus(), status = _k.status, setStatus = _k.setStatus;
    function handleSetModels(newModels) {
        setModels(newModels);
    }
    function handleSetPowerTrains(newPowerTrains) {
        setPowerTrains(newPowerTrains);
    }
    function handleSetSelectedModels(newSelectedModels) {
        setSelectedModels(newSelectedModels);
    }
    function handleSetSelectedPowerTrains(newSelectedPowerTrains) {
        setSelectedPowerTrains(newSelectedPowerTrains);
    }
    function handleSetSelectedAllModels(newSelectedAllModels) {
        setSelectedAllModels(newSelectedAllModels);
    }
    function handleSetSelectedAllPowerTrains(newSelectedAllPowerTrains) {
        setSelectedAllPowerTrains(newSelectedAllPowerTrains);
    }
    function handleSetPnoCodes(newPnoCodes) {
        setPnoCodes(newPnoCodes);
    }
    function onAllModelsChecked(checked) {
        setSelectedAllModels(checked);
        if (checked) {
            setSelectedModels(models.map(function (model) { return model.pno; }));
        }
        else {
            setSelectedModels([]);
        }
    }
    function onAllPowerTrainsChecked(checked) {
        setSelectedAllPowerTrains(checked);
        if (checked) {
            setSelectedPowerTrains(powerTrains);
        }
        else {
            setSelectedPowerTrains([]);
        }
    }
    function onAllItemsChecked(checked, type) {
        setExclusionsHasAnyChange(true);
        switch (type) {
            case 'MODELS':
                onAllModelsChecked(checked);
                break;
            case 'POWER_TRAINS':
                onAllPowerTrainsChecked(checked);
                break;
        }
    }
    function onModelCheckedChange(checked, modelId) {
        setExclusionsHasAnyChange(true);
        if (checked) {
            setSelectedModels(function (currentSelectedModels) {
                return __spreadArrays(currentSelectedModels, [modelId]);
            });
            if (__spreadArrays(selectedModels, [modelId]).length !== models.length) {
                return;
            }
            setSelectedAllModels(sameMembers(__spreadArrays(selectedModels, [modelId]), models.map(function (model) { return model.pno; })));
        }
        if (!checked) {
            setSelectedModels(function (currentSelectedModels) {
                return __spreadArrays(currentSelectedModels.filter(function (model) { return model !== modelId; }));
            });
            setSelectedAllModels(false);
        }
    }
    function onPowerTrainCheckedChange(checked, powerTrain) {
        setExclusionsHasAnyChange(true);
        if (checked) {
            setSelectedPowerTrains(function (currentSelectedPowerTrains) {
                return __spreadArrays(currentSelectedPowerTrains, [powerTrain]);
            });
            if (__spreadArrays(selectedPowerTrains, [powerTrain]).length !== powerTrains.length) {
                return;
            }
            setSelectedAllPowerTrains(sameMembers(__spreadArrays(selectedPowerTrains, [powerTrain]), powerTrains));
        }
        if (!checked) {
            setSelectedPowerTrains(function (currentSelectedPowerTrains) {
                return __spreadArrays(currentSelectedPowerTrains.filter(function (currentPowerTrain) { return currentPowerTrain !== powerTrain; }));
            });
            setSelectedAllPowerTrains(false);
        }
    }
    function onPnoCodesChanges(event) {
        setExclusionsHasAnyChange(true);
        setPnoCodes(event.currentTarget.value);
    }
    function onSaveExclusionsClick() {
        return __awaiter(this, void 0, void 0, function () {
            var pnoCodesToSend, productDetailsResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        pnoCodesToSend = pnoCodes
                            .split('\n')
                            .map(function (code) {
                            return code.split(',');
                        })
                            .flatMap(function (code) {
                            return code.map(function (c) { return c.trim(); });
                        })
                            .filter(function (code) { return code !== ''; });
                        return [4 /*yield*/, financialProductsApi.saveFinancialProductConfig(productDetails.id, selectedModels, selectedPowerTrains, pnoCodesToSend)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, financialProductsApi.getProductDetails(productId)];
                    case 2:
                        productDetailsResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            handleChangeProductDetails(productDetailsResponse);
                            if (productDetailsResponse.excludeModelCars.values) {
                                setSelectedModels(productDetailsResponse.excludeModelCars.values);
                                if (productDetailsResponse.excludeModelCars.values.length === models.length) {
                                    setSelectedAllModels(sameMembers(productDetailsResponse.excludeModelCars.values, models.map(function (model) { return model.pno; })));
                                }
                            }
                            if (productDetailsResponse.excludePowerTrains.values) {
                                setSelectedPowerTrains(productDetailsResponse.excludePowerTrains.values);
                                if (productDetailsResponse.excludePowerTrains.values.length === powerTrains.length) {
                                    setSelectedAllPowerTrains(sameMembers(productDetailsResponse.excludePowerTrains.values, powerTrains));
                                }
                            }
                            if (productDetailsResponse.excludePno12.values) {
                                var pnoCodesToBeDisplayed = productDetailsResponse.excludePno12.values.join(', ');
                                setPnoCodes(pnoCodesToBeDisplayed);
                            }
                            setExclusionsHasAnyChange(false);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    return {
        models: models,
        powerTrains: powerTrains,
        selectedAllModels: selectedAllModels,
        selectedAllPowerTrains: selectedAllPowerTrains,
        exclusionsHasAnyChange: exclusionsHasAnyChange,
        selectedModels: selectedModels,
        selectedPowerTrains: selectedPowerTrains,
        pnoCodes: pnoCodes,
        handleSetModels: handleSetModels,
        handleSetPowerTrains: handleSetPowerTrains,
        handleSetSelectedModels: handleSetSelectedModels,
        handleSetSelectedPowerTrains: handleSetSelectedPowerTrains,
        handleSetSelectedAllModels: handleSetSelectedAllModels,
        handleSetSelectedAllPowerTrains: handleSetSelectedAllPowerTrains,
        handleSetPnoCodes: handleSetPnoCodes,
        onAllItemsChecked: onAllItemsChecked,
        onModelCheckedChange: onModelCheckedChange,
        onPowerTrainCheckedChange: onPowerTrainCheckedChange,
        onPnoCodesChanges: onPnoCodesChanges,
        onSaveExclusionsClick: onSaveExclusionsClick
    };
}
