import { useHttp } from 'http/httpService';
import SessionStorage from 'common/sessionStorage';
import { creatorContextPath } from '../CreatorContextPath';
export function useInteriorApi() {
    var http = useHttp();
    var context = SessionStorage.get('employeeLeasingMode') ? 'employeeLeasing' : 'creator';
    return {
        getInteriorDetailsDealer: function (carModelId, token) {
            return http
                .get("/api/" + creatorContextPath(context) + "/car-interior/" + carModelId + "?token=" + token)
                .then(function (response) { return response.data; });
        },
        getInteriorDetailsVcp: function (carModelId, token) {
            return http
                .get("/api/" + creatorContextPath(context) + "/car-interior/vcp/" + carModelId + "?token=" + token)
                .then(function (response) { return response.data; });
        }
    };
}
