import { useHttp } from 'http/httpService';
import SessionStorage from 'common/sessionStorage';
import { creatorContextPath } from '../CreatorContextPath';
export function useVersionApi() {
    var http = useHttp();
    var context = SessionStorage.get('employeeLeasingMode') ? 'employeeLeasing' : 'creator';
    return {
        getVersions: function (carModelId, powerTrainId) {
            return http
                .get("/api/" + creatorContextPath(context) + "/car-version/" + carModelId + "?powerTrainId=" + powerTrainId)
                .then(function (response) { return response.data; });
        },
        getVersionsVcp: function (carModelId, powerTrainId) {
            return http
                .get("/api/" + creatorContextPath(context) + "/car-version/vcp/" + carModelId + "?powerTrainId=" + powerTrainId)
                .then(function (response) {
                return response.data;
            });
        },
    };
}
