var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { TextInput } from 'components/textInput/TextInput.component';
import React, { useEffect, useState } from 'react';
import { useSpecialOfferApi } from '../useSpecialOfferApi';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { fundingFormDictionary } from './RequestSubjectPreview';
import './requestSubject.css';
import { useFormattedCurrency } from 'utils/formatCurrency';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
export var EditRequestSubject = withCancellation(function (_a) {
    var requestSubject = _a.requestSubject, updatedRequestSubject = _a.updatedRequestSubject, formApi = _a.formApi, availableDealerShareMarginPercentDropdownOptions = _a.availableDealerShareMarginPercentDropdownOptions, availableVCPLevelPercentDropdownOptions = _a.availableVCPLevelPercentDropdownOptions, availableFundingForms = _a.availableFundingForms, leasingProvidersDropdownOptions = _a.leasingProvidersDropdownOptions, cfmProvidersDropdownOptions = _a.cfmProvidersDropdownOptions;
    var _b = useState(), percentagesValues = _b[0], setPercentagesValues = _b[1];
    var _c = useState({
        dealerShareMarginNetto: 0,
        vcpLevelNetto: 0,
        finalPriceGross: 0,
        finalPriceNetto: 0,
    }), newPercentagesValues = _c[0], setNewPercentagesValues = _c[1];
    var specialOfferApi = useSpecialOfferApi();
    function getPercentagesValues(dealerShareMarginNet, vcpLevelNet, catalogPriceNet, catalogPriceGross) {
        return __awaiter(this, void 0, void 0, function () {
            var percentageValuesResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, specialOfferApi.getPercentagesValues(dealerShareMarginNet, vcpLevelNet, catalogPriceNet, catalogPriceGross)];
                    case 1:
                        percentageValuesResponse = _a.sent();
                        return [2 /*return*/, percentageValuesResponse];
                }
            });
        });
    }
    useEffect(function () {
        function getCurrentPercentagesValues() {
            return __awaiter(this, void 0, void 0, function () {
                var currentPercentageValuesResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getPercentagesValues(requestSubject.dealerShareMarginNet, requestSubject.vcpLevelNet, requestSubject.catalogPriceNet, requestSubject.catalogPriceGross)];
                        case 1:
                            currentPercentageValuesResponse = _a.sent();
                            setPercentagesValues(currentPercentageValuesResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        getCurrentPercentagesValues();
    }, []);
    useEffect(function () {
        function getNewPercentagesValues() {
            return __awaiter(this, void 0, void 0, function () {
                var newPercentageValuesResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getPercentagesValues(updatedRequestSubject.newDealerMargin ? updatedRequestSubject.newDealerMargin.value : requestSubject.dealerShareMarginNet, updatedRequestSubject.newFleetCoofinancing ? updatedRequestSubject.newFleetCoofinancing.value : requestSubject.vcpLevelNet, requestSubject.catalogPriceNet, requestSubject.catalogPriceGross)];
                        case 1:
                            newPercentageValuesResponse = _a.sent();
                            setNewPercentagesValues(newPercentageValuesResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (updatedRequestSubject.newDealerMargin || updatedRequestSubject.newFleetCoofinancing) {
            getNewPercentagesValues();
        }
    }, [updatedRequestSubject.newDealerMargin, updatedRequestSubject.newFleetCoofinancing]);
    var currentDealerShareMarginNetto = useFormattedCurrency(percentagesValues && percentagesValues.dealerShareMarginNetto ? percentagesValues.dealerShareMarginNetto : 0);
    var currentVcpLevelNetto = useFormattedCurrency(percentagesValues && percentagesValues.vcpLevelNetto ? percentagesValues.vcpLevelNetto : 0);
    var isInvalidValue = updatedRequestSubject.newCarCounter !== '' && (Number(updatedRequestSubject.newCarCounter) - Number(requestSubject.carCounter) <= 0);
    return React.createElement("div", null,
        React.createElement("div", { className: "editRequestSubjectTableWrapper", role: "region", "data-testid": 'edit-request-subject-table' },
            React.createElement("table", { className: "editRequestSubjectListTable" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "editRequestSubjectLabels firstRowEditRequestSubject" }),
                        React.createElement("th", { className: "editRequestSubjectCurrentValues firstRowEditRequestSubject editRequestSubjectHeader" }, "Obecna warto\u015B\u0107"),
                        React.createElement("th", { className: "editRequestSubjectNewValues firstRowEditRequestSubject editRequestSubjectHeader" }, "Nowa warto\u015B\u0107"))),
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", { className: "editRequestSubjectLabels" }, "Liczba aut"),
                        React.createElement("td", { className: "editRequestSubjectCurrentValues" }, requestSubject.carCounter),
                        React.createElement("td", { className: "editRequestSubjectNewValues" },
                            React.createElement("div", { className: 'editRequestSubjectCellWrapper' },
                                React.createElement("div", { className: "editRequestSubjectCarAmountInput" },
                                    React.createElement(TextInput, { name: "offerCarAmount", id: "offerCarAmount", onChange: function (event) {
                                            return formApi.onNewCarAmountChange(event, requestSubject.specialOfferId);
                                        }, placeholder: "Liczba", value: updatedRequestSubject.newCarCounter, textAlign: 'center', validate: isInvalidValue }),
                                    React.createElement("div", { className: 'editRequestSubjectCarCounterInvalidMessage' }, isInvalidValue ? 'Nowa wartość musi być większa od obecnej wartości' : null))))),
                    React.createElement("tr", null,
                        React.createElement("td", { className: "editRequestSubjectLabels" }, "Udzia\u0142 mar\u017Cy Dealera w rabacie dla klienta netto"),
                        React.createElement("td", { className: "editRequestSubjectCurrentValues" },
                            React.createElement("div", null,
                                React.createElement("div", null,
                                    requestSubject.dealerShareMarginNet,
                                    "%"),
                                React.createElement("div", null,
                                    "(",
                                    currentDealerShareMarginNetto,
                                    ")"))),
                        React.createElement("td", { className: "editRequestSubjectNewValues" },
                            React.createElement("div", { className: 'editRequestSubjectCellWrapper' },
                                React.createElement("div", { className: "editRequestSubjectDealerMargin" },
                                    React.createElement(Dropdown, { options: availableDealerShareMarginPercentDropdownOptions, onChange: function (_, option) {
                                            return formApi.onNewDealerMarginChange(_, option, requestSubject.specialOfferId);
                                        }, name: "dealerMargin", placeholder: "%", value: updatedRequestSubject.newDealerMargin }),
                                    React.createElement("div", { className: 'editRequestSubjectNewValue' },
                                        "(",
                                        useFormattedCurrency(updatedRequestSubject.newDealerMargin ? newPercentagesValues.dealerShareMarginNetto : 0),
                                        ")"))))),
                    React.createElement("tr", null,
                        React.createElement("td", { className: "editRequestSubjectLabels" }, "Poziom dofinansowania flotowego VCP netto"),
                        React.createElement("td", { className: "editRequestSubjectCurrentValues" },
                            React.createElement("div", null,
                                React.createElement("div", null,
                                    requestSubject.vcpLevelNet,
                                    "%"),
                                React.createElement("div", null,
                                    "(",
                                    currentVcpLevelNetto,
                                    ")"))),
                        React.createElement("td", { className: "editRequestSubjectNewValues" },
                            React.createElement("div", { className: 'editRequestSubjectCellWrapper' },
                                React.createElement("div", { className: "editRequestSubjectVcpLevel" },
                                    React.createElement(Dropdown, { options: availableVCPLevelPercentDropdownOptions, onChange: function (_, option) {
                                            return formApi.onNewFleetCoofinancingChange(_, option, requestSubject.specialOfferId);
                                        }, name: "fleetCoofinancing", placeholder: "%", value: updatedRequestSubject.newFleetCoofinancing }),
                                    React.createElement("div", { className: 'editRequestSubjectNewValue' },
                                        "(",
                                        useFormattedCurrency(updatedRequestSubject.newFleetCoofinancing ? newPercentagesValues.vcpLevelNetto : 0),
                                        ")"))))),
                    React.createElement("tr", null,
                        React.createElement("td", { className: "editRequestSubjectLabels" }, "Rodzaj finansowania"),
                        React.createElement("td", { className: "editRequestSubjectCurrentValues" },
                            fundingFormDictionary[requestSubject.financingName],
                            requestSubject.financingValue ? ': ' + requestSubject.financingValue : ''),
                        React.createElement("td", { className: "editRequestSubjectNewValues" },
                            React.createElement("div", { className: 'editRequestSubjectFundingWrapper' },
                                React.createElement("div", { className: "editRequestSubjectFundingForm" },
                                    React.createElement(Dropdown, { options: availableFundingForms, onChange: function (_, option) {
                                            return formApi.onFundingFormChange(_, option, requestSubject.specialOfferId);
                                        }, name: "fundingForm", placeholder: "Wybierz", value: updatedRequestSubject.newFundingForm })),
                                updatedRequestSubject.newFundingForm &&
                                    updatedRequestSubject.newFundingForm.value === 'LEASING' ? (React.createElement("div", { className: "editRequestSubjectProviderWrapper" },
                                    React.createElement(Dropdown, { options: leasingProvidersDropdownOptions, onChange: function (_, option) {
                                            return formApi.onProviderChange(_, option, requestSubject.specialOfferId);
                                        }, name: "leasingProvider", placeholder: "Dostawca", value: updatedRequestSubject.newFundingProvider }))) : null,
                                updatedRequestSubject.newFundingForm &&
                                    updatedRequestSubject.newFundingForm.value === 'CFM' ? (React.createElement("div", { className: "editRequestSubjectProviderWrapper" },
                                    React.createElement(Dropdown, { options: cfmProvidersDropdownOptions, onChange: function (_, option) {
                                            return formApi.onProviderChange(_, option, requestSubject.specialOfferId);
                                        }, name: "CFM", placeholder: "Dostawca", value: updatedRequestSubject.newFundingProvider }))) : null,
                                updatedRequestSubject.newFundingForm &&
                                    (updatedRequestSubject.newFundingForm.value === 'LEASING_OTHER' ||
                                        updatedRequestSubject.newFundingForm.value === 'CFM_OTHER') ? (React.createElement("div", { className: "editRequestSubjectProviderWrapper" },
                                    React.createElement(TextInput, { id: "OTHER", name: "OTHER", placeholder: "Wprowad\u017A nazw\u0119", onChange: function (event) {
                                            return formApi.onOtherProviderChange(event, requestSubject.specialOfferId);
                                        }, value: updatedRequestSubject.newOtherProvider }))) : null)))))));
});
