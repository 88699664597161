import { useHttp } from 'http/httpService';
export function useStartOffer() {
    var http = useHttp();
    return {
        getYearsVcp: function () {
            return http
                .get('/api/creator/car-model/vcp/get-years')
                .then(function (response) { return response.data; });
        },
        getYears: function () {
            return http
                .get('/api/creator/car-model/get-years')
                .then(function (response) { return response.data; });
        },
        getModelsVcp: function (year) {
            return http
                .get("/api/creator/car-model/vcp/get-models?year=" + year)
                .then(function (response) { return response.data; });
        },
        getModels: function (year) {
            return http
                .get("/api/creator/car-model/get-models?year=" + year)
                .then(function (response) { return response.data; });
        },
        employeeLeasingCases: function () {
            return http
                .get('/api/creator-el/employee/leasing/')
                .then(function (response) { return response.data; });
        },
        getYearsVcpEL: function () {
            return http
                .get('/api/creator-el/car-model/vcp/get-years')
                .then(function (response) { return response.data; });
        },
        getModelsVcpEL: function (year) {
            return http
                .get("/api/creator-el/car-model/vcp/get-models?year=" + year)
                .then(function (response) { return response.data; });
        },
    };
}
