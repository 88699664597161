var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CreatorHeader } from 'components/creatorHeader/CreatorHeader.component';
import { Tabs } from 'components/tabs/Tabs.component';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import './creator.css';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { Drive } from './drive/Drive.component';
import { Version } from './version/Version.component';
import { Options } from './options/Options.component';
import { useOptionsTab } from './options/useOptionsTab';
import { Exterior } from './exterior/Exterior.component';
import { useExteriorTab } from './exterior/useExteriorTab';
import { useDriveTab } from './drive/useDriveTab';
import { useVersionTab } from './version/useVersionTab';
import { useStatus } from '../../common/statusContext';
import { Spinner } from 'components/spinner/Spinner';
import uuid from 'react-uuid';
import { useCreatorApi } from './useCreatorApi';
import UserContext from 'common/userContext';
import ReactDOM from 'react-dom';
import { Interior } from './interior/Interior.component';
import { useInteriorTab } from './interior/useInteriorTab';
import { Diff } from './diff/Diff.component';
import { useCarPreview } from './carPreview/usePreview';
import { CarPreview } from './carPreview/CarPreview.component';
import { SummaryTab } from './summary/SummaryTab.component';
import { useSummaryTab } from './summary/useSummaryTab';
import { useAppError } from '../../common/appErrorContext';
import { ErrorPopup } from './popup/ErrorPopup.component';
import { ConfigErrorPopup } from './popup/ConfigErrorPopup.component';
import SessionStorage from '../../common/sessionStorage';
import { usePriceListTab } from './priceList/usePriceListTab';
import { usePricesListApi } from './priceList/usePricesListApi';
import { immutableSort } from 'utils/immutableSort';
function isFromVCP() {
    return UserContext.isFromVCP() && !UserContext.hasAnyRole('VCP_EMPLOYEE_LEASING');
}
export var EmployeeLeasingCreator = withCancellation(function () {
    var _a = useStatus(), status = _a.status, setStatus = _a.setStatus;
    var _b = useAppError(), appError = _b.appError, setAppError = _b.setAppError;
    function handleSetAppError(newAppError) {
        setAppError(newAppError);
    }
    var location = useLocation();
    var _c = useState(), token = _c[0], setToken = _c[1];
    function handleOnTokenChange(token) {
        setToken(token);
    }
    var _d = useState(undefined), carModelId = _d[0], setCarModelId = _d[1];
    var _e = useState(undefined), offerId = _e[0], setOfferId = _e[1];
    var _f = useState(undefined), offer = _f[0], setOffer = _f[1];
    function handleChangeOffer(offer) {
        setOffer(offer);
    }
    var _g = useState(false), isTokenConfigurationVisible = _g[0], setIsTokenConfigurationVisible = _g[1];
    function handleChangeTokenConfigurationVisible(value) {
        setIsTokenConfigurationVisible(value);
    }
    var creatorApi = useCreatorApi({ context: 'employeeLeasing' });
    function handleOnCarModelIdChange(carModelId) {
        var id = uuid();
        var createOfferRequest = isFromVCP() ? creatorApi.createOfferVcp : creatorApi.createOffer;
        createOfferRequest(id, carModelId)
            .then(function (response) {
            if (response.status === 200) {
                if (response.data && response.data.errorDesc) {
                    window.dispatchEvent(new ErrorEvent('error', {
                        error: response.data.errorCode,
                        message: response.data.description,
                    }));
                }
                ReactDOM.unstable_batchedUpdates(function () {
                    setOfferId(id);
                    SessionStorage.set('offerId', id);
                    setCarModelId(carModelId);
                });
            }
            else {
                throw new Error('nie można utworzyć nowej oferty');
            }
        })["catch"](function (error) {
            console.error(['[create-offer-el]', error]);
        });
    }
    var _h = useState([]), pendingSyncData = _h[0], setPendingSyncData = _h[1];
    var _j = useState(undefined), year = _j[0], setYear = _j[1];
    var _k = useState(undefined), model = _k[0], setModel = _k[1];
    var _l = useState(false), employeeLeasingMode = _l[0], setEmployeeLeasingMode = _l[1];
    var priceListApi = usePricesListApi();
    var isSaveInSessionStorage = SessionStorage.has('offerId') && SessionStorage.has('currentTab');
    function setBasicDataFromOffer(offerData) {
        setYear(offerData.vehicle.year);
        setModel(offerData.vehicle.name);
        setCarModelId(offerData.vehicle.carModelId);
    }
    function handleSetModelYear(modelYear) {
        setYear(modelYear);
    }
    useEffect(function () {
        if (location.state && 'selectedCase' in location.state) {
            SessionStorage.set('employeeLeasingMode', true);
            SessionStorage.set('selectedCase', location.state.selectedCase);
            setEmployeeLeasingMode(true);
        }
        if (location.state && 'year' in location.state && 'model' in location.state) {
            SessionStorage.set('employeeLeasingMode', true);
            setEmployeeLeasingMode(true);
        }
    }, []);
    useEffect(function () {
        function createOfferOrRecreateFromStorage() {
            return __awaiter(this, void 0, void 0, function () {
                var offerIdFromStorage, getOfferResponse_1, priceListsToUse_1, getPriceListsFromStorage, priceListRequest, priceListResponse, sortedPriceLists;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!isSaveInSessionStorage) return [3 /*break*/, 5];
                            offerIdFromStorage = SessionStorage.get('offerId');
                            if (SessionStorage.get('employeeLeasingMode')) {
                                setEmployeeLeasingMode(true);
                            }
                            setStatus('pending');
                            return [4 /*yield*/, creatorApi.getOffer(offerIdFromStorage)];
                        case 1:
                            getOfferResponse_1 = _a.sent();
                            getPriceListsFromStorage = SessionStorage.get('priceLists');
                            if (!getPriceListsFromStorage) return [3 /*break*/, 2];
                            priceListsToUse_1 = getPriceListsFromStorage;
                            return [3 /*break*/, 4];
                        case 2:
                            priceListRequest = isFromVCP() ? priceListApi.getPricesListVcp : priceListApi.getPricesList;
                            return [4 /*yield*/, priceListRequest(getOfferResponse_1.vehicle.year, getOfferResponse_1.vehicle.name)];
                        case 3:
                            priceListResponse = _a.sent();
                            sortedPriceLists = immutableSort(priceListResponse, function (a, b) { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(); });
                            priceListsToUse_1 = sortedPriceLists;
                            _a.label = 4;
                        case 4:
                            ReactDOM.unstable_batchedUpdates(function () {
                                setStatus('success');
                                var employeeLeasingModeFromSessionStorage = SessionStorage.get('employeeLeasingMode');
                                if (employeeLeasingModeFromSessionStorage) {
                                    setEmployeeLeasingMode(employeeLeasingModeFromSessionStorage);
                                }
                                setBasicDataFromOffer(getOfferResponse_1);
                                setOfferId(getOfferResponse_1.id);
                                setToken(getOfferResponse_1.vehicle.tokenConfiguration);
                                setOffer(__assign(__assign({}, getOfferResponse_1), { diff: {
                                        addedElements: [''],
                                        subtractedElements: [''],
                                        differentVersion: false
                                    } }));
                                onPriceListsChange(priceListsToUse_1);
                                onSelectedPriceListChange(getOfferResponse_1.vehicle.priceList, priceListsToUse_1);
                            });
                            return [3 /*break*/, 6];
                        case 5:
                            ReactDOM.unstable_batchedUpdates(function () {
                                if (location.state && 'selectedCase' in location.state) {
                                    setModel(location.state.selectedCase.model);
                                }
                                if (location.state && 'year' in location.state && 'model' in location.state) {
                                    setYear(location.state.year);
                                    setModel(location.state.model);
                                }
                            });
                            _a.label = 6;
                        case 6: return [2 /*return*/];
                    }
                });
            });
        }
        createOfferOrRecreateFromStorage();
    }, []);
    useEffect(function () {
        return function () {
            SessionStorage.remove('offerId');
            SessionStorage.remove('currentTab');
            SessionStorage.remove('isConfigurationSourceLink');
            SessionStorage.remove('percentageValuesPrintType');
            SessionStorage.remove('envImpactPrintType');
            SessionStorage.remove('calculations');
            SessionStorage.remove('employeeLeasingMode');
            SessionStorage.remove('selectedCase');
            setAppError(undefined);
        };
    }, []);
    // cenniki
    var _m = usePriceListTab({
        model: model,
        handleOnCarModelIdChange: handleOnCarModelIdChange,
        isSaveInSessionStorage: isSaveInSessionStorage,
        employeeLeasingMode: employeeLeasingMode,
        handleSetModelYear: handleSetModelYear
    }), selectedPriceList = _m.selectedPriceList, onPriceListsChange = _m.onPriceListsChange, onSelectedPriceListChange = _m.onSelectedPriceListChange;
    // napędy
    var _o = useDriveTab({
        carModelId: carModelId,
        canBeSynced: true,
        onSynced: function () {
            setPendingSyncData(['versions']);
        },
        onDriveChange: function () {
            setPendingSyncData(['versions']);
        },
        handleOnTokenChange: handleOnTokenChange,
        offerId: offerId,
        handleChangeOffer: handleChangeOffer,
        offer: offer,
        employeeLeasingMode: employeeLeasingMode,
        isSaveInSessionStorage: isSaveInSessionStorage
    }), drives = _o.drives, onSelectedDriveChange = _o.onSelectedDriveChange, selectedDrive = _o.selectedDrive;
    // wersje i motywy
    var _p = useVersionTab({
        selectedDrive: selectedDrive,
        carModelId: carModelId,
        token: token,
        canBeSynced: pendingSyncData.includes('versions') && offer !== undefined,
        onSynced: function () { },
        onThemeChange: function () {
            setPendingSyncData(['options']);
        },
        offer: offer,
        offerId: offerId,
        handleChangeOffer: handleChangeOffer,
        isSaveInSessionStorage: isSaveInSessionStorage,
        employeeLeasingMode: employeeLeasingMode
    }), versions = _p.versions, selectedVersion = _p.selectedVersion, selectedTheme = _p.selectedTheme, onSelectedThemeChange = _p.onSelectedThemeChange;
    // opcje
    var _q = useOptionsTab({
        carModelId: carModelId,
        token: token,
        handleOnTokenChange: handleOnTokenChange,
        canBeSynced: pendingSyncData.includes('options'),
        onSynced: function () {
            setPendingSyncData(['exterior']);
        },
        onOptionChange: function () {
            setPendingSyncData(['options']);
        },
        offer: offer,
        handleChangeOffer: handleChangeOffer,
        employeeLeasingMode: employeeLeasingMode
    }), selectedPackages = _q.selectedPackages, selectedOptions = _q.selectedOptions, onSelectedOptionChange = _q.onSelectedOptionChange, onSelectedPackageChange = _q.onSelectedPackageChange, options = _q.options, onSelectedSubOptionChange = _q.onSelectedSubOptionChange, selectedSubOptions = _q.selectedSubOptions;
    // nadwozie
    var _r = useExteriorTab({
        carModelId: carModelId,
        token: token,
        handleOnTokenChange: handleOnTokenChange,
        canBeSynced: pendingSyncData.includes('exterior'),
        onSynced: function () {
            setPendingSyncData(['interior']);
        },
        onExteriorChange: function () {
            setPendingSyncData(['options']);
        },
        offer: offer,
        handleChangeOffer: handleChangeOffer,
        handleSetAppError: handleSetAppError,
        employeeLeasingMode: employeeLeasingMode
    }), exteriors = _r.exteriors, selectedColor = _r.selectedColor, selectedWheels = _r.selectedWheels, onSelectedColorChange = _r.onSelectedColorChange, onSelectedWheelsChange = _r.onSelectedWheelsChange;
    // wnętrze
    var _s = useInteriorTab({
        carModelId: carModelId,
        offer: offer,
        token: token,
        handleOnTokenChange: handleOnTokenChange,
        canBeSynced: pendingSyncData.includes('interior'),
        onSynced: function () {
            setPendingSyncData([]);
        },
        onInteriorChange: function () {
            setPendingSyncData(['options']);
        },
        handleChangeOffer: handleChangeOffer,
        employeeLeasingMode: employeeLeasingMode
    }), interiorElements = _s.interiorElements, selectedInterior = _s.selectedInterior, onSelectedInteriorChange = _s.onSelectedInteriorChange;
    // podgląd samochodów
    var swiperTabs = useCarPreview(token);
    // podsumowanie
    var _t = useSummaryTab({
        offer: offer,
        drives: drives,
        selectedDrive: selectedDrive,
        versions: versions,
        selectedTheme: selectedTheme,
        selectedVersion: selectedVersion,
        exteriors: exteriors,
        selectedWheels: selectedWheels,
        interiorElements: interiorElements,
        options: options,
        selectedPackages: selectedPackages
    }), additionalInformation = _t.additionalInformation, onChangeAdditionalInformation = _t.onChangeAdditionalInformation, onGetSheetClick = _t.onGetSheetClick, onRegisterClick = _t.onRegisterClick, onBlurAdditionalInformation = _t.onBlurAdditionalInformation, additionalOptionsLimit = _t.additionalOptionsLimit, maxAdditionalOptionsLimit = _t.maxAdditionalOptionsLimit, onSendClick = _t.onSendClick, onBackClick = _t.onBackClick;
    var tabs = [
        {
            value: 'drive',
            name: 'Napęd',
            content: (React.createElement(Drive, { drives: drives, selectedDrive: selectedDrive, onSelectedDriveChange: onSelectedDriveChange, handleOnTokenChange: handleOnTokenChange })),
            isVisible: true,
        },
        {
            value: 'version',
            name: 'Wersja',
            content: (React.createElement(Version, { versions: versions, selectedVersion: selectedVersion, onSelectedThemeChange: onSelectedThemeChange, selectedTheme: selectedTheme, onVersionChange: function () {
                    setPendingSyncData(['options']);
                }, handleOnTokenChange: handleOnTokenChange })),
            isVisible: true,
        },
        {
            value: 'options',
            name: 'Opcje',
            content: (React.createElement(Options, { options: options, selectedPackages: selectedPackages, onSelectedPackageChange: onSelectedPackageChange, selectedOptions: selectedOptions, onSelectedOptionsChange: onSelectedOptionChange, onSelectedSubOptionChange: onSelectedSubOptionChange, selectedSubOptions: selectedSubOptions })),
            isVisible: true,
        },
        {
            value: 'exterior',
            name: 'Nadwozie',
            content: (React.createElement(Exterior, { exteriors: exteriors, selectedColor: selectedColor, onSelectedColorChange: onSelectedColorChange, selectedWheels: selectedWheels, onSelectedWheelsChange: onSelectedWheelsChange })),
            isVisible: true,
        },
        {
            value: 'interior',
            name: 'Wnętrze',
            content: (React.createElement(Interior, { interiorElements: interiorElements, selectedInterior: selectedInterior, onSelectedInteriorChange: onSelectedInteriorChange })),
            isVisible: true,
        },
        {
            value: 'summary',
            name: 'Podsumowanie',
            content: (React.createElement(SummaryTab, { handleChangeTokenConfigurationVisible: handleChangeTokenConfigurationVisible, offer: offer, drives: drives, selectedDrive: selectedDrive, versions: versions, exteriors: exteriors, selectedWheels: selectedWheels, interiorElements: interiorElements, options: options, selectedPackages: selectedPackages, additionalInformation: additionalInformation, onChangeAdditionalInformation: onChangeAdditionalInformation, onGetSheetClick: onGetSheetClick, onBlurAdditionalInformation: onBlurAdditionalInformation, onRegisterClick: onRegisterClick, additionalOptionsLimit: additionalOptionsLimit, maxAdditionalOptionsLimit: maxAdditionalOptionsLimit, onSendClick: onSendClick, onBackClick: onBackClick })),
            isVisible: true,
        },
    ];
    function getFirstVisibleTabValue() {
        var firstVisibleTab = tabs.find(function (tab) { return tab.isVisible; }).value;
        SessionStorage.set('currentTab', firstVisibleTab);
        return firstVisibleTab;
    }
    function handleTabChange(value) {
        SessionStorage.set('currentTab', value);
    }
    var canShowDiffModal = pendingSyncData.length === 0 && status !== 'pending';
    function isFatalError() {
        return (appError === null || appError === void 0 ? void 0 : appError.redirect) === 'errorPage';
    }
    function isErrorPopup() {
        return (appError === null || appError === void 0 ? void 0 : appError.redirect) === 'errorPopup';
    }
    function isConfigErrorPopup() {
        return (appError === null || appError === void 0 ? void 0 : appError.redirect) === 'configErrorPopup';
    }
    if (isFatalError()) {
        return React.createElement(Redirect, { to: {
                pathname: '/error',
                state: {
                    errorId: appError.errorId,
                    errorDesc: appError.errorDesc
                }
            } });
    }
    return (React.createElement("div", null,
        React.createElement(CreatorHeader, { model: model, modelYear: year, priceList: selectedPriceList ? selectedPriceList : '', grossPrice: offer ? offer.priceVariant.totalPriceGross : '', netPrice: offer ? offer.priceVariant.totalPriceNet : '', isTokenConfigurationVisible: isTokenConfigurationVisible, tokenConfiguration: offer ? offer.vehicle.tokenConfiguration : '' }),
        React.createElement("div", { className: "tabsWrapper" },
            React.createElement("form", null,
                (pendingSyncData.length > 0 || (pendingSyncData.length === 0 && status === 'pending')) && (React.createElement(Spinner, null)),
                (drives && versions && options && exteriors && interiorElements) ? (React.createElement(React.Fragment, null,
                    React.createElement(Tabs.Root, { defaultValue: SessionStorage.get('currentTab') || getFirstVisibleTabValue(), onValueChange: handleTabChange },
                        React.createElement("div", { style: { position: 'sticky',
                                top: '90px',
                                background: 'var(--white)',
                                zIndex: 10 } },
                            React.createElement(Tabs.List, null, tabs.map(function (tab, index) {
                                return tab.isVisible ? (React.createElement(Tabs.Trigger, { value: tab.value, key: index }, tab.name)) : null;
                            })),
                            React.createElement("div", { style: { position: 'absolute', top: '5px', right: '-43px' } },
                                React.createElement(CarPreview, { swiperTabs: swiperTabs }))),
                        tabs.map(function (tab, index) {
                            return tab.isVisible ? (React.createElement(Tabs.Content, { value: tab.value, key: index },
                                React.createElement("div", { id: "tabContentWrapper", className: "tabContentWrapper" }, tab.content))) : null;
                        })))) : null),
            React.createElement(Diff, { offer: offer, canDiffShow: canShowDiffModal, handleChangeOffer: handleChangeOffer })),
        isErrorPopup() ? React.createElement(ErrorPopup, { text: appError.errorDesc }) : null,
        isConfigErrorPopup() ? React.createElement(ConfigErrorPopup, null) : null));
});
