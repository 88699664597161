import React from 'react';
import './salePotential.css';
export function SalePotentialPreview(_a) {
    var salePotentialSection = _a.salePotentialSection;
    var fleet = salePotentialSection.fleet;
    return React.createElement("div", { className: 'salePotentialSectionWrapper' },
        React.createElement("div", { className: 'salePotentialSectionFleetWrapper' }, fleet && fleet.length > 0 ? React.createElement("div", { className: 'salePotentialSectionLabel' }, "Posiadana flota pojazd\u00F3w osobowych:") : null),
        React.createElement("div", { className: 'salePotentialSectionTableWrapper', "data-testid": 'sale-potential-table-wrapper' }, fleet && fleet.length > 0 ?
            React.createElement("table", { className: 'salePotentialSectionTable' },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: 'salePotentialSectionTableHeader headerMarkCell' }, "Marka"),
                        React.createElement("th", { className: 'salePotentialSectionTableHeader headerModelCell' }, "Model"),
                        React.createElement("th", { className: 'salePotentialSectionTableHeader headerAmountCell' }, "Sztuk"))),
                React.createElement("tbody", null, fleet.map(function (car) { return (React.createElement("tr", { className: 'salePotentialtSectionFleetTableRow', key: car.mark + "_" + car.model },
                    React.createElement("td", { className: 'markCell' }, car.mark),
                    React.createElement("td", { className: 'modelCell' }, car.model),
                    React.createElement("td", { className: 'amountCell' }, car.amount))); })))
            : null));
}
