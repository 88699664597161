var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useState } from 'react';
export function useSalePotential() {
    var _a = useState(''), mark = _a[0], setMark = _a[1];
    var _b = useState(''), model = _b[0], setModel = _b[1];
    var _c = useState(''), amount = _c[0], setAmount = _c[1];
    var _d = useState([]), fleet = _d[0], setFleet = _d[1];
    function handleOnMarkChange(event) {
        setMark(event.currentTarget.value);
    }
    function handleOnModelChange(event) {
        setModel(event.currentTarget.value);
    }
    function handleOnAmountChange(event) {
        var result = event.target.value.replace(/\D/g, '');
        setAmount(result);
    }
    function handleOnAddCarFleet() {
        setFleet(function (currentFleet) {
            return __spreadArrays(currentFleet, [{
                    mark: mark,
                    model: model,
                    amount: amount
                }]);
        });
        setMark('');
        setModel('');
        setAmount('');
    }
    function handleOnDeleteCarFleet(carMark, carModel) {
        var carFleetToDelete = fleet.find(function (car) { return car.mark === carMark && car.model === carModel; });
        setFleet((function (currentFleet) {
            return __spreadArrays(currentFleet.filter(function (car) { return car !== carFleetToDelete; }));
        }));
    }
    return {
        mark: mark,
        handleOnMarkChange: handleOnMarkChange,
        model: model,
        handleOnModelChange: handleOnModelChange,
        amount: amount,
        handleOnAmountChange: handleOnAmountChange,
        fleet: fleet,
        handleOnAddCarFleet: handleOnAddCarFleet,
        handleOnDeleteCarFleet: handleOnDeleteCarFleet
    };
}
