var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { Button } from 'components/button/Button.component';
import { TextAmount } from 'components/textAmount/TextAmount';
import { useSummaryApi } from 'pages/creator/summary/useSummaryApi';
import React, { useEffect, useState } from 'react';
import './requestSubject.css';
import { ChangesHistory } from '../changesHistory/ChangesHistory';
import { useSpecialOfferApi } from '../useSpecialOfferApi';
export var fundingFormDictionary = {
    CASH: 'Gotówka',
    LEASING: 'Leasing',
    LEASING_OTHER: 'Leasing - inne',
    CFM: 'Finansowanie CFM',
    CFM_OTHER: 'Finansowanie CFM - inne',
};
export var statusDictionary = {
    SUBMITTED: 'Wysłany',
    APPROVED: 'Zaakceptowany',
    DECLINED: 'Odrzucony',
    ERROR: 'Błąd',
    TENTATIVELY_ACCEPTED: 'Wstępnie zaakceptowany',
    SUBMITTED_RECKONING: 'Zamówienie do rozliczenia',
    APPROVED_RECKONING: 'Zamówienie rozliczone',
    DECLINED_RECKONING: 'Zamówienie odrzucone',
    SUBMITTED_CONDITION: 'Zmiana warunków',
    APPROVED_CONDITION: 'Zmiana warunków zaakceptowana',
    DECLINED_CONDITION: 'Zmiana warunków odrzucona',
    APPROVED_DURATION_EXTENSION: 'Zmiana daty ważności zaakceptowana',
    DECLINED_DURATION_EXTENSION: 'Zmiana daty ważności odrzucona',
    SUBMITTED_DURATION_EXTENSION: 'Zmiana daty ważności',
    BUSINESS_DURATION_EXTENSION: 'Zmiana daty ważności VCP',
    WITHDRAWN_RECKONING: 'Zamówienie wycofane',
    RECKONING_CORRECTED: 'Rozliczenie skorygowane',
    ADJUSTED: 'Rozliczenie skorygowane'
};
export var RequestSubjectPreview = withCancellation(function RequestSubject(_a) {
    var requestSubject = _a.requestSubject;
    var summaryApi = useSummaryApi();
    var specialOfferApi = useSpecialOfferApi();
    var _b = useState(), percentagesValues = _b[0], setPercentagesValues = _b[1];
    function onShowConfigurationClick() {
        return __awaiter(this, void 0, void 0, function () {
            var getConfigurationSheetResponse, responseBlob, url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, summaryApi.getConfigurationSheet(requestSubject.offerId)];
                    case 1:
                        getConfigurationSheetResponse = _a.sent();
                        responseBlob = new Blob([getConfigurationSheetResponse.data], {
                            type: getConfigurationSheetResponse.contentType,
                        });
                        url = URL.createObjectURL(responseBlob);
                        window.open(url);
                        URL.revokeObjectURL(url);
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        function getPercentagesValues() {
            return __awaiter(this, void 0, void 0, function () {
                var percentageValuesResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.getPercentagesValues(requestSubject.dealerShareMarginNet, requestSubject.vcpLevelNet, requestSubject.catalogPriceNet, requestSubject.catalogPriceGross)];
                        case 1:
                            percentageValuesResponse = _a.sent();
                            setPercentagesValues(percentageValuesResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (requestSubject) {
            getPercentagesValues();
        }
    }, []);
    var lastStatus = requestSubject.status;
    return (requestSubject ?
        React.createElement("div", { className: "requestSubjectSectionWrapper" },
            React.createElement("div", { className: "requestSubjectGridWrapper" },
                React.createElement("div", { className: "requestSubjectLeftContainer" },
                    React.createElement("div", { className: 'requestSubjectOfferDropdownWrapper displayRow' },
                        React.createElement("div", { className: "requestSubjectLabel" }, "Oferta:"),
                        React.createElement("div", { "data-testid": "request-subject-offer-number" }, requestSubject.offerNumber)),
                    React.createElement("div", { className: "requestSubjectOfferRow" },
                        React.createElement("div", { className: "requestSubjectLabel" }, "PNO12:"),
                        React.createElement("div", { className: "requestSubjectValue", "data-testid": "request-subject-pno" },
                            React.createElement("div", null, requestSubject.pno12))),
                    React.createElement("div", { className: "requestSubjectOfferRow" },
                        React.createElement("div", { className: "requestSubjectLabel" }, "Samoch\u00F3d:"),
                        React.createElement("div", { className: "requestSubjectValue", "data-testid": "request-subject-car" },
                            React.createElement("div", null, requestSubject.car))),
                    React.createElement("div", { className: "requestSubjectOfferRow" },
                        React.createElement("div", { className: "requestSubjectLabel" }, "Ilo\u015B\u0107 aut:"),
                        React.createElement("div", { className: "requestSubjectCarAmountInput", "data-testid": "request-subject-car-amount" },
                            React.createElement("div", null, requestSubject.carCounter))),
                    React.createElement("div", { className: "requestSubjectOfferRow" },
                        React.createElement("div", { className: "requestSubjectLabel" }, "Silnik:"),
                        React.createElement("div", { className: "requestSubjectValue", "data-testid": "request-subject-drive" },
                            React.createElement("div", null, requestSubject.driver))),
                    React.createElement("div", { className: "requestSubjectOfferRow" },
                        React.createElement("div", { className: "requestSubjectLabel" }, "Wersja wyposa\u017Cenia:"),
                        React.createElement("div", { className: "requestSubjectValue", "data-testid": "request-subject-version" },
                            React.createElement("div", null, requestSubject.version))),
                    React.createElement("div", { className: "requestSubjectOfferRow" },
                        React.createElement("div", { className: "requestSubjectLabel" }, "Status:"),
                        React.createElement("div", { className: "requestSubjectValue", "data-testid": "request-subject-status" },
                            React.createElement("div", null, statusDictionary[lastStatus]))),
                    React.createElement("div", { className: "requestSubjectButtonWrapper" },
                        React.createElement(Button, { type: "button", disabled: !requestSubject, variant: "secondary", size: 32, onClick: onShowConfigurationClick }, "wy\u015Bwietl konfiguracj\u0119"))),
                React.createElement("div", { className: "requestSubjectRightContainer", "data-testid": "request-subject-amounts-table" },
                    React.createElement("table", { className: "requestSubjectFinancingDataTable" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { className: "requestSubjectFinancingDataHeader requestSubjectCategoryCell" }, "Kategoria"),
                                React.createElement("th", { className: "requestSubjectFinancingDataHeader requestSubjectPercentCell" }, "Procent"),
                                React.createElement("th", { className: "requestSubjectFinancingDataHeader requestSubjectAmountHeaderCell" }, "Kwota"))),
                        React.createElement("tbody", null,
                            React.createElement("tr", { className: "requestSubjectFinancingDataRow" },
                                React.createElement("td", { className: "requestSubjectCategoryCell" }, "Cena katalogowa netto:"),
                                React.createElement("td", { className: "requestSubjectPercentCell" }),
                                React.createElement("td", { className: "requestSubjectAmountCell" },
                                    React.createElement(TextAmount, null, requestSubject.catalogPriceNet))),
                            React.createElement("tr", { className: "requestSubjectFinancingDataRow" },
                                React.createElement("td", { className: "requestSubjectCategoryCell" }, "Cena katalogowa brutto:"),
                                React.createElement("td", { className: "requestSubjectPercentCell" }),
                                React.createElement("td", { className: "requestSubjectAmountCell" },
                                    React.createElement(TextAmount, null, requestSubject.catalogPriceGross))),
                            React.createElement("tr", { className: "requestSubjectFinancingDataRow" },
                                React.createElement("td", { className: "requestSubjectCategoryCell" }, "Udzia\u0142 mar\u017Cy Dealera w rabacie dla klienta netto:"),
                                React.createElement("td", { className: "requestSubjectPercentCell" },
                                    React.createElement("div", null,
                                        requestSubject.dealerShareMarginNet,
                                        "%")),
                                React.createElement("td", { className: "requestSubjectAmountCell" },
                                    React.createElement(TextAmount, null, percentagesValues ? percentagesValues.dealerShareMarginNetto : 0))),
                            React.createElement("tr", { className: "requestSubjectFinancingDataRow" },
                                React.createElement("td", { className: "requestSubjectCategoryCell" }, "Poziom dofinansowania flotowego VCP netto:"),
                                React.createElement("td", { className: "requestSubjectPercentCell" },
                                    React.createElement("div", null,
                                        requestSubject.vcpLevelNet,
                                        "%")),
                                React.createElement("td", { className: "requestSubjectAmountCell" },
                                    React.createElement(TextAmount, null, percentagesValues ? percentagesValues.vcpLevelNetto : 0))),
                            React.createElement("tr", { className: "requestSubjectFinancingDataRow" },
                                React.createElement("td", { className: "requestSubjectCategoryCell" }, "Oczekiwana cena ko\u0144cowa netto:"),
                                React.createElement("td", { className: "requestSubjectPercentCell" }),
                                React.createElement("td", { className: "requestSubjectAmountCell" },
                                    React.createElement(TextAmount, null, percentagesValues ? percentagesValues.finalPriceNetto : requestSubject.finalPriceNet))),
                            React.createElement("tr", { className: "requestSubjectFinancingDataRow" },
                                React.createElement("td", { className: "requestSubjectCategoryCell" }, "Oczekiwana cena ko\u0144cowa brutto:"),
                                React.createElement("td", { className: "requestSubjectPercentCell" }),
                                React.createElement("td", { className: "requestSubjectAmountCell" },
                                    React.createElement(TextAmount, null, percentagesValues ? percentagesValues.finalPriceGross : requestSubject.finalPriceGross))))))),
            React.createElement("div", { className: 'requestSubjectFinancingWrapper financingWrapperReadOnly' },
                React.createElement("div", { className: "requestSubjectFinancingLabel" }, "Rodzaj finansowania:"),
                React.createElement("div", { className: "requestSubjectFInancingValue", "data-testid": "request-subject-financing-form" },
                    fundingFormDictionary[requestSubject.financingName],
                    requestSubject.financingValue ? ': ' + requestSubject.financingValue : '')),
            React.createElement(ChangesHistory, { changesHistory: requestSubject.statuses }))
        : null);
});
