var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState } from 'react';
import { Label } from '../../../components/label/Label.component';
import { useFinancingApi } from './useFinancingApi';
import './financing.css';
import ReactDOM from 'react-dom';
import { useStatus } from 'common/statusContext';
import { useAppError } from 'common/appErrorContext';
import { SectionHeader } from 'components/sectionHeader/SectionHeader';
import { Checkbox } from 'components/checkbox/Checkbox.component';
import { Calculate } from './Calculate.component';
import uuid from 'react-uuid';
import { Plus } from 'components/accessories/Plus.comonent';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import SessionStorage from '../../../common/sessionStorage';
var ERROR_CODES = ['eurocode-not-exist', 'token-mLeasing', 'mLeasing-error', 'api-error-zps'];
export var Financing = withCancellation(function (_a) {
    var offerId = _a.offerId, offer = _a.offer, drives = _a.drives, onClientCFMChange = _a.onClientCFMChange;
    var setStatus = useStatus().setStatus;
    var isClientCFM = offer.printOptions.includes('CFM');
    var financingApi = useFinancingApi();
    var _b = useState(undefined), calculations = _b[0], setCalculations = _b[1];
    var _c = useState(undefined), wallboxCalculations = _c[0], setWallboxCalculations = _c[1];
    var _d = useState(undefined), foundingProducts = _d[0], setFoundingProducts = _d[1];
    useEffect(function () {
        var id = uuid();
        var getStoredCalculations = SessionStorage.get('calculations');
        if (getStoredCalculations) {
            setCalculations(getStoredCalculations.map(function (calculation) {
                return {
                    id: calculation.id,
                    forPrinting: calculation.forPrinting,
                    isCalculationIdRegister: true,
                    isCalculateError: false
                };
            }));
        }
        else {
            setCalculations([
                {
                    id: id,
                    forPrinting: true,
                    isCalculationIdRegister: false,
                    isCalculateError: false
                },
            ]);
        }
        // dodać jakiś warunek który powie czy można kalkulację wallboxa pokazać
        var wallboxId = uuid();
        setWallboxCalculations([
            {
                id: wallboxId,
                forPrinting: true
            }
        ]);
        function fetchProducts() {
            return __awaiter(this, void 0, void 0, function () {
                var foundingProductsResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, financingApi.getProductsList(offerId)];
                        case 1:
                            foundingProductsResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setFoundingProducts(foundingProductsResponse);
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        fetchProducts();
    }, []);
    var _e = useAppError(), appError = _e.appError, setAppError = _e.setAppError;
    function isFinancingError() {
        return ERROR_CODES.some(function (e) { return e.includes(appError === null || appError === void 0 ? void 0 : appError.errorId); });
    }
    function getErrorDesc() {
        return appError === null || appError === void 0 ? void 0 : appError.errorDesc;
    }
    useEffect(function () {
        isFinancingError();
    }, [appError]);
    useEffect(function () {
        return function () {
            setAppError(undefined);
        };
    }, []);
    function handleOnAddCalculationClick(type) {
        var id = uuid();
        switch (type) {
            case 'CAR':
                setCalculations(function (currentCalculations) {
                    return __spreadArrays(currentCalculations, [
                        {
                            id: id,
                            forPrinting: isClientCFM ? false : true,
                            isCalculationIdRegister: false,
                            isCalculateError: false
                        }
                    ]);
                });
                break;
            case 'WALLBOX':
                setWallboxCalculations(function (currentCalculations) {
                    return __spreadArrays(currentCalculations, [
                        {
                            id: id,
                            forPrinting: true
                        }
                    ]);
                });
                break;
        }
    }
    function handleOnPrintSwitchChange(checked, calculationId, type) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, editedCalculation, calculationsFromSessionStorage, getCalculationFromSessionStorageById, editedCalculationIndexFromSessionStorage, getCalculationFromSessionStorageById, editedCalculationIndexFromSessionStorage;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = type;
                        switch (_a) {
                            case 'CAR': return [3 /*break*/, 1];
                            case 'WALLBOX': return [3 /*break*/, 6];
                        }
                        return [3 /*break*/, 7];
                    case 1:
                        editedCalculation = calculations.find(function (calculation) { return calculation.id === calculationId; });
                        calculationsFromSessionStorage = SessionStorage.get('calculations');
                        if (!editedCalculation.isCalculationIdRegister) return [3 /*break*/, 5];
                        if (!checked) return [3 /*break*/, 3];
                        setStatus('pending');
                        return [4 /*yield*/, financingApi.enablePrint(calculationId)];
                    case 2:
                        _b.sent();
                        if (calculationsFromSessionStorage) {
                            getCalculationFromSessionStorageById = calculationsFromSessionStorage.find(function (calculate) { return calculate.id === calculationId; });
                            editedCalculationIndexFromSessionStorage = calculationsFromSessionStorage.findIndex(function (calculate) { return calculate.id === calculationId; });
                            SessionStorage.set('calculations', __spreadArrays(calculationsFromSessionStorage.slice(0, editedCalculationIndexFromSessionStorage), [
                                __assign(__assign({}, getCalculationFromSessionStorageById), { forPrinting: true })
                            ], calculationsFromSessionStorage.slice(editedCalculationIndexFromSessionStorage + 1)));
                        }
                        setStatus('success');
                        return [3 /*break*/, 5];
                    case 3:
                        setStatus('pending');
                        return [4 /*yield*/, financingApi.disablePrint(calculationId)];
                    case 4:
                        _b.sent();
                        if (calculationsFromSessionStorage) {
                            getCalculationFromSessionStorageById = calculationsFromSessionStorage.find(function (calculate) { return calculate.id === calculationId; });
                            editedCalculationIndexFromSessionStorage = calculationsFromSessionStorage.findIndex(function (calculate) { return calculate.id === calculationId; });
                            SessionStorage.set('calculations', __spreadArrays(calculationsFromSessionStorage.slice(0, editedCalculationIndexFromSessionStorage), [
                                __assign(__assign({}, getCalculationFromSessionStorageById), { forPrinting: false })
                            ], calculationsFromSessionStorage.slice(editedCalculationIndexFromSessionStorage + 1)));
                        }
                        setStatus('success');
                        _b.label = 5;
                    case 5:
                        setCalculations(function (currentCalculations) {
                            var editedCalculationIndex = currentCalculations.findIndex(function (calculation) { return calculation.id === calculationId; });
                            return __spreadArrays(currentCalculations.slice(0, editedCalculationIndex), [
                                __assign(__assign({}, currentCalculations[editedCalculationIndex]), { forPrinting: checked })
                            ], currentCalculations.slice(editedCalculationIndex + 1));
                        });
                        return [3 /*break*/, 7];
                    case 6:
                        setWallboxCalculations(function (currentCalculations) {
                            var editedCalculationIndex = currentCalculations.findIndex(function (calculation) { return calculation.id === calculationId; });
                            return __spreadArrays(currentCalculations.slice(0, editedCalculationIndex), [
                                __assign(__assign({}, currentCalculations[editedCalculationIndex]), { forPrinting: checked })
                            ], currentCalculations.slice(editedCalculationIndex + 1));
                        });
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    }
    function handleOnDeleteCalculationClick(id, type) {
        switch (type) {
            case 'CAR':
                var editedCalculation = calculations.find(function (calculation) { return calculation.id === id; });
                var calculationsFromSessionStorage = SessionStorage.get('calculations');
                if (editedCalculation.isCalculationIdRegister) {
                    setStatus('pending');
                    financingApi.deleteCalculate(id);
                    if (calculationsFromSessionStorage) {
                        var editedCalculationIndexFromSessionStorage = calculationsFromSessionStorage.findIndex(function (calculate) { return calculate.id === id; });
                        if (calculationsFromSessionStorage.length === 1 && editedCalculationIndexFromSessionStorage === 0) {
                            SessionStorage.remove('calculations');
                        }
                        else {
                            SessionStorage.set('calculations', __spreadArrays(calculationsFromSessionStorage.slice(0, editedCalculationIndexFromSessionStorage), calculationsFromSessionStorage.slice(editedCalculationIndexFromSessionStorage + 1)));
                        }
                    }
                    setStatus('success');
                }
                setCalculations(function (currentCalculation) {
                    return currentCalculation.filter(function (calculation) { return calculation.id !== id; });
                });
                break;
            case 'WALLBOX':
                setWallboxCalculations(function (currentCalculation) {
                    return currentCalculation.filter(function (calculation) { return calculation.id !== id; });
                });
                break;
        }
    }
    function handleChangeIsCalculationIdRegister(calculationId) {
        setCalculations(function (currentCalculations) {
            var editedCalculationIndex = currentCalculations.findIndex(function (calculation) { return calculation.id === calculationId; });
            return __spreadArrays(currentCalculations.slice(0, editedCalculationIndex), [
                __assign(__assign({}, currentCalculations[editedCalculationIndex]), { isCalculationIdRegister: true })
            ], currentCalculations.slice(editedCalculationIndex + 1));
        });
    }
    function clientCFMHandler(checked) {
        return __awaiter(this, void 0, void 0, function () {
            var _i, calculations_1, calculation;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        onClientCFMChange(checked);
                        _i = 0, calculations_1 = calculations;
                        _a.label = 1;
                    case 1:
                        if (!(_i < calculations_1.length)) return [3 /*break*/, 6];
                        calculation = calculations_1[_i];
                        if (!checked) return [3 /*break*/, 3];
                        return [4 /*yield*/, handleOnPrintSwitchChange(false, calculation.id, 'CAR')];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, handleOnPrintSwitchChange(true, calculation.id, 'CAR')];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 1];
                    case 6: return [2 /*return*/];
                }
            });
        });
    }
    var selectedDriveData = drives.find(function (drive) { return drive.powerTrainId === offer.vehicle.powerTrainId; });
    var drive = selectedDriveData.displayName.split(',')[0];
    function handleOnCalculationError(id, value) {
        setCalculations(function (currentCalculations) {
            var editedCalculationIndex = currentCalculations.findIndex(function (calculation) { return calculation.id === id; });
            return __spreadArrays(currentCalculations.slice(0, editedCalculationIndex), [
                __assign(__assign({}, currentCalculations[editedCalculationIndex]), { isCalculateError: value })
            ], currentCalculations.slice(editedCalculationIndex + 1));
        });
    }
    var isSomeCalculationWithError = calculations && calculations.some(function (calculation) { return calculation.isCalculateError === true; });
    return isFinancingError() && !isSomeCalculationWithError ? (React.createElement("div", null,
        React.createElement("div", { className: "row", style: { marginTop: '104px' } },
            React.createElement("img", { src: "/assets/images/new-dol/volvo_error_car.png", alt: "volvo_car" })),
        React.createElement("hr", null),
        React.createElement("div", { className: "errorHeader", style: { marginTop: '25px' } }, getErrorDesc()))) : foundingProducts && foundingProducts.foundingProducts.length < 1 ? (React.createElement("div", null,
        React.createElement("div", { className: "row", style: { marginTop: '104px' } },
            React.createElement("img", { src: "/assets/images/new-dol/volvo_error_car.png", alt: "volvo_car" })),
        React.createElement("hr", null),
        React.createElement("div", { className: "errorHeader", style: { marginTop: '25px' } }, "Brak aktywnych produkt\u00F3w finansowych"))) : foundingProducts ? (React.createElement("div", { className: "financing" },
        React.createElement("div", { className: "clientCFM_wrapper" },
            React.createElement(SectionHeader, null,
                React.createElement("div", { className: "financing_clientCFM_header_wrapper", "data-testid": "financing_clientCFM_header_wrapper" },
                    React.createElement("div", null,
                        "kalkulacja finansowania twojego volvo ",
                        offer.vehicle.name,
                        " ",
                        drive),
                    React.createElement("div", { className: "clientCFM_checkbox_wrapper" },
                        React.createElement(Checkbox.Root, { id: "clientCFM", value: "CFM", checked: isClientCFM, onCheckedChange: function (checked) { return clientCFMHandler(checked); } },
                            React.createElement(Checkbox.Indicator, null)),
                        React.createElement(Label, { htmlFor: "clientCFM" }, "Klient CFM / Konsument"))))),
        calculations
            ? calculations.map(function (calculation, index) { return (React.createElement(Calculate, { key: calculation.id, foundingProducts: foundingProducts, offer: offer, offerId: offerId, id: calculation.id, forPrinting: calculation.forPrinting, canDelete: calculations.length > 1, calculationNumber: index + 1, onPrintSwitchChange: function (checked) { return handleOnPrintSwitchChange(checked, calculation.id, 'CAR'); }, onDeleteCalculationClick: function () {
                    handleOnDeleteCalculationClick(calculation.id, 'CAR');
                }, canScroll: calculations.length > 1, handleChangeIsCalculationIdRegister: handleChangeIsCalculationIdRegister, isClientCFM: isClientCFM, handleOnCalculationError: handleOnCalculationError, isCalculateError: calculation.isCalculateError })); })
            : null,
        React.createElement("div", { className: "financing_add_calculation_wrapper" },
            React.createElement("button", { type: "button", className: "financing_add_calculation_button", onClick: function () { return handleOnAddCalculationClick('CAR'); } },
                React.createElement(Plus, { className: "plus" }),
                React.createElement("span", null, "Dodaj kalkulacj\u0119 finansowania auta"))))) : null;
});
