import React from 'react';
import './accordion.css';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { ChevronDown } from './ChevronDown.component';
import { ChevronUp } from './ChevronUp.component';
import { TextAmount } from 'components/textAmount/TextAmount';
function Root(_a) {
    var defaultValue = _a.defaultValue, children = _a.children;
    return (React.createElement(RadixAccordion.Root, { type: "multiple", className: "accordionRoot", defaultValue: defaultValue }, children));
}
function Item(_a) {
    var value = _a.value, children = _a.children, className = _a.className;
    return (React.createElement(RadixAccordion.Item, { value: value, className: "accordionItem " + (className ? className : '') }, children));
}
function Trigger(_a) {
    var _b = _a.uppercaseName, uppercaseName = _b === void 0 ? true : _b, _c = _a.backgroundColor, backgroundColor = _c === void 0 ? 'default' : _c, children = _a.children;
    return (React.createElement(RadixAccordion.Header, { className: "accordionHeader " + backgroundColor },
        React.createElement(RadixAccordion.Trigger, { className: "accordionTrigger", "data-testid": "accordion-trigger" },
            React.createElement("div", { className: 'accordionTriggerWrapper' },
                React.createElement("div", { className: "accordionName " + (uppercaseName ? 'accordionNameUppercase' : '') }, children),
                React.createElement("div", null,
                    React.createElement(ChevronDown, { className: "chevronDown" }),
                    React.createElement(ChevronUp, { className: "chevronUp" }))))));
}
function TriggerForSummaryAccordion(_a) {
    var total = _a.total, children = _a.children;
    return (React.createElement(RadixAccordion.Header, { className: 'accordionHeader firstListItem' },
        React.createElement(RadixAccordion.Trigger, { className: "accordionTrigger", "data-testid": "accordion-trigger" },
            React.createElement("div", { className: 'accordionTriggerWrapper forOptionsSummary' },
                React.createElement("div", { className: 'accordionName accordionNameUppercase' }, children),
                React.createElement("div", null, total !== 0 ? React.createElement(TextAmount, { bold: true }, total) : null),
                React.createElement("div", null,
                    React.createElement(ChevronDown, { className: "chevronDown" }),
                    React.createElement(ChevronUp, { className: "chevronUp" }))))));
}
function Content(_a) {
    var children = _a.children;
    return (React.createElement(RadixAccordion.Content, { className: "accordionContent", "data-testid": "accordion-content" }, children));
}
export var Accordion = {
    Root: Root,
    Item: Item,
    Trigger: Trigger,
    TriggerForSummaryAccordion: TriggerForSummaryAccordion,
    Content: Content,
};
