var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useState, useEffect } from 'react';
import { useOptionsApi } from './useOptionsApi';
import UserContext from 'common/userContext';
import ReactDOM from 'react-dom';
import { useCreatorApi } from '../useCreatorApi';
import { useStatus } from '../../../common/statusContext';
function isFromVCP() {
    return UserContext.isFromVCP() && !UserContext.hasAnyRole('VCP_EMPLOYEE_LEASING');
}
function getSelectedOptions(allSelectedOptions, availableOptions) {
    var optionsToSelect = availableOptions.filter(function (availableOption) {
        return allSelectedOptions.includes(availableOption.id);
    });
    return optionsToSelect.map(function (option) { return option.id; });
}
export function useOptionsTab(_a) {
    var carModelId = _a.carModelId, token = _a.token, handleOnTokenChange = _a.handleOnTokenChange, canBeSynced = _a.canBeSynced, onSynced = _a.onSynced, onOptionChange = _a.onOptionChange, offer = _a.offer, handleChangeOffer = _a.handleChangeOffer, handleChangeClientId = _a.handleChangeClientId, employeeLeasingMode = _a.employeeLeasingMode;
    var _b = useState([]), selectedPackages = _b[0], setSelectedPackages = _b[1];
    var _c = useState([]), selectedOptions = _c[0], setSelectedOptions = _c[1];
    var _d = useState(undefined), options = _d[0], setOptions = _d[1];
    var _e = useState([]), selectedSubOptions = _e[0], setSelectedSubOptions = _e[1];
    var optionsApi = useOptionsApi();
    var creatorApi = useCreatorApi(employeeLeasingMode ? { context: 'employeeLeasing' } : undefined);
    var addOptionRequest = creatorApi.addOption;
    var deleteOptionRequest = creatorApi.deleteOption;
    var selectSubOptionRequest = creatorApi.selectSubOption;
    var setStatus = useStatus().setStatus;
    function handleChangeSelectedPackage(checked, id) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        if (!checked) return [3 /*break*/, 2];
                        return [4 /*yield*/, addOptionRequest(offer.id, id, carModelId)];
                    case 1:
                        _a.sent();
                        setSelectedPackages(function (currentSelectedPackages) { return __spreadArrays(currentSelectedPackages, [id]); });
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, deleteOptionRequest(offer.id, id, carModelId)];
                    case 3:
                        _a.sent();
                        setSelectedPackages(function (currentSelectedPackages) {
                            return __spreadArrays(currentSelectedPackages).filter(function (option) { return option !== id; });
                        });
                        _a.label = 4;
                    case 4: return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 5:
                        getOfferResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            handleChangeOffer(getOfferResponse);
                            handleOnTokenChange(getOfferResponse.vehicle.tokenConfiguration);
                            onOptionChange();
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleChangeSelectedOption(checked, id) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        if (!checked) return [3 /*break*/, 2];
                        return [4 /*yield*/, addOptionRequest(offer.id, id, carModelId)];
                    case 1:
                        _a.sent();
                        setSelectedOptions(function (currentSelectedOptions) { return __spreadArrays(currentSelectedOptions, [id]); });
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, deleteOptionRequest(offer.id, id, carModelId)];
                    case 3:
                        _a.sent();
                        setSelectedOptions(function (currentSelectedOptions) {
                            return __spreadArrays(currentSelectedOptions).filter(function (option) { return option !== id; });
                        });
                        _a.label = 4;
                    case 4: return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 5:
                        getOfferResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            handleChangeOffer(getOfferResponse);
                            if (getOfferResponse.client.clientId) {
                                handleChangeClientId(getOfferResponse.client.clientId);
                            }
                            handleOnTokenChange(getOfferResponse.vehicle.tokenConfiguration);
                            onOptionChange();
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleChangeSelectedSubOption(packageId, elementId) {
        return __awaiter(this, void 0, void 0, function () {
            var getOfferResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, selectSubOptionRequest(offer.id, packageId, elementId, carModelId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, creatorApi.getOffer(offer.id)];
                    case 2:
                        getOfferResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatus('success');
                            setSelectedSubOptions(getOfferResponse.vehicle.packageVariants);
                            handleChangeOffer(getOfferResponse);
                            if (getOfferResponse.client.clientId) {
                                handleChangeClientId(getOfferResponse.client.clientId);
                            }
                            handleOnTokenChange(getOfferResponse.vehicle.tokenConfiguration);
                            onOptionChange();
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        if (canBeSynced &&
            carModelId &&
            token &&
            offer) {
            var getOptionsRequest = isFromVCP() ? optionsApi.getOptionsVcp : optionsApi.getOptions;
            setStatus('pending');
            getOptionsRequest(carModelId, token).then(function (data) {
                ReactDOM.unstable_batchedUpdates(function () {
                    setOptions(data);
                    // pakiety defaultowe na starcie
                    var packages = data.find(function (option) { return option.category.toUpperCase() === 'PACKAGE' || option.category.toUpperCase() === 'PAKIETY'; });
                    if (packages) {
                        setSelectedPackages(getSelectedOptions(offer.vehicle.selectedOptions, packages.availableOptions));
                        setSelectedSubOptions(offer.vehicle.packageVariants);
                    }
                    // opcje defaultowe na starcie
                    var options = data.filter(function (option) { return option.category.toUpperCase() !== 'PACKAGE' && option.category.toUpperCase() !== 'PAKIETY'; }).flatMap(function (option) { return option.availableOptions; });
                    setStatus('success');
                    setSelectedOptions(getSelectedOptions(offer.vehicle.selectedOptions, options));
                    onSynced();
                });
            });
        }
    }, [
        canBeSynced,
        carModelId,
        offer,
        token
    ]);
    return {
        selectedPackages: selectedPackages,
        selectedOptions: selectedOptions,
        options: options,
        onSelectedPackageChange: handleChangeSelectedPackage,
        onSelectedOptionChange: handleChangeSelectedOption,
        onSelectedSubOptionChange: handleChangeSelectedSubOption,
        selectedSubOptions: selectedSubOptions
    };
}
