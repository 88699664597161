import { useHttp } from 'http/httpService';
import SessionStorage from 'common/sessionStorage';
import { creatorContextPath } from '../CreatorContextPath';
export function usePricesListApi() {
    var http = useHttp();
    var context = SessionStorage.get('employeeLeasingMode') ? 'employeeLeasing' : 'creator';
    return {
        getPricesList: function (year, modelName) {
            return http
                .get("/api/" + creatorContextPath(context) + "/priceList/" + year + "/" + modelName)
                .then(function (response) { return response.data; });
        },
        getPricesListVcp: function (year, modelName) {
            return http
                .get("/api/" + creatorContextPath(context) + "/priceList/vcp/" + year + "/" + modelName)
                .then(function (response) { return response.data; });
        },
        getPricesListForEL: function (modelName) {
            return http
                .get("/api/creator-el/priceList/" + modelName)
                .then(function (response) { return response.data; });
        }
    };
}
