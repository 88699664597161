var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { ViewSection } from '../viewSection/ViewSection';
import { useStatus } from 'common/statusContext';
import { useSpecialOfferApi } from '../useSpecialOfferApi';
import { EditApplicantData } from '../applicantData/EditApplicantData';
import { Spinner } from 'components/spinner/Spinner';
import './editSpecialOfferForm.css';
import { EditClientSection } from '../clientSection/EditClientSection';
import { useEditClientSection } from '../clientSection/useEditClientSection';
import { EditRequestSubject } from '../requestSubject/EditRequestSubject';
import { AccordionRequestSubject } from '../requestSubject/AccordionRequestSubject';
import { useEditSpecialOfferForm } from './useEditSpecialOfferForm';
import ReactDOM from 'react-dom';
import { formDictionary } from '../requestSubject/RequestSubject';
import { HorizontalLine } from '../horizontalLine/HorizontalLine';
import { Button } from 'components/button/Button.component';
import { InfoDialog } from '../InfoDialog';
import { format } from 'date-fns';
import UserContext from 'common/userContext';
import SessionStorage from '../../../common/sessionStorage';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
export var EditSpecialOfferForm = withCancellation(function (_a) {
    var specialOffer = _a.specialOffer;
    var _b = useStatus(), status = _b.status, setStatus = _b.setStatus;
    var _c = useState(new Date(specialOffer.validTo)), newValidTo = _c[0], setNewValidTo = _c[1];
    var _d = useState([]), fundingForms = _d[0], setFundingForms = _d[1];
    var _e = useState([]), leasingProviders = _e[0], setLeasingProviders = _e[1];
    var _f = useState([]), cfmProviders = _f[0], setCfmProviders = _f[1];
    var _g = useState([]), availableVCPLevelPercent = _g[0], setAvailableVCPLevelPercent = _g[1];
    var _h = useState([]), availableDealerShareMarginPercent = _h[0], setAvailableDealerShareMarginPercent = _h[1];
    var _j = useState(false), openConfirmDialog = _j[0], setOpenConfirmDialog = _j[1];
    var _k = useState(''), dialogMessage = _k[0], setDialogMessage = _k[1];
    var _l = useState(), applicantName = _l[0], setApplicantName = _l[1];
    var specialOfferApi = useSpecialOfferApi();
    var _m = window.location.href.split('?'), searchParamsString = _m[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var specialOfferNumber = searchParams.get('specialOfferNumber');
    useEffect(function () {
        function getFunding() {
            return __awaiter(this, void 0, void 0, function () {
                var fundingResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, specialOfferApi.fundingForms()];
                        case 1:
                            fundingResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setFundingForms(fundingResponse.fundingFormCodesList);
                                setLeasingProviders(fundingResponse.leasingProviders);
                                setCfmProviders(fundingResponse.cfmProviders);
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getPercentages() {
            return __awaiter(this, void 0, void 0, function () {
                var percentageResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, specialOfferApi.getAvailablePercentages()];
                        case 1:
                            percentageResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setAvailableVCPLevelPercent(percentageResponse.VCPLevelPercent);
                                setAvailableDealerShareMarginPercent(percentageResponse.dealerShareMarginPercent);
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getApplicantName() {
            return __awaiter(this, void 0, void 0, function () {
                var applicantDataResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, specialOfferApi.getApplicantData()];
                        case 1:
                            applicantDataResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setApplicantName(applicantDataResponse.name);
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        getFunding();
        getPercentages();
        getApplicantName();
    }, []);
    function handleChangeValidToDate(date) {
        setNewValidTo(date);
    }
    var applicantSection = {
        applicantData: {
            applicantId: specialOffer.applicant.login,
            applicantName: specialOffer.applicant.name,
            organizationId: specialOffer.applicant.organizationId,
            organizationName: specialOffer.applicant.organizationName,
            applicationDate: specialOffer.applicant.applicationDate,
            validTo: specialOffer.validTo,
        },
    };
    var editApplicantSection = {
        newValidTo: newValidTo,
        handleChangeValidToDate: handleChangeValidToDate,
    };
    var clientSection = {
        party: specialOffer.ordering.party,
        requestAttachments: specialOffer.requestAttachments,
        policyAttachments: specialOffer.policyAttachments,
        partyCars: specialOffer.ownedCars,
        type: specialOffer.type,
        requestDate: new Date(specialOffer.queryDate),
        capitalGroup: specialOffer.capitalGroup
    };
    var requestSubjectSection = {
        requestSubjects: specialOffer.querySubject,
    };
    var editClientSection = useEditClientSection();
    var _o = useEditSpecialOfferForm(requestSubjectSection.requestSubjects), updateQuerySubject = _o.updateQuerySubject, formApi = _o.formApi;
    var availableDealerShareMarginPercentDropdownOptions = availableDealerShareMarginPercent.map(function (option) {
        return {
            value: option,
            label: option.toString().replace('.', ',') + "%",
        };
    });
    var availableVCPLevelPercentDropdownOptions = availableVCPLevelPercent.map(function (option) {
        return {
            value: option,
            label: option.toString().replace('.', ',') + "%",
        };
    });
    var availableFundingForms = fundingForms.map(function (option) {
        return {
            value: option,
            label: "" + formDictionary[option],
        };
    });
    function areSomeChangesForSpecialOfferId(specialOfferId) {
        return (updateQuerySubject[specialOfferId].hasOwnProperty('newDealerMargin') ||
            updateQuerySubject[specialOfferId].hasOwnProperty('newFleetCoofinancing') ||
            updateQuerySubject[specialOfferId].hasOwnProperty('newFundingForm') ||
            updateQuerySubject[specialOfferId].newCarCounter !== '');
    }
    var isDateToChanged = newValidTo.getTime() !== new Date(specialOffer.validTo).getTime();
    function onSaveEditSpecialOfferClick() {
        return __awaiter(this, void 0, void 0, function () {
            function setErrorStatus(dialogMessage, consoleMessage) {
                setStatus('error');
                setDialogMessage(dialogMessage);
                setOpenConfirmDialog(true);
                console.error(consoleMessage);
            }
            var updateQuerySubjectArray, updatedQuerySubjects, extendSpecialOfferJson, formattedNewValidTo, changeValidToJson, saveEditingSpecialOfferResponse, saveNewValidToSpecialOfferResponse, searchParams_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updateQuerySubjectArray = Object.entries(updateQuerySubject).map(function (entry) {
                            var specialOfferId = entry[0], querySubject = entry[1];
                            var currentRequestSubject = requestSubjectSection.requestSubjects.find(function (requestSubject) { return requestSubject.specialOfferId === specialOfferId; });
                            var carCounter = Number(querySubject.newCarCounter) - Number(currentRequestSubject.carCounter);
                            var fundingProvider = querySubject.newFundingProvider
                                ? querySubject.newFundingProvider.value
                                : querySubject.newOtherProvider
                                    ? querySubject.newOtherProvider
                                    : '';
                            var isCarCounterChanged = carCounter > 0;
                            if (areSomeChangesForSpecialOfferId(specialOfferId)) {
                                return {
                                    offerId: specialOfferId,
                                    carCounter: isCarCounterChanged ? carCounter : null,
                                    financingName: querySubject.newFundingForm && querySubject.newFundingForm.value
                                        ? querySubject.newFundingForm.value
                                        : null,
                                    financingValue: querySubject.newFundingForm ? fundingProvider : null,
                                    vcpLevelNet: querySubject.newFleetCoofinancing && querySubject.newFleetCoofinancing.value
                                        ? querySubject.newFleetCoofinancing.value
                                        : null,
                                    dealerShareMarginNet: querySubject.newDealerMargin && querySubject.newDealerMargin.value
                                        ? querySubject.newDealerMargin.value
                                        : null,
                                };
                            }
                        });
                        updatedQuerySubjects = updateQuerySubjectArray.filter(function (querySubject) { return querySubject; });
                        extendSpecialOfferJson = {
                            updateQuerySubjectJsons: updatedQuerySubjects,
                            applicant: {
                                organizationId: SessionStorage.get('organizationId'),
                                organizationName: SessionStorage.get('organizationName'),
                                login: SessionStorage.get('login'),
                                name: applicantName
                            },
                        };
                        formattedNewValidTo = format(new Date(newValidTo), 'yyyy-MM-dd');
                        changeValidToJson = {
                            validTo: formattedNewValidTo,
                            requestAttachments: editClientSection.newRequestAttachments,
                            policyAttachments: editClientSection.newPolicyAttachments,
                            applicant: {
                                organizationId: SessionStorage.get('organizationId'),
                                organizationName: SessionStorage.get('organizationName'),
                                login: SessionStorage.get('login'),
                                name: applicantName
                            },
                        };
                        if (!(isDateToChanged || updatedQuerySubjects.length > 0)) return [3 /*break*/, 5];
                        setStatus('pending');
                        if (!(updatedQuerySubjects.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, specialOfferApi.saveEditSpecialOffer(specialOfferNumber, extendSpecialOfferJson)];
                    case 1:
                        saveEditingSpecialOfferResponse = _a.sent();
                        if (!saveEditingSpecialOfferResponse) {
                            setErrorStatus('Nie udało się zapisać zmienionych warunków oferty specjalnej. Spróbuj ponownie później.', 'Błąd zapisu zmiany warunków oferty specjalnej');
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2:
                        if (!isDateToChanged) return [3 /*break*/, 4];
                        return [4 /*yield*/, specialOfferApi.saveNewDateTo(specialOfferNumber, changeValidToJson)];
                    case 3:
                        saveNewValidToSpecialOfferResponse = _a.sent();
                        if (!saveNewValidToSpecialOfferResponse) {
                            setErrorStatus('Nie udało się wysłać nowej daty ważności oferty specjalnej. Spróbuj ponownie później.', 'Błąd zapisu nowej daty ważności oferty specjalnej');
                            return [2 /*return*/];
                        }
                        _a.label = 4;
                    case 4:
                        setStatus('success');
                        searchParams_1 = new URLSearchParams();
                        searchParams_1.set('specialOfferNumber', specialOfferNumber);
                        window.location.hash = '#/special-offer/preview?' + searchParams_1.toString();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    function handleOnConfirmDialog() {
        setOpenConfirmDialog(false);
        setDialogMessage('');
    }
    function isSaveDisabled() {
        var updateQuerySubjectArray = Object.entries(updateQuerySubject);
        var areSomeChangesInQuerySubjects = false;
        var isSomeProviderMissing = false;
        var isSomeCarCouterToSmall = false;
        updateQuerySubjectArray.forEach(function (element) {
            var specialOfferId = element[0], querySubject = element[1];
            if (areSomeChangesForSpecialOfferId(specialOfferId)) {
                areSomeChangesInQuerySubjects = true;
            }
            ;
            if (querySubject.newFundingForm && querySubject.newFundingForm.value !== 'CASH') {
                isSomeProviderMissing = querySubject.newFundingProvider === '' && querySubject.newOtherProvider === '';
            }
            var currentRequestSubject = requestSubjectSection.requestSubjects.find(function (requestSubject) { return requestSubject.specialOfferId === specialOfferId; });
            var carCounter = Number(querySubject.newCarCounter) - Number(currentRequestSubject.carCounter);
            if (querySubject.newCarCounter !== '' && carCounter <= 0) {
                isSomeCarCouterToSmall = true;
            }
        });
        return (!isDateToChanged && !areSomeChangesInQuerySubjects) || (areSomeChangesInQuerySubjects && isSomeCarCouterToSmall || isSomeProviderMissing);
    }
    var validStatuses = ['APPROVED', 'APPROVED_RECKONING', 'APPROVED_CONDITION', 'DECLINED_CONDITION', 'APPROVED_DURATION_EXTENSION', 'BUSINESS_DURATION_EXTENSION', 'DECLINED_DURATION_EXTENSION'];
    var requestSubjectsToEdit = requestSubjectSection.requestSubjects.filter(function (requestSubject) { return validStatuses.includes(requestSubject.status); });
    var isOSFromMyOrganization = specialOffer && (UserContext.organizationId() === specialOffer.applicant.organizationId);
    useEffect(function () {
        if (specialOffer && !isOSFromMyOrganization) {
            setDialogMessage('Brak dostępu!');
            setOpenConfirmDialog(true);
        }
    }, [specialOffer, isOSFromMyOrganization]);
    return (isOSFromMyOrganization ? (React.createElement("div", { className: "editSpecialOfferWrapper" },
        React.createElement(ViewSection, { title: "wnioskuj\u0105cy", dataTestid: "special-offer-applicant-section" },
            React.createElement(EditApplicantData, { applicantSection: applicantSection, applicationId: specialOfferNumber, editApplicantSection: editApplicantSection })),
        React.createElement(ViewSection, { title: "zamawiaj\u0105cy", dataTestid: "special-offer-client-section" },
            React.createElement(EditClientSection, { clientSection: clientSection, editClientSection: editClientSection, canAddAttachment: isDateToChanged })),
        React.createElement(ViewSection, { title: "przedmiot zapytania", dataTestid: "special-offer-request-subject" },
            React.createElement(AccordionRequestSubject.Root, null, requestSubjectsToEdit.map(function (requestSubject) {
                return requestSubject ? (React.createElement(AccordionRequestSubject.Item, { value: requestSubject.pno12, key: requestSubject.specialOfferId },
                    React.createElement(AccordionRequestSubject.Trigger, null, requestSubject.car + " " + requestSubject.driver + " " + requestSubject.version),
                    React.createElement(AccordionRequestSubject.Content, null,
                        React.createElement(EditRequestSubject, { requestSubject: requestSubject, updatedRequestSubject: updateQuerySubject[requestSubject.specialOfferId], formApi: formApi, availableDealerShareMarginPercentDropdownOptions: availableDealerShareMarginPercentDropdownOptions, availableVCPLevelPercentDropdownOptions: availableVCPLevelPercentDropdownOptions, availableFundingForms: availableFundingForms, leasingProvidersDropdownOptions: leasingProviders, cfmProvidersDropdownOptions: cfmProviders })))) : null;
            }))),
        React.createElement(HorizontalLine, { color: "#6D6D6D" }),
        React.createElement("div", { className: "specialOfferSubmitButtonWrapper" },
            React.createElement("div", { className: "specialOfferSubmitButton" },
                React.createElement(Button, { variant: "primary", type: "button", onClick: onSaveEditSpecialOfferClick, disabled: isSaveDisabled() }, "wy\u015Blij zapytanie"))),
        React.createElement(InfoDialog, { open: openConfirmDialog, message: dialogMessage, onConfirm: handleOnConfirmDialog }),
        status === 'pending' ? React.createElement(Spinner, null) : null)) : React.createElement(InfoDialog, { open: openConfirmDialog, message: dialogMessage, onConfirm: handleOnConfirmDialog }));
});
