import { useHttp } from 'http/httpService';
export function useAttractivenessApi() {
    var http = useHttp();
    return {
        addPrintTypeOption: function (offerId, printType) {
            return http
                .post("/api/creator/offer/" + offerId + "/printType/" + printType)
                .then(function (response) { return response.data; });
        },
        deletePrintTypeOption: function (offerId, printType) {
            return http["delete"]("/api/creator/offer/" + offerId + "/printType/" + printType)
                .then(function (response) { return response.data; });
        },
        addDiscount: function (offerId, discountValue, type, value, name) {
            return http
                .post("/api/creator/offer/" + offerId + "/discount", {
                discountValue: discountValue,
                type: type,
                value: value,
                name: name
            })
                .then(function (response) { return response.data; });
        },
        modifyDiscount: function (offerId, discountValue, type, value, name) {
            return http
                .put("/api/creator/offer/" + offerId + "/discount", {
                discountValue: discountValue,
                type: type,
                value: value,
                name: name
            })
                .then(function (response) { return response.data; });
        },
        deleteDiscount: function (offerId, discountValue) {
            return http["delete"]("/api/creator/offer/" + offerId + "/discount/" + discountValue);
        }
    };
}
