var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useAppError } from 'common/appErrorContext';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useStatus } from 'common/statusContext';
import { Button } from 'components/button/Button.component';
import { createAxiosInstance } from 'http/httpService';
import { ConfirmDialog } from 'pages/priceListImport/confirmDialog/ConfirmDialog.component';
import { usePriceListApi } from 'pages/priceListImport/usePriceListApi';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './dataImport.css';
import { useInterval } from './useInterval';
import SessionStorage from 'common/sessionStorage';
export var DataImport = withCancellation(function (_a) {
    var title = _a.title, handleGoToNextWizardStep = _a.handleGoToNextWizardStep, cancelPopupMessage = _a.cancelPopupMessage, currentPriceListName = _a.currentPriceListName;
    var history = useHistory();
    var _b = useState('IDLE'), importStatus = _b[0], setImportStatus = _b[1];
    var _c = useState(false), openConfirmDialog = _c[0], setOpenConfirmDialog = _c[1];
    var _d = useState([]), pendingLogs = _d[0], setPendingLogs = _d[1];
    var _e = useState(15000), pollingDelay = _e[0], setPollingDelay = _e[1];
    var _f = useState(0), errorsCount = _f[0], setErrorsCounts = _f[1];
    var isEmployeeLeasing = SessionStorage.get('employeeLeasingContext');
    var priceListApi = isEmployeeLeasing ? usePriceListApi({ context: 'employeeLeasing' }) : usePriceListApi();
    var setAppError = useAppError().setAppError;
    var setStatus = useStatus().setStatus;
    function handleChangeImportStatus(newStatus) {
        setImportStatus(newStatus);
    }
    function handleOnCancelClick() {
        setOpenConfirmDialog(true);
    }
    function handleOnGenerateCSVClick() {
        return __awaiter(this, void 0, void 0, function () {
            var csvFileResponse, link, responseBlob;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, priceListApi.getCsvFile()];
                    case 1:
                        csvFileResponse = _a.sent();
                        link = document.createElement('a');
                        responseBlob = new Blob([csvFileResponse.data], {
                            type: csvFileResponse.contentType,
                        });
                        link.href = URL.createObjectURL(responseBlob);
                        link.download = csvFileResponse.filename;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(link.href);
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleOnCancelDialog() {
        setOpenConfirmDialog(false);
    }
    function handleOnConfirmDialog() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, priceListApi.cancelImport(currentPriceListName)];
                    case 1:
                        _a.sent();
                        setOpenConfirmDialog(false);
                        SessionStorage.remove('employeeLeasingContext');
                        history.push('/price-lists');
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        function importStatus() {
            return __awaiter(this, void 0, void 0, function () {
                var statusResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, priceListApi.getImportStatus()];
                        case 1:
                            statusResponse = _a.sent();
                            if (statusResponse[0].status === 'DOWNLOAD') {
                                handleChangeImportStatus('PENDING');
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        importStatus();
    }, []);
    var handleInterval = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var statusResponse, logsResponse, errorsLogResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, priceListApi.getImportStatus()];
                case 1:
                    statusResponse = _a.sent();
                    if (statusResponse[0].status === 'DOWNLOADED') {
                        handleChangeImportStatus('SUCCESS');
                        setPendingLogs([]);
                        setPollingDelay(null);
                    }
                    if (!(statusResponse[0].status === 'DOWNLOAD')) return [3 /*break*/, 4];
                    return [4 /*yield*/, priceListApi.getImportLog()];
                case 2:
                    logsResponse = _a.sent();
                    return [4 /*yield*/, priceListApi.getErrorsImportLogs()];
                case 3:
                    errorsLogResponse = _a.sent();
                    setPendingLogs(logsResponse.content);
                    setErrorsCounts(errorsLogResponse.count);
                    _a.label = 4;
                case 4:
                    if (statusResponse[0].status === 'ERROR') {
                        handleChangeImportStatus('ERROR');
                        setPollingDelay(null);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, []);
    useInterval(handleInterval, pollingDelay);
    useEffect(function () {
        return function () {
            if (importStatus === 'ERROR') {
                // poniższy kod omija cancel request, żeby request nie został usunięty przy odmontowywaniu się komponentu
                var http = createAxiosInstance(setAppError, history, setStatus);
                http.put("/api/creator/prices/cancel-import/" + currentPriceListName);
            }
        };
    }, [importStatus, currentPriceListName]);
    return (React.createElement("div", { className: "importDataWrapper" },
        React.createElement("div", { className: "importDataInfoWrapper" },
            React.createElement("div", { className: "importDataInfoTitle" },
                React.createElement("p", null, title),
                React.createElement("p", { className: "" + (errorsCount > 0 ? 'importDataInfoErrors' : '') },
                    "Liczba b\u0142\u0119d\u00F3w w imporcie: ",
                    errorsCount)),
            React.createElement("div", { className: "importDataInfoLogsWrapper" },
                importStatus === 'PENDING'
                    ? pendingLogs.map(function (log) { return React.createElement("div", { "data-testid": 'price-list-import-log', key: log.id, className: "importDataInfoLog " + (log.level === 'ERROR' ? 'errorLog' : '') }, log.log); })
                    : null,
                importStatus === 'SUCCESS' ? (React.createElement("div", { className: "importDataInfoSuccess" }, "IMPORT ZAKO\u0143CZONY SUKCESEM")) : null,
                importStatus === 'ERROR' ? React.createElement("div", { className: "importDataInfoError" }, "IMPORT SI\u0118 NIE UDA\u0141") : null)),
        React.createElement("div", { className: "importDataActionsWrapper" },
            React.createElement("div", { className: "dataImportButtonWrapper" },
                React.createElement(Button, { type: "button", variant: "secondary", onClick: handleOnCancelClick }, "Anuluj")),
            React.createElement("div", { className: "dataImportButtonWrapper" },
                React.createElement(Button, { type: "button", variant: "secondary", onClick: handleOnGenerateCSVClick, disabled: importStatus !== 'SUCCESS' && importStatus !== 'ERROR' }, "Wygeneruj CSV")),
            React.createElement("div", { className: "dataImportButtonWrapper" },
                React.createElement(Button, { type: "button", onClick: handleGoToNextWizardStep, disabled: importStatus !== 'SUCCESS' }, "Dalej"))),
        React.createElement(ConfirmDialog, { open: openConfirmDialog, message: cancelPopupMessage, onCancel: handleOnCancelDialog, onConfirm: handleOnConfirmDialog })));
});
