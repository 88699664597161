import { useHttp } from 'http/httpService';
import SessionStorage from 'common/sessionStorage';
import { creatorContextPath } from '../CreatorContextPath';
export function useOptionsApi() {
    var http = useHttp();
    var context = SessionStorage.get('employeeLeasingMode') ? 'employeeLeasing' : 'creator';
    return {
        getOptions: function (carModelId, token) {
            return http
                .get("/api/" + creatorContextPath(context) + "/options/" + carModelId + "?token=" + token)
                .then(function (response) { return response.data; });
        },
        getOptionsVcp: function (carModelId, token) {
            return http
                .get("/api/" + creatorContextPath(context) + "/options/vcp/" + carModelId + "?token=" + token)
                .then(function (response) { return response.data; });
        }
    };
}
