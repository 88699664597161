import { useHttp } from 'http/httpService';
export function useFinancingApi() {
    var http = useHttp();
    return {
        changeFinalValueRatio: function (productId, offerId, installment, protocolDeliveryDate, millageLimitId) {
            return http
                .post("/api/creator/zps/" + productId + "/finalValueRatio?offerContext=" + offerId, {
                installmentNo: installment,
                deliveryProtocolDate: protocolDeliveryDate,
                millageLimitId: millageLimitId,
            })
                .then(function (response) { return response.data; });
        },
        calculate: function (productName, productId, offerId, installment, protocolDeliveryDate, mileageLimitId, entryFeeRatio, finalValueRatio, appealLevel, currencyCode, interestMethodCode, entryFeeDate, mileageLimitValue, calculateId, toPrint, order) {
            return http
                .post("/api/creator/zps/" + productId + "/calculate?offerContext=" + offerId + "&calculatorId=" + calculateId + "&toPrint=" + toPrint + "&order=" + order, {
                productName: productName,
                installment: installment,
                protocolDeliveryDate: protocolDeliveryDate,
                mileageLimitId: mileageLimitId,
                entryFeeRatio: entryFeeRatio,
                finalValueRatio: finalValueRatio,
                appealLevel: appealLevel,
                currencyCode: currencyCode,
                interestMethodCode: interestMethodCode,
                entryFeeDate: entryFeeDate,
                mileageLimitValue: mileageLimitValue
            })
                .then(function (response) { return response.data; });
        },
        getProductsList: function (offerId) {
            return http.get("/api/creator/zps/list?offerContext=" + offerId).then(function (response) { return response.data; });
        },
        enablePrint: function (calculateId) {
            return http
                .put("/api/creator/zps/calculator/" + calculateId + "/enable-print")
                .then(function (response) { return response.data; });
        },
        disablePrint: function (calculateId) {
            return http
                .put("/api/creator/zps/calculator/" + calculateId + "/disable-print")
                .then(function (response) { return response.data; });
        },
        deleteCalculate: function (calculateId) {
            return http["delete"]("/api/creator/zps/calculator/" + calculateId)
                .then(function (response) { return response.data; });
        }
    };
}
