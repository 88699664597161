var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PriceListsHeader } from 'components/priceListsHeader/PriceListsHeader.component';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './modifyPriceList.css';
import { List } from 'components/list/List.component';
import { Switch } from 'components/switch/Switch';
import { Button } from 'components/button/Button.component';
import { formatDate } from 'utils/formatDate';
import { usePriceListApi } from '../usePriceListApi';
import { useStatus } from 'common/statusContext';
import ReactDOM from 'react-dom';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { immutableSort } from 'utils/immutableSort';
import { Spinner } from 'components/spinner/Spinner';
import SessionStorage from 'common/sessionStorage';
export var ModifyPriceList = withCancellation(function () {
    var priceListName = useParams().priceListName;
    var _a = useState(undefined), priceListData = _a[0], setPriceListData = _a[1];
    var history = useHistory();
    var isEmployeeLeasing = SessionStorage.get('employeeLeasingContext');
    var priceListsApi = isEmployeeLeasing ? usePriceListApi({ context: 'employeeLeasing' }) : usePriceListApi();
    var _b = useStatus(), status = _b.status, setStatus = _b.setStatus;
    function getPriceListData() {
        return __awaiter(this, void 0, void 0, function () {
            var priceListDataResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, priceListsApi.getPriceListData(priceListName)];
                    case 1:
                        priceListDataResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setPriceListData(priceListDataResponse);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        getPriceListData();
    }, []);
    function handlePublishSwitchChange(checked, modelId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, priceListsApi.putPublish(modelId, checked ? 'publish' : 'unpublish')];
                    case 1:
                        _a.sent();
                        getPriceListData();
                        setStatus('success');
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleOnImportDataClick() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, priceListsApi.putPriceListEditImport(priceListName)];
                    case 1:
                        _a.sent();
                        setStatus('success');
                        history.push({
                            pathname: '/price-list-import',
                            state: {
                                priceListName: priceListName,
                            },
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleOnFinishClick() {
        SessionStorage.remove('employeeLeasingContext');
        history.push('/price-lists');
    }
    var sortedPriceListData = priceListData &&
        immutableSort(priceListData, function (modelA, modelB) {
            return modelA.name.localeCompare(modelB.name);
        });
    return (React.createElement("div", { className: "modifyPriceListWrapper", "data-testid": "modify-pricelist-wrapper" },
        React.createElement(PriceListsHeader, null),
        React.createElement("div", { className: "modifyPriceListContainer" },
            React.createElement("div", { className: "modifyPriceListName" }, priceListName)),
        React.createElement("div", { className: "modifyPriceListTableWrapper" },
            React.createElement("div", { className: "modifyPriceListTableHeaders" },
                React.createElement("div", { className: "modifyPriceListTableLeftCell" }),
                React.createElement("div", { className: "modifyPriceListTableCenterCell modifyPriceListTableCenterCellHeader" }, "Data obowi\u0105zywania cennika"),
                React.createElement("div", { className: "modifyPriceListTableRightCell modifyPriceListTableRightCellHeader" }, "Publikuj")),
            priceListData ? (React.createElement("div", { "data-testid": "modify-pricelist-table" },
                React.createElement(List.Root, null, sortedPriceListData.map(function (model) { return (React.createElement(List.Item, { key: model.id, height: "fixed", cursor: "default" },
                    React.createElement("div", { className: "modifyPriceListTableRow" },
                        React.createElement("div", { className: "modifyPriceListTableLeftCell" }, model.name),
                        React.createElement("div", { className: "modifyPriceListTableCenterCell" }, model.fromDate ? (React.createElement("div", null,
                            React.createElement("span", null,
                                formatDate(new Date(model.fromDate)),
                                " - "),
                            model.toDate !== undefined && (React.createElement("span", null, formatDate(new Date(model.toDate)))))) : null),
                        React.createElement("div", { className: "modifyPriceListTableRightCell" },
                            React.createElement(Switch, { checked: model.published, onCheckedChange: function (checked) {
                                    return handlePublishSwitchChange(checked, model.id);
                                } }))))); })))) : null,
            React.createElement("div", { className: "modifyPriceListActionsWrapper" },
                React.createElement("div", { className: "modifyPriceListButtonWrapper" },
                    React.createElement(Button, { type: "button", variant: "secondary", onClick: handleOnImportDataClick }, "import danych")),
                React.createElement("div", { className: "modifyPriceListButtonWrapper" },
                    React.createElement(Button, { type: "button", onClick: handleOnFinishClick }, "zako\u0144cz")))),
        status === 'pending' ? React.createElement(Spinner, null) : null));
});
