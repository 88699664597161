var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState } from 'react';
import './carDamageOCIssues.css';
import { HeadRow } from './HeadRow.component';
import { CarDamageOCIssuesHeader } from './CarDamageOCIssueHeader.component';
import { PaginatedIssues } from './PaginatedIssues.component';
import { useCarDamageOCIssues } from './useCarDamageOCIssues';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { Spinner } from 'components/spinner/Spinner';
import { Label } from 'components/label/Label.component';
import { MultiDropdown } from 'components/dropdown/MultiDropdown';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { useStatus } from 'common/statusContext';
import ReactDOM from 'react-dom';
import UserContext from 'common/userContext';
var ITEMS_PER_PAGINATION_PAGE = 10;
var statusesOptions = [
    {
        value: 'OPEN',
        label: 'Do podjęcia',
    },
    {
        value: 'PICKED_UP',
        label: 'Podjęto',
    },
    {
        value: 'CLOSED',
        label: 'Zamknięto',
    },
];
export var CarDamageOCIssues = withCancellation(function () {
    var _a = useState(), assistantsOptions = _a[0], setAssistantsOptions = _a[1];
    var _b = useState(''), searchPhrase = _b[0], setSearchPhrase = _b[1];
    var _c = useState(undefined), issues = _c[0], setIssues = _c[1];
    var _d = useState(), statusFilter = _d[0], setStatusFilter = _d[1];
    var _e = useState(), assistantFilter = _e[0], setAssistantFilter = _e[1];
    var _f = useState(0), currentPage = _f[0], setCurrentPage = _f[1];
    var _g = useState(0), totalPages = _g[0], setTotalPages = _g[1];
    var _h = useState([
        {
            value: 'all',
            label: 'Wszyscy',
        },
    ]), availableDealers = _h[0], setAvailableDealers = _h[1];
    var _j = useState(), selectedDealer = _j[0], setSelectedDealer = _j[1];
    var carDamageOCIssues = useCarDamageOCIssues();
    var _k = useStatus(), status = _k.status, setStatus = _k.setStatus;
    var isFromVcp = UserContext.isFromVCP();
    function setSearchFilterOnRequestOptions(requestOptions) {
        if (searchPhrase) {
            requestOptions.phrase = searchPhrase;
        }
    }
    function setStatusFiltersOnRequestOptions(requestOptions) {
        if (statusFilter && statusFilter.length > 0) {
            var statusFilterOption = statusFilter.map(function (status) {
                return status.value.toString();
            });
            requestOptions.status = statusFilterOption;
        }
    }
    function setAssistantFiltersOnRequestOptions(requestOptions) {
        if (assistantFilter && assistantFilter.length > 0) {
            var assistantFilterOption = assistantFilter.map(function (assistant) {
                return assistant.value.toString();
            });
            requestOptions.assistant = assistantFilterOption;
        }
    }
    function setDealerFiltersOnRequestOptions(requestOptions) {
        if (selectedDealer !== 'all') {
            requestOptions.organizationId = selectedDealer;
        }
    }
    function applyPhraseFilter(phrase) {
        return __awaiter(this, void 0, void 0, function () {
            var requestOptions, issuesResponse_1, requestOptions, issuesResponse_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setSearchPhrase(phrase);
                        if (!(phrase.length >= 3)) return [3 /*break*/, 2];
                        setStatus('pending');
                        requestOptions = {
                            phrase: phrase,
                            page: 0,
                            size: ITEMS_PER_PAGINATION_PAGE
                        };
                        setStatusFiltersOnRequestOptions(requestOptions);
                        setAssistantFiltersOnRequestOptions(requestOptions);
                        setDealerFiltersOnRequestOptions(requestOptions);
                        return [4 /*yield*/, carDamageOCIssues.getIssues(isFromVcp, requestOptions)];
                    case 1:
                        issuesResponse_1 = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setIssues(issuesResponse_1.entries);
                            setStatus('success');
                            setCurrentPage(0);
                            setTotalPages(issuesResponse_1.totalPages);
                        });
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(phrase === '')) return [3 /*break*/, 4];
                        setStatus('pending');
                        requestOptions = {
                            page: 0,
                            size: ITEMS_PER_PAGINATION_PAGE
                        };
                        setStatusFiltersOnRequestOptions(requestOptions);
                        setAssistantFiltersOnRequestOptions(requestOptions);
                        return [4 /*yield*/, carDamageOCIssues.getIssues(isFromVcp, requestOptions)];
                    case 3:
                        issuesResponse_2 = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setIssues(issuesResponse_2.entries);
                            setStatus('success');
                            setCurrentPage(0);
                            setTotalPages(issuesResponse_2.totalPages);
                        });
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        function getInitialData() {
            return __awaiter(this, void 0, void 0, function () {
                var _a, assistantsResponse, issuesResponse, dealersResponse, dealersNamesResponse, assistantsDropdownOptions;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, Promise.all([carDamageOCIssues.getAssistants(isFromVcp), carDamageOCIssues.getIssues(isFromVcp), isFromVcp ? carDamageOCIssues.getAvailableDealers() : Promise.resolve(null), isFromVcp ? carDamageOCIssues.getDealersNames() : Promise.resolve(null)])];
                        case 1:
                            _a = _b.sent(), assistantsResponse = _a[0], issuesResponse = _a[1], dealersResponse = _a[2], dealersNamesResponse = _a[3];
                            assistantsDropdownOptions = assistantsResponse.map(function (assistant) {
                                return {
                                    value: assistant,
                                    label: assistant
                                };
                            });
                            ReactDOM.unstable_batchedUpdates(function () {
                                setIssues(issuesResponse.entries);
                                setTotalPages(issuesResponse.totalPages);
                                setAssistantsOptions(assistantsDropdownOptions);
                                if (dealersResponse) {
                                    var availableDealersOptions_1 = dealersResponse.map(function (dealer) {
                                        var dealerName = dealersNamesResponse.find(function (dealerName) { return dealerName.organizationId === dealer; }).organizationName;
                                        return {
                                            value: dealer,
                                            label: dealerName
                                        };
                                    });
                                    setAvailableDealers(function (currentAvailableDealers) {
                                        return __spreadArrays(currentAvailableDealers, availableDealersOptions_1);
                                    });
                                    setSelectedDealer('all');
                                }
                                ;
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        getInitialData();
    }, []);
    function handleOnStatusChange(value) {
        return __awaiter(this, void 0, void 0, function () {
            var requestOptions, statusFilterOption, issuesResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        requestOptions = {
                            page: 0,
                            size: ITEMS_PER_PAGINATION_PAGE
                        };
                        if (value.length > 0) {
                            statusFilterOption = value.map(function (status) {
                                return status.value.toString();
                            });
                            requestOptions.status = statusFilterOption;
                        }
                        setSearchFilterOnRequestOptions(requestOptions);
                        setAssistantFiltersOnRequestOptions(requestOptions);
                        setDealerFiltersOnRequestOptions(requestOptions);
                        return [4 /*yield*/, carDamageOCIssues.getIssues(isFromVcp, requestOptions)];
                    case 1:
                        issuesResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStatusFilter(value);
                            setIssues(issuesResponse.entries);
                            setCurrentPage(0);
                            setTotalPages(issuesResponse.totalPages);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleOnAssistantChange(value) {
        return __awaiter(this, void 0, void 0, function () {
            var requestOptions, assistantFilterOption, issuesResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        requestOptions = {
                            page: 0,
                            size: ITEMS_PER_PAGINATION_PAGE
                        };
                        if (value.length > 0) {
                            assistantFilterOption = value.map(function (assistant) {
                                return assistant.value.toString();
                            });
                            requestOptions.assistant = assistantFilterOption;
                        }
                        setSearchFilterOnRequestOptions(requestOptions);
                        setStatusFiltersOnRequestOptions(requestOptions);
                        setDealerFiltersOnRequestOptions(requestOptions);
                        return [4 /*yield*/, carDamageOCIssues.getIssues(isFromVcp, requestOptions)];
                    case 1:
                        issuesResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setAssistantFilter(value);
                            setIssues(issuesResponse.entries);
                            setCurrentPage(0);
                            setTotalPages(issuesResponse.totalPages);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleOnDealerChange(value) {
        return __awaiter(this, void 0, void 0, function () {
            var requestOptions, issuesResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        requestOptions = {
                            page: 0,
                            size: ITEMS_PER_PAGINATION_PAGE,
                            organizationId: value === 'all' ? null : value
                        };
                        setSearchFilterOnRequestOptions(requestOptions);
                        setStatusFiltersOnRequestOptions(requestOptions);
                        setAssistantFiltersOnRequestOptions(requestOptions);
                        return [4 /*yield*/, carDamageOCIssues.getIssues(isFromVcp, requestOptions)];
                    case 1:
                        issuesResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setIssues(issuesResponse.entries);
                            setTotalPages(issuesResponse.totalPages);
                            setSelectedDealer(value);
                            setCurrentPage(0);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function changePageHandler(selectedPage) {
        return __awaiter(this, void 0, void 0, function () {
            var requestOptions, issuesResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        requestOptions = {
                            page: selectedPage,
                            size: ITEMS_PER_PAGINATION_PAGE
                        };
                        setSearchFilterOnRequestOptions(requestOptions);
                        setStatusFiltersOnRequestOptions(requestOptions);
                        setAssistantFiltersOnRequestOptions(requestOptions);
                        setDealerFiltersOnRequestOptions(requestOptions);
                        return [4 /*yield*/, carDamageOCIssues.getIssues(isFromVcp, requestOptions)];
                    case 1:
                        issuesResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setIssues(issuesResponse.entries);
                            setTotalPages(issuesResponse.totalPages);
                            setCurrentPage(selectedPage);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function onChangeIssueStateHandler() {
        return __awaiter(this, void 0, void 0, function () {
            var requestOptions, issuesResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        requestOptions = {
                            page: currentPage,
                            size: ITEMS_PER_PAGINATION_PAGE
                        };
                        setSearchFilterOnRequestOptions(requestOptions);
                        setStatusFiltersOnRequestOptions(requestOptions);
                        setAssistantFiltersOnRequestOptions(requestOptions);
                        return [4 /*yield*/, carDamageOCIssues.getIssues(isFromVcp, requestOptions)];
                    case 1:
                        issuesResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setIssues(issuesResponse.entries);
                            setTotalPages(issuesResponse.totalPages);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement("div", null,
        React.createElement(CarDamageOCIssuesHeader, { searchPhrase: searchPhrase, applyPhraseFilter: applyPhraseFilter }),
        React.createElement("div", { className: "carDamageOCIssuesFiltersContainer" },
            React.createElement("div", { className: 'carDamageOCIssueFilterStatus' },
                React.createElement("div", { className: 'carDamageOCIssuesFilterLabel' },
                    React.createElement(Label, null, "Status")),
                React.createElement("div", { className: "carDamageOCIssuesStatusMultiDropdown" },
                    React.createElement(MultiDropdown, { options: statusesOptions, name: 'statusDropdown', onChange: handleOnStatusChange, placeholder: 'Wszystkie', value: statusFilter }))),
            React.createElement("div", { className: 'carDamageOCIssueFilterAssistant' },
                React.createElement("div", { className: 'carDamageOCIssuesFilterLabel' },
                    React.createElement(Label, null, "Opiekun zg\u0142oszenia")),
                React.createElement("div", { className: "carDamageOCIssuesAssistantMultiDropdown" },
                    React.createElement(MultiDropdown, { options: assistantsOptions, name: 'assistantDropdown', onChange: handleOnAssistantChange, placeholder: 'Wszyscy', value: assistantFilter }))),
            isFromVcp &&
                React.createElement("div", { className: 'carDamageOCIssueFilterDealer' },
                    React.createElement("div", { className: 'carDamageOCIssuesFilterLabel' },
                        React.createElement(Label, null, "Dealer")),
                    React.createElement("div", { className: "carDamageOCIssuesDealerDropdown" },
                        React.createElement(Dropdown, { name: 'dealerDropdown', options: availableDealers, onChange: handleOnDealerChange, placeholder: 'Wybierz', value: availableDealers.find(function (dealer) { return dealer.value === selectedDealer; }) })))),
        status === 'pending' && React.createElement(Spinner, null),
        issues ? (React.createElement("div", { className: "carDamageOCIssuesContainer" },
            React.createElement(HeadRow, null),
            issues ? (React.createElement(PaginatedIssues, { issues: issues, onChangeIssueState: onChangeIssueStateHandler, totalPages: totalPages, changePageHandler: changePageHandler, currentPage: currentPage })) : null)) : null));
});
