import { useHttp } from 'http/httpService';
import SessionStorage from 'common/sessionStorage';
import { creatorContextPath } from '../CreatorContextPath';
export function useExteriorApi() {
    var http = useHttp();
    var context = SessionStorage.get('employeeLeasingMode') ? 'employeeLeasing' : 'creator';
    return {
        getExterior: function (carModelId, token) {
            return http
                .get("/api/" + creatorContextPath(context) + "/car-body/" + carModelId + "?token=" + token)
                .then(function (response) { return response.data; });
        },
        getExteriorVcp: function (carModelId, token) {
            return http
                .get("/api/" + creatorContextPath(context) + "/car-body/vcp/" + carModelId + "?token=" + token)
                .then(function (response) { return response.data; });
        },
        getColor: function (token, versionToken, carModelId) {
            return http
                .get("api/" + creatorContextPath(context) + "/car/images/" + carModelId + "?token=" + token + "&versionToken=" + versionToken)
                .then(function (response) { return response.data; });
        },
        getColorVcp: function (token, versionToken, carModelId) {
            return http
                .get("api/" + creatorContextPath(context) + "/car/images/" + carModelId + "/vcp?token=" + token + "&versionToken=" + versionToken)
                .then(function (response) { return response.data; });
        }
    };
}
