var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSpecialOfferApi } from '../useSpecialOfferApi';
import uuid from 'react-uuid';
import { useStatus } from 'common/statusContext';
export function useRequestSubject(partyIdenticiationNumber, excludedPno12) {
    if (excludedPno12 === void 0) { excludedPno12 = []; }
    var _a = useState([]), allOffers = _a[0], setAllOffers = _a[1];
    var _b = useState([]), fundingForms = _b[0], setFundingForms = _b[1];
    var _c = useState([]), leasingProviders = _c[0], setLeasingProviders = _c[1];
    var _d = useState([]), cfmProviders = _d[0], setCfmProviders = _d[1];
    var _e = useState([]), availableVCPLevelPercent = _e[0], setAvailableVCPLevelPercent = _e[1];
    var _f = useState([]), availableDealerShareMarginPercent = _f[0], setAvailableDealerShareMarginPercent = _f[1];
    var _g = useState([]), requestSubjects = _g[0], setRequestSubjects = _g[1];
    var setStatus = useStatus().setStatus;
    function addNewRequestSubject(id) {
        setRequestSubjects(function (currentRequestSubjects) {
            return __spreadArrays(currentRequestSubjects, [
                {
                    id: id,
                    selectedOffer: '',
                    offerCarAmount: '',
                    selectedDealerMargin: null,
                    selectedFleetCoofinancing: null,
                    selectedFinancing: 'CASH',
                    selectedLeasingProvider: null,
                    otherLeasingProvider: '',
                    selectedCfmProvider: null,
                    otherCfmProvider: '',
                    percentagesValues: null
                }
            ]);
        });
    }
    var specialOfferApi = useSpecialOfferApi();
    function clearSelectedFinancingProvider(index) {
        setRequestSubjects(function (currentRequestSubjects) {
            return __spreadArrays(currentRequestSubjects.slice(0, index), [
                __assign(__assign({}, currentRequestSubjects[index]), { 'selectedLeasingProvider': null, 'otherLeasingProvider': '', 'selectedCfmProvider': null, 'otherCfmProvider': '' })
            ], currentRequestSubjects.slice(index + 1));
        });
    }
    function setNewRequestSubjectStateHelper(value, index, property) {
        setRequestSubjects(function (currentRequestSubjects) {
            var _a;
            return __spreadArrays(currentRequestSubjects.slice(0, index), [
                __assign(__assign({}, currentRequestSubjects[index]), (_a = {}, _a[property] = value, _a))
            ], currentRequestSubjects.slice(index + 1));
        });
    }
    function onOfferChange(value, index) {
        return __awaiter(this, void 0, void 0, function () {
            var selectedOfferObject, percentageValuesResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setNewRequestSubjectStateHelper(value, index, 'selectedOffer');
                        selectedOfferObject = allOffers ? allOffers.find(function (offer) { return offer.id === value; }) : undefined;
                        if (!(requestSubjects[index]['selectedDealerMargin'] && requestSubjects[index]['selectedFleetCoofinancing'])) return [3 /*break*/, 2];
                        return [4 /*yield*/, specialOfferApi.getPercentagesValues(requestSubjects[index]['selectedDealerMargin'].value, requestSubjects[index]['selectedFleetCoofinancing'].value, selectedOfferObject.listPriceNet, selectedOfferObject.listPriceGross)];
                    case 1:
                        percentageValuesResponse = _a.sent();
                        setNewRequestSubjectStateHelper(percentageValuesResponse, index, 'percentagesValues');
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    function onOfferCarAmountChange(event, index) {
        var result = event.target.value.replace(/\D/g, '');
        setNewRequestSubjectStateHelper(result, index, 'offerCarAmount');
    }
    function onDealerMarginChange(_, option, index) {
        return __awaiter(this, void 0, void 0, function () {
            var selectedOfferObject, percentageValuesResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setNewRequestSubjectStateHelper(option, index, 'selectedDealerMargin');
                        if (!(requestSubjects[index]['selectedOffer'] && requestSubjects[index]['selectedFleetCoofinancing'])) return [3 /*break*/, 2];
                        selectedOfferObject = (allOffers && requestSubjects[index]['selectedOffer']) ? allOffers.find(function (offer) { return offer.id === requestSubjects[index]['selectedOffer']; }) : undefined;
                        return [4 /*yield*/, specialOfferApi.getPercentagesValues(option.value, requestSubjects[index]['selectedFleetCoofinancing'].value, selectedOfferObject.listPriceNet, selectedOfferObject.listPriceGross)];
                    case 1:
                        percentageValuesResponse = _a.sent();
                        setNewRequestSubjectStateHelper(percentageValuesResponse, index, 'percentagesValues');
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    function onFleetCoofinancingChange(_, option, index) {
        return __awaiter(this, void 0, void 0, function () {
            var selectedOfferObject, percentageValuesResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setNewRequestSubjectStateHelper(option, index, 'selectedFleetCoofinancing');
                        if (!(requestSubjects[index]['selectedOffer'] && requestSubjects[index]['selectedDealerMargin'])) return [3 /*break*/, 2];
                        selectedOfferObject = (allOffers && requestSubjects[index]['selectedOffer']) ? allOffers.find(function (offer) { return offer.id === requestSubjects[index]['selectedOffer']; }) : undefined;
                        return [4 /*yield*/, specialOfferApi.getPercentagesValues(requestSubjects[index]['selectedDealerMargin'].value, option.value, selectedOfferObject.listPriceNet, selectedOfferObject.listPriceGross)];
                    case 1:
                        percentageValuesResponse = _a.sent();
                        setNewRequestSubjectStateHelper(percentageValuesResponse, index, 'percentagesValues');
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    function onFinancingChange(value, index) {
        setNewRequestSubjectStateHelper(value, index, 'selectedFinancing');
        clearSelectedFinancingProvider(index);
    }
    function onLeasingProviderChange(_, option, index) {
        setNewRequestSubjectStateHelper(option, index, 'selectedLeasingProvider');
    }
    function onOtherLeasingProviderChange(event, index) {
        var value = event.currentTarget.value;
        setNewRequestSubjectStateHelper(value, index, 'otherLeasingProvider');
    }
    function onCfmProviderChange(_, option, index) {
        setNewRequestSubjectStateHelper(option, index, 'selectedCfmProvider');
    }
    function onOtherCfmProviderChange(event, index) {
        var value = event.currentTarget.value;
        setNewRequestSubjectStateHelper(value, index, 'otherCfmProvider');
    }
    function handleRequestSubjectDelete(id) {
        setRequestSubjects(function (currentRequestSubjects) {
            return __spreadArrays(currentRequestSubjects.filter(function (requestSubject) { return requestSubject.id !== id; }));
        });
    }
    useEffect(function () {
        function getFunding() {
            return __awaiter(this, void 0, void 0, function () {
                var fundingResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.fundingForms()];
                        case 1:
                            fundingResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setFundingForms(fundingResponse.fundingFormCodesList);
                                setLeasingProviders(fundingResponse.leasingProviders);
                                setCfmProviders(fundingResponse.cfmProviders);
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getPercentage() {
            return __awaiter(this, void 0, void 0, function () {
                var percentageResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.getAvailablePercentages()];
                        case 1:
                            percentageResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setAvailableVCPLevelPercent(percentageResponse.VCPLevelPercent);
                                setAvailableDealerShareMarginPercent(percentageResponse.dealerShareMarginPercent);
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        getFunding();
        getPercentage();
    }, []);
    // pobranie ofert dla strony po nip/pesel strony
    useEffect(function () {
        function getAvailableOffers() {
            return __awaiter(this, void 0, void 0, function () {
                var partyOffersResponse, id;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, specialOfferApi.getAvailableOffersByPartyIdentificationNumber(partyIdenticiationNumber)];
                        case 1:
                            partyOffersResponse = _a.sent();
                            setStatus('success');
                            id = uuid();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setAllOffers(partyOffersResponse);
                                setRequestSubjects([
                                    {
                                        id: id,
                                        selectedOffer: '',
                                        offerCarAmount: '',
                                        selectedDealerMargin: null,
                                        selectedFleetCoofinancing: null,
                                        selectedFinancing: 'CASH',
                                        selectedLeasingProvider: null,
                                        otherLeasingProvider: '',
                                        selectedCfmProvider: null,
                                        otherCfmProvider: '',
                                        percentagesValues: null
                                    }
                                ]);
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (partyIdenticiationNumber) {
            getAvailableOffers();
        }
        ;
    }, [partyIdenticiationNumber]);
    var selectedOffers = requestSubjects.filter(function (requestSubject) { return requestSubject.selectedOffer; }).map(function (requestSubject) { return requestSubject.selectedOffer; });
    var selectedPno12 = selectedOffers.map(function (selectedOffer) { return allOffers.find(function (offer) { return offer.id === selectedOffer; }).pno12; });
    var availableOffers = allOffers.filter(function (offer) {
        return !selectedPno12.includes(offer.pno12) && !excludedPno12.includes(offer.pno12);
    });
    return {
        onOfferChange: onOfferChange,
        onOfferCarAmountChange: onOfferCarAmountChange,
        onDealerMarginChange: onDealerMarginChange,
        onFleetCoofinancingChange: onFleetCoofinancingChange,
        onFinancingChange: onFinancingChange,
        onLeasingProviderChange: onLeasingProviderChange,
        onOtherLeasingProviderChange: onOtherLeasingProviderChange,
        onCfmProviderChange: onCfmProviderChange,
        onOtherCfmProviderChange: onOtherCfmProviderChange,
        fundingForms: fundingForms,
        leasingProviders: leasingProviders,
        cfmProviders: cfmProviders,
        availableVCPLevelPercent: availableVCPLevelPercent,
        availableDealerShareMarginPercent: availableDealerShareMarginPercent,
        availableOffers: availableOffers,
        requestSubjects: requestSubjects,
        addNewRequestSubject: addNewRequestSubject,
        allOffers: allOffers,
        handleRequestSubjectDelete: handleRequestSubjectDelete
    };
}
