var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { Button } from 'components/button/Button.component';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { Label } from 'components/label/Label.component';
import React, { useCallback, useEffect, useState } from 'react';
import './priceLists.css';
import { PaginatedPriceLists } from '../paginatedPriceLists/PaginatedPriceLists.component';
import { useHistory } from 'react-router-dom';
import { usePriceListApi } from '../usePriceListApi';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import SessionStorage from '../../../common/sessionStorage';
import { Spinner } from 'components/spinner/Spinner';
import { useStatus } from 'common/statusContext';
import ReactDOM from 'react-dom';
import { immutableSort } from 'utils/immutableSort';
import { useInterval } from '../priceListImportContainer/dataImport/useInterval';
export var ITEMS_PER_PAGINATION_PAGE = 10;
var importInProgressStatuses = ['WAITING', 'DOWNLOAD', 'DOWNLOADED', 'IMPORTING'];
var importNotSuccessfullStatuses = ['ERROR', 'TO_CANCEL'];
var importAllStatuses = __spreadArrays(importInProgressStatuses, importNotSuccessfullStatuses);
export var PriceLists = withCancellation(function (_a) {
    var employeeLeasing = _a.employeeLeasing;
    var history = useHistory();
    var priceListsApi = employeeLeasing ? usePriceListApi({ context: 'employeeLeasing' }) : usePriceListApi();
    var _b = useStatus(), status = _b.status, setStatus = _b.setStatus;
    var _c = useState(undefined), priceLists = _c[0], setPriceList = _c[1];
    var _d = useState(undefined), selectedFilter = _d[0], setSelectedFilter = _d[1];
    var _e = useState(0), totalPages = _e[0], setTotalPages = _e[1];
    var _f = useState([{
            value: 'all',
            label: 'Wszystkie cenniki',
        }]), filterDropdownOptions = _f[0], setFilterDropdownOptions = _f[1];
    var _g = useState(0), currentPage = _g[0], setCurrentPage = _g[1];
    var _h = useState(15000), pollingDelay = _h[0], setPollingDelay = _h[1];
    var _j = useState(null), importStatus = _j[0], setImportStatus = _j[1];
    var _k = useState(''), currentlyImportedStatus = _k[0], setCurrentlyImportedStatus = _k[1];
    function handleChangeImportStatus(value) {
        setImportStatus(value);
    }
    function setDataOnFilterChange(priceListsResponse, value) {
        ReactDOM.unstable_batchedUpdates(function () {
            setPriceList(priceListsResponse.content);
            setTotalPages(priceListsResponse.totalPages);
            setSelectedFilter(value);
            setCurrentPage(0);
            SessionStorage.set('priceListsFilter', value);
            setStatus('success');
        });
    }
    function handleChangeSelectedFilter(value) {
        return __awaiter(this, void 0, void 0, function () {
            var priceListsResponse, priceListsResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        if (!(value !== 'all')) return [3 /*break*/, 2];
                        return [4 /*yield*/, priceListsApi.getPriceLists(ITEMS_PER_PAGINATION_PAGE, 0, "20" + value)];
                    case 1:
                        priceListsResponse = _a.sent();
                        setDataOnFilterChange(priceListsResponse, value);
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(value === 'all')) return [3 /*break*/, 4];
                        return [4 /*yield*/, priceListsApi.getPriceLists(ITEMS_PER_PAGINATION_PAGE, 0)];
                    case 3:
                        priceListsResponse = _a.sent();
                        setDataOnFilterChange(priceListsResponse, value);
                        _a.label = 4;
                    case 4:
                        setStatus('error');
                        return [2 /*return*/];
                }
            });
        });
    }
    function onCreateOrEditClick() {
        if (employeeLeasing) {
            SessionStorage.set('employeeLeasingContext', true);
        }
        history.push('/price-list-import');
    }
    function changePageHandler(selectedPage) {
        return __awaiter(this, void 0, void 0, function () {
            var priceListsResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, priceListsApi.getPriceLists(ITEMS_PER_PAGINATION_PAGE, selectedPage)];
                    case 1:
                        priceListsResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setPriceList(priceListsResponse.content);
                            setCurrentPage(selectedPage);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function getImportStatus() {
        return __awaiter(this, void 0, void 0, function () {
            var statusResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, priceListsApi.getImportStatus()];
                    case 1:
                        statusResponse = _a.sent();
                        if (!importAllStatuses.includes(statusResponse[0].status)) return [3 /*break*/, 4];
                        handleChangeImportStatus(statusResponse[0].status);
                        setCurrentlyImportedStatus(statusResponse[0].name);
                        if (!(statusResponse[0].status === 'ERROR')) return [3 /*break*/, 3];
                        return [4 /*yield*/, priceListsApi.cancelImport(statusResponse[0].name)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                    case 4:
                        handleChangeImportStatus(null);
                        setPollingDelay(null);
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        function getPriceListYears() {
            return __awaiter(this, void 0, void 0, function () {
                var priceListYearsResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, priceListsApi.getPriceListYears()];
                        case 1:
                            priceListYearsResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                var yearsForFilterDropdownArray = immutableSort(priceListYearsResponse, (function (yearA, yearB) { return yearB.year - yearA.year; })).map(function (year) {
                                    return {
                                        value: year.year,
                                        label: "20" + year.year
                                    };
                                });
                                setFilterDropdownOptions(function (current) {
                                    return __spreadArrays(current, yearsForFilterDropdownArray);
                                });
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getPriceLists() {
            return __awaiter(this, void 0, void 0, function () {
                var priceListsResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, priceListsApi.getPriceLists(ITEMS_PER_PAGINATION_PAGE, 0)["catch"](function (error) {
                                    console.error(error);
                                    setStatus('error');
                                })];
                        case 1:
                            priceListsResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setPriceList(priceListsResponse.content);
                                setTotalPages(priceListsResponse.totalPages);
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        getPriceListYears();
        getImportStatus();
        if (SessionStorage.has('priceListsFilter')) {
            var lastSelectedFilter = SessionStorage.get('priceListsFilter');
            setSelectedFilter(lastSelectedFilter);
            handleChangeSelectedFilter(lastSelectedFilter);
        }
        else {
            SessionStorage.set('priceListsFilter', 'all');
            setSelectedFilter('all');
            getPriceLists();
        }
    }, []);
    var handleInterval = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            getImportStatus();
            return [2 /*return*/];
        });
    }); }, []);
    useInterval(handleInterval, pollingDelay);
    function message(priceListName) {
        if (importInProgressStatuses.includes(importStatus)) {
            return "Operacje zablokowane. Trwa import cennika " + priceListName + ".";
        }
        if (importNotSuccessfullStatuses.includes(importStatus)) {
            return "Operacje zablokowane. Trwa anulacja importu cennika " + priceListName + ".";
        }
    }
    function isCreateOrModifyButtonDisabled() {
        return importNotSuccessfullStatuses.includes(importStatus);
    }
    return priceLists ? (React.createElement("div", { className: "priceListsWrapper" },
        React.createElement("div", { className: "priceLists" },
            React.createElement("div", { className: "priceListsActions" },
                React.createElement("div", { className: "priceListsModifyButton" },
                    React.createElement(Button, { type: "button", onClick: onCreateOrEditClick, disabled: isCreateOrModifyButtonDisabled() }, "utw\u00F3rz/zmodyfikuj cennik")),
                React.createElement("div", { className: "priceListsFilterWrapper" },
                    React.createElement(Label, null, "MY:"),
                    React.createElement("div", { className: "priceListsFilterDropdown" },
                        React.createElement(Dropdown, { options: filterDropdownOptions, onChange: handleChangeSelectedFilter, name: "priceListsFilter", value: filterDropdownOptions.find(function (filter) { return filter.value === selectedFilter; }) })))),
            React.createElement("div", { className: 'priceListMessage' }, message(currentlyImportedStatus)),
            React.createElement("div", null,
                React.createElement(PaginatedPriceLists, { items: priceLists, totalPages: totalPages, changePageHandler: changePageHandler, currentPage: currentPage, isImportInProgress: !!importStatus, employeeLeasing: employeeLeasing }))))) : status === 'pending' ? (React.createElement(Spinner, null)) : null;
});
