import { Tabs } from 'components/tabs/Tabs.component';
import React from 'react';
import { RequestSubject } from './RequestSubject';
import ModelDictionary from 'common/modelDictionary';
import './requestSubject.css';
export function NewRequestSubjectsSection(_a) {
    var idSelectedParty = _a.idSelectedParty, requestSubjectSectiona = _a.requestSubjectSectiona, tabValue = _a.tabValue, onTabChange = _a.onTabChange;
    var requestSubjectSection = requestSubjectSectiona;
    var NewRequestSubjectsTabs = requestSubjectSection.requestSubjects.length > 0 &&
        requestSubjectSection.requestSubjects.map(function (requestSubject, index) {
            var offer = requestSubjectSection.allOffers.find(function (offer) { return offer.id === requestSubject.selectedOffer; });
            var modelCode = offer && offer.modelId.split('_')[0];
            return {
                value: requestSubject.id,
                name: requestSubject.selectedOffer ? ModelDictionary.nameFor(modelCode) + " " + offer.engine : 'Nowa',
                content: React.createElement(RequestSubject, { requestSubjectSection: requestSubjectSection, index: index, disabled: !idSelectedParty, id: requestSubject.id }),
            };
        });
    return (React.createElement("div", null, NewRequestSubjectsTabs ? (React.createElement("div", null,
        React.createElement(Tabs.Root, { value: tabValue, onValueChange: onTabChange },
            React.createElement(Tabs.List, { style: { paddingTop: 0, gap: 0, flexWrap: 'wrap' } }, NewRequestSubjectsTabs.map(function (tab, index) { return (React.createElement(Tabs.Trigger, { value: tab.value, key: index, style: { maxWidth: '190px', minWidth: '170px', height: '72px' }, borderBottom: true },
                React.createElement("span", { className: 'tabTriggerName' }, tab.name))); })),
            NewRequestSubjectsTabs.map(function (tab, index) { return (React.createElement(Tabs.Content, { value: tab.value, key: index },
                React.createElement("div", { id: "tabContentWrapper", className: "tabContentWrapper" }, tab.content))); })))) : null));
}
