var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect } from 'react';
import { List } from 'components/list/List.component';
import { Label } from 'components/label/Label.component';
import './summaryTab.css';
import { TextAmount } from 'components/textAmount/TextAmount';
import { NetTextAmountSummary } from 'components/textAmount/NetTextAmountSummary';
import { BasicInformationSummary } from 'components/summary/BasicInformationSummary';
import { Accordion } from 'components/accordion/Accordion.component';
import { ListItemElement } from 'components/listItemElement/ListItemElement.component';
import { TextAmountPlaceholder } from 'components/textAmount/TextAmountPlaceholder';
import { uppercasedFirstLetter } from 'utils/uppercasedFirstLetter';
import { TermValueTable } from 'components/termValueTable/TermValueTable.component';
import { immutableSort } from 'utils/immutableSort';
import { Checkbox } from 'components/checkbox/Checkbox.component';
import { ChipPlaceholder } from 'components/chip/ChipPlaceholder.component';
import userContext from 'common/userContext';
import { LackOfPriceChip } from 'components/chip/LackOfPriceChip.component';
import { PackageContent } from 'pages/demo/components/packageContent/PackageContent.component';
import { PackagesSummary } from 'components/summary/PackagesSummary';
import { AccordionHeaderPlaceholder } from 'components/accordion/AccordionHeaderPlaceholder';
import { TextArea } from 'components/textArea/TextArea.component';
import { Button } from 'components/button/Button.component';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useEmployeeLeasingInstallment } from './useEmployeeLeasingInstallment';
function isFromVCP() {
    return userContext.isFromVCP();
}
function isEmployeeLeasingUser() {
    return userContext.hasAnyRole('VCP_EMPLOYEE_LEASING', 'EMPLOYEE_LEASING');
}
var ADDITIONAL_OPTIONS_LIMIT_EXCEEDED_MESSAGE = 'Przekroczony został limit łącznej wartości opcji dodatkowych. Konfiguracja będzie podlegała dodatkowej akceptacji.';
var MAX_ADDITIONAL_OPTIONS_LIMIT_EXCEEDED_MESSAGE = 'Przekroczony został limit łącznej wartości opcji dodatkowych. Zaktualizuj konfigurację, aby przesłać wniosek dalej.';
export var SummaryTab = withCancellation(function (_a) {
    var handleChangeTokenConfigurationVisible = _a.handleChangeTokenConfigurationVisible, offer = _a.offer, selectedDrive = _a.selectedDrive, drives = _a.drives, versions = _a.versions, exteriors = _a.exteriors, selectedWheels = _a.selectedWheels, interiorElements = _a.interiorElements, options = _a.options, selectedPackages = _a.selectedPackages, accessories = _a.accessories, selectedAccessoryPackages = _a.selectedAccessoryPackages, additionalInformation = _a.additionalInformation, onChangeAdditionalInformation = _a.onChangeAdditionalInformation, onGetSheetClick = _a.onGetSheetClick, onBlurAdditionalInformation = _a.onBlurAdditionalInformation, onRegisterClick = _a.onRegisterClick, clientId = _a.clientId, selectedParty = _a.selectedParty, additionalOptionsLimit = _a.additionalOptionsLimit, maxAdditionalOptionsLimit = _a.maxAdditionalOptionsLimit, onSendClick = _a.onSendClick, onBackClick = _a.onBackClick;
    useEffect(function () {
        handleChangeTokenConfigurationVisible(true);
    }, []);
    useEffect(function () {
        return function () {
            handleChangeTokenConfigurationVisible(false);
        };
    }, []);
    var basicInfo = [
        {
            category: 'samochód',
            elements: [
                {
                    label: 'Cena katalogowa samochodu:',
                    netAmount: offer.priceVariant.catalogPriceNetWithoutAccessories,
                    grossAmount: offer.priceVariant.catalogPriceGrossWithoutAccessories,
                    visible: true
                },
                {
                    label: 'Kwota rabatu:',
                    netAmount: offer.priceVariant.discountSummaryNetAmount,
                    grossAmount: offer.priceVariant.discountSummaryGrossAmount,
                    visible: offer.priceVariant.discountSummaryGrossAmount === 0 ? false : true
                },
                {
                    label: 'Cena sprzedaży po rabatowaniu:',
                    netAmount: offer.priceVariant.minimumPriceNetAfterDiscount,
                    grossAmount: offer.priceVariant.minimumPriceGrossAfterDiscount,
                    visible: offer.priceVariant.discountSummaryGrossAmount === 0 ? false : true
                },
            ],
            sectionVisible: true
        },
        {
            category: 'akcesoria',
            elements: [
                {
                    label: 'Cena katalogowa akcesoriów:',
                    netAmount: offer.priceVariant.optionsPriceNetOnlyAccessoriesCatalogPrice,
                    grossAmount: offer.priceVariant.optionsPriceGrossOnlyAccessoriesCatalogPrice,
                    visible: true
                },
                {
                    label: 'Kwota rabatu na akcesoria:',
                    netAmount: offer.priceVariant.accessoriesNetDiscount,
                    grossAmount: offer.priceVariant.accessoriesGrossDiscount,
                    visible: offer.priceVariant.accessoriesGrossDiscount === 0 ? false : true
                },
                {
                    label: 'Cena sprzedaży akcesoriów po rabatowaniu:',
                    netAmount: offer.priceVariant.optionsPriceNetOnlyAccessories,
                    grossAmount: offer.priceVariant.optionsPriceGrossOnlyAccessories,
                    visible: offer.priceVariant.accessoriesGrossDiscount === 0 ? false : true
                },
            ],
            sectionVisible: offer.priceVariant.optionsPriceGrossOnlyAccessoriesCatalogPrice !== 0
        },
        {
            category: 'inne',
            elements: [
                {
                    label: 'Cena dodatkowych akcesoriów:',
                    netAmount: offer.priceVariant.customAccessoriesPriceNet,
                    grossAmount: offer.priceVariant.customAccessoriesPriceGross,
                    visible: offer.priceVariant.customAccessoriesPriceGross === 0 ? false : true
                },
                {
                    label: 'Cena dodatkowych usług:',
                    netAmount: offer.priceVariant.customServicesPriceNet,
                    grossAmount: offer.priceVariant.customServicesPriceGross,
                    visible: offer.priceVariant.customServicesPriceGross === 0 ? false : true
                },
            ],
            sectionVisible: offer.priceVariant.customAccessoriesPriceGross === 0 && offer.priceVariant.customServicesPriceGross === 0 ? false : true
        },
    ];
    function ensureUnit(value, unit) {
        if (!value) {
            return '';
        }
        if (value.endsWith(unit)) {
            return value;
        }
        return value + " " + unit;
    }
    var selectedDriveData = drives.find(function (drive) { return drive.powerTrainId === offer.vehicle.powerTrainId; });
    var seletedVersionData = Object.values(versions)
        .flat()
        .find(function (version) { return version.versionId === "" + offer.vehicle.typeId + offer.vehicle.seatNo && version.powerTrainThemeId === offer.vehicle.theme; });
    var selectedColorData = exteriors
        .find(function (exterior) { return exterior.category.toUpperCase() === 'KOLOR'; })
        .availableOptions.find(function (color) { return offer.vehicle.selectedOptions.includes(color.id); });
    var selectedWheelsId = selectedWheels.split('_')[0];
    var selectedWheelsData = exteriors
        .find(function (exterior) { return exterior.category.toUpperCase() === 'KOŁA'; })
        .availableOptions.find(function (wheels) { return wheels.id === selectedWheelsId; });
    var selectedInteriorData = interiorElements.find(function (interior) {
        return offer.vehicle.selectedOptions.includes(interior.versionId);
    });
    function isElectricMotor() {
        return offer.vehicle.fuelName.trim().toLowerCase() === 'elektryczny';
    }
    var basicTechnicalDataLeftColumn = [
        {
            term: 'Silnik:',
            value: offer.vehicle.powerTrainName,
        },
        {
            term: 'Rodzaj paliwa:',
            value: offer.vehicle.fuelName,
        },
        {
            term: 'Moc:',
            value: offer.vehicle.technicalData.kilowattsTotal,
        },
        {
            term: 'Moc maksymalna:',
            value: offer.vehicle.technicalData.horsepowerTotal,
        },
        {
            term: 'Czas przyspieszenia:',
            value: offer.vehicle.technicalData.acceleration,
        },
        {
            term: isElectricMotor() ? 'Moment obrotowy:' : 'Średnie spalanie:',
            value: isElectricMotor() ? offer.vehicle.technicalData.torqueTotal : offer.vehicle.technicalData.fuelConsumptionRangeBestWorstWltpTotal,
        },
    ];
    var basicTechnicalDataRigthColumn = [
        {
            term: 'Prędkość maksymalna:',
            value: ensureUnit(offer.vehicle.technicalData.maxSpeed, 'km/h'),
        },
        {
            term: 'Liczba miejsc:',
            value: offer.vehicle.seatNo,
        },
        {
            term: 'Wysokość:',
            value: ensureUnit(offer.vehicle.technicalData.height, 'mm'),
        },
        {
            term: 'Długość:',
            value: ensureUnit(offer.vehicle.technicalData.length, 'mm'),
        },
        {
            term: 'Szerokość z lusterkami:',
            value: ensureUnit(offer.vehicle.technicalData.widthMirrors, 'mm'),
        },
        {
            term: 'Pojemność bagażnika:',
            value: ensureUnit(offer.vehicle.technicalData.cargoCapacity, 'l'),
        },
    ];
    var mildHybridWltp = {
        leftColumn: [
            {
                term: 'Emisja CO2 WLTP:',
                value: offer.vehicle.technicalData.co2EmissionsRangeBestWorstWltpTotal,
            },
            {
                term: 'Zużycie paliwa (cykl mieszany) WLTP:',
                value: offer.vehicle.technicalData.fuelConsumptionWltpTotal,
            },
            {
                term: 'Zużycie paliwa (low) WLTP:',
                value: offer.vehicle.technicalData.fuelConsumptionWltpLow,
            },
        ],
        rightColumn: [
            {
                term: 'Zużycie paliwa (medium) WLTP:',
                value: offer.vehicle.technicalData.fuelConsumptionWltpMedium,
            },
            {
                term: 'Zużycie paliwa (high) WLTP:',
                value: offer.vehicle.technicalData.fuelConsumptionWltpHigh,
            },
            {
                term: 'Zużycie paliwa (extra high) WLTP:',
                value: offer.vehicle.technicalData.fuelConsumptionWltpExtraHigh,
            },
        ],
    };
    var plugInHybridWltp = {
        leftColumn: [
            {
                term: 'Emisja CO2 WLTP:',
                value: offer.vehicle.technicalData.co2EmissionsRangeBestWorstWltpTotal,
            },
            {
                term: 'Zużycie paliwa (cykl mieszany) WLTP:',
                value: offer.vehicle.technicalData.fuelConsumptionWltpTotal,
            },
            {
                term: 'Zużycie paliwa (low) WLTP:',
                value: offer.vehicle.technicalData.fuelConsumptionWltpLow,
            },
            {
                term: 'Zużycie paliwa (medium) WLTP:',
                value: offer.vehicle.technicalData.fuelConsumptionWltpMedium,
            },
            {
                term: 'Zużycie paliwa (high) WLTP:',
                value: offer.vehicle.technicalData.fuelConsumptionWltpHigh,
            },
        ],
        rightColumn: [
            {
                term: 'Zużycie paliwa (extra high) WLTP:',
                value: offer.vehicle.technicalData.fuelConsumptionWltpExtraHigh,
            },
            {
                term: 'Zużycie energii elektrycznej (cykl mieszany) WLTP:',
                value: offer.vehicle.technicalData.electricEnergyConsumptionWltpTotal,
            },
            {
                term: 'Zużycie energii elektrycznej (city) WLTP:',
                value: offer.vehicle.technicalData.electricEnergyConsumptionWltpCity,
            },
            {
                term: 'Zasięg na napędzie elektrycznym (cykl mieszany) WLTP:',
                value: offer.vehicle.technicalData.electricRangeWltpTotal,
            },
            {
                term: 'Zasięg na napędzie elektrycznym (city) WLTP:',
                value: offer.vehicle.technicalData.electricRangeWltpCity,
            },
        ],
    };
    var electricWltp = {
        leftColumn: [
            {
                term: 'Zużycie energii elektrycznej (cykl mieszany) WLTP:',
                value: offer.vehicle.technicalData.electricEnergyConsumptionWltpTotal,
            },
            {
                term: 'Zużycie energii elektrycznej (city) WLTP:',
                value: offer.vehicle.technicalData.electricEnergyConsumptionWltpCity,
            },
        ],
        rightColumn: [
            {
                term: 'Zasięg na napędzie elektrycznym (cykl mieszany) WLTP:',
                value: offer.vehicle.technicalData.electricRangeWltpTotal,
            },
            {
                term: 'Zasięg na napędzie elektrycznym (city) WLTP:',
                value: offer.vehicle.technicalData.electricRangeWltpCity,
            },
        ],
    };
    var standardData = [
        {
            category: 'drive',
            name: 'napęd',
            value: selectedDriveData.displayName,
            code: '',
            price: selectedDriveData.powerTrainPrice,
        },
        {
            category: 'version',
            name: 'wersja',
            value: seletedVersionData ? seletedVersionData.versionName + ", Motyw: " + uppercasedFirstLetter(seletedVersionData.powerTrainThemeName) : '',
            code: '',
            price: seletedVersionData ? seletedVersionData.powerTrainThemePrice : '',
        },
        {
            category: 'color',
            name: 'kolor',
            value: selectedColorData.name,
            code: selectedColorData.optionNo,
            price: selectedColorData.price,
        },
        {
            category: 'wheels',
            name: 'koła',
            value: selectedWheelsData.name,
            code: selectedWheelsData.optionNo,
            price: selectedWheelsData.price,
        },
        {
            category: 'upholstery',
            name: 'tapicerka',
            value: selectedInteriorData.versionName,
            code: selectedInteriorData.versionId,
            price: selectedInteriorData.versionPrice,
        },
    ];
    var interiorFinish = {
        category: 'interior',
        name: 'wykończenie wnętrza',
        content: selectedInteriorData.requiredOptions,
    };
    // pakiety z opcji
    var packageOptions = options.find(function (option) { return option.category.toUpperCase() === 'PAKIETY'; });
    var sortedPackageAvailableOptions = packageOptions
        ? immutableSort(packageOptions.availableOptions, function (availableOptionA, availableOptionB) {
            return availableOptionA.name.localeCompare(availableOptionB.name);
        })
        : [];
    var sortedPackageAvailableOptionsWithSortedContents = sortedPackageAvailableOptions.map(function (availableOption) {
        return __assign(__assign({}, availableOption), { content: immutableSort(availableOption.content, function (contentOptionA, contentOptionB) {
                return contentOptionA.name.localeCompare(contentOptionB.name);
            }) });
    });
    var packages = packageOptions && __assign(__assign({}, packageOptions), { availableOptions: sortedPackageAvailableOptionsWithSortedContents });
    // pakiety z akcesorii
    var packageAccessories = accessories ? accessories.find(function (accessory) { return accessory.category.toUpperCase() === 'PAKIETY'; }) : null;
    var sortedPackageAccessories = packageAccessories
        ? immutableSort(packageAccessories.accessories, function (packageAccessoryA, packageAccessoryB) {
            return packageAccessoryA.name.localeCompare(packageAccessoryB.name);
        })
        : [];
    function isPackageWithExchangableOptions(availableOption) {
        var isMultiple = availableOption.content.some(function (option) { return option.token; });
        return isMultiple;
    }
    function isSubOptionSelected(packageId, elementId) {
        var packageVariant = offer.vehicle.packageVariants.find(function (option) { return option.optionId === packageId; });
        return packageVariant.subOptionId === elementId;
    }
    // inne poza pakietami i pozostałymi z opcji nie w standardzie
    var restOptions = options.filter(function (option) { return option.category.toUpperCase() !== 'PAKIETY' && option.category.toUpperCase() !== 'POZOSTAŁE'; });
    var sortedRestOptions = restOptions
        ? immutableSort(restOptions, function (optionA, optionB) { return optionA.category.localeCompare(optionB.category); })
        : [];
    var sortedRestOptionsWithSortedAvailableOptions = sortedRestOptions.map(function (sortedRestOption) {
        return __assign(__assign({}, sortedRestOption), { availableOptions: immutableSort(sortedRestOption.availableOptions, function (availableOptionA, availableOptionB) { return availableOptionA.name.localeCompare(availableOptionB.name); }) });
    });
    var sortedRestOptionsWithoutStandard = sortedRestOptionsWithSortedAvailableOptions.map(function (sortedRestOptionWithoutStandar) {
        return __assign(__assign({}, sortedRestOptionWithoutStandar), { availableOptions: sortedRestOptionWithoutStandar.availableOptions.filter(function (option) { return !option.standard; }) });
    });
    var selectedRestOptionsWithoutStandard = sortedRestOptionsWithoutStandard
        .filter(function (option) { return option.availableOptions.length > 0; })
        .map(function (option) {
        return __assign(__assign({}, option), { availableOptions: option.availableOptions.filter(function (availableOption) {
                return offer.vehicle.selectedOptions.includes(availableOption.id);
            }) });
    })
        .filter(function (element) { return element.availableOptions.length > 0; });
    // inne poza pakietami i pozostałymi z akcesorii
    var restAccessories = accessories ? accessories.filter(function (accessory) {
        return accessory.category.toUpperCase() !== 'POZOSTAŁE' && accessory.category.toUpperCase() !== 'PAKIETY';
    }) : null;
    var sortedRestAccessories = restAccessories
        ? immutableSort(restAccessories, function (accessoryA, accessoryB) {
            return accessoryA.category.localeCompare(accessoryB.category);
        })
        : [];
    var sortedRestAccessoriesWithSortedOptions = sortedRestAccessories.map(function (accessory) {
        return __assign(__assign({}, accessory), { accessories: immutableSort(accessory.accessories, function (accessoryA, accessoryB) {
                return accessoryA.name.localeCompare(accessoryB.name);
            }) });
    });
    var sortedRestAccessoriesWithoutStandard = sortedRestAccessoriesWithSortedOptions.map(function (sortedRestAccessoryWithSortedOptions) {
        return __assign(__assign({}, sortedRestAccessoryWithSortedOptions), { accessories: sortedRestAccessoryWithSortedOptions.accessories.filter(function (option) { return !option.standard; }) });
    });
    var selectedRestAccessoriesWithoutStandard = sortedRestAccessoriesWithoutStandard
        .filter(function (option) { return option.accessories.length > 0; })
        .map(function (option) {
        return __assign(__assign({}, option), { accessories: option.accessories.filter(function (accessory) {
                return offer.vehicle.selectedOptions.includes(accessory.id);
            }) });
    })
        .filter(function (element) { return element.accessories.length > 0; });
    // pozostałe z opcji nie w w standardzie
    var remainingOptions = options.find(function (option) { return option.category.toUpperCase() === 'POZOSTAŁE'; });
    var sortedRemainingAvailableOptions = remainingOptions
        ? immutableSort(remainingOptions.availableOptions, function (availableOptionA, availableOptionB) {
            return availableOptionA.name.localeCompare(availableOptionB.name);
        })
        : [];
    var remainingsOptions = remainingOptions && __assign(__assign({}, remainingOptions), { availableOptions: sortedRemainingAvailableOptions });
    var sortedRemainingOptionsWithoutStandard = remainingsOptions && __assign(__assign({}, remainingsOptions), { availableOptions: remainingsOptions.availableOptions.filter(function (option) { return !option.standard; }) });
    var selectedRemainingsOptionsWithoutStandard = sortedRemainingOptionsWithoutStandard &&
        sortedRemainingOptionsWithoutStandard.availableOptions.length > 0 &&
        sortedRemainingOptionsWithoutStandard.availableOptions.some(function (availableOption) {
            return offer.vehicle.selectedOptions.includes(availableOption.id);
        })
        ? __assign(__assign({}, sortedRemainingOptionsWithoutStandard), { availableOptions: sortedRemainingOptionsWithoutStandard.availableOptions.filter(function (availableOption) { return offer.vehicle.selectedOptions.includes(availableOption.id); }) }) : undefined;
    // pozostałe z akcesorii
    var remainingAccessories = accessories ? accessories.find(function (accessory) { return accessory.category.toUpperCase() === 'POZOSTAŁE'; }) : null;
    var sortedRemainingAccessories = remainingAccessories
        ? immutableSort(remainingAccessories.accessories, function (accessoryA, accessoryB) {
            return accessoryA.name.localeCompare(accessoryB.name);
        })
        : [];
    var remainingsAccessories = remainingAccessories && __assign(__assign({}, remainingAccessories), { accessories: sortedRemainingAccessories });
    var sortedRemainingAccessoriesWithoutStandard = remainingsAccessories && __assign(__assign({}, remainingsAccessories), { accessories: remainingsAccessories.accessories.filter(function (option) { return !option.standard; }) });
    var selectedRemainingsAccessoriesWithoutStandard = sortedRemainingAccessoriesWithoutStandard &&
        sortedRemainingAccessoriesWithoutStandard.accessories.length > 0 &&
        sortedRemainingAccessoriesWithoutStandard.accessories.some(function (accessory) {
            return offer.vehicle.selectedOptions.includes(accessory.id);
        })
        ? __assign(__assign({}, sortedRemainingAccessoriesWithoutStandard), { accessories: sortedRemainingAccessoriesWithoutStandard.accessories.filter(function (accessory) {
                return offer.vehicle.selectedOptions.includes(accessory.id);
            }) }) : undefined;
    var selectedWithoutStandard = __spreadArrays(selectedRestOptionsWithoutStandard, selectedRestAccessoriesWithoutStandard);
    if (selectedRemainingsOptionsWithoutStandard) {
        selectedWithoutStandard.push(selectedRemainingsOptionsWithoutStandard);
    }
    if (selectedRemainingsAccessoriesWithoutStandard) {
        selectedWithoutStandard.push(selectedRemainingsAccessoriesWithoutStandard);
    }
    var selectedOptionsWithoutStandard = selectedRemainingsOptionsWithoutStandard
        ? __spreadArrays(selectedRestOptionsWithoutStandard, [selectedRemainingsOptionsWithoutStandard]) : __spreadArrays(selectedRestOptionsWithoutStandard);
    var selectedAccessoriesWithoutStandard = selectedRemainingsAccessoriesWithoutStandard
        ? __spreadArrays(selectedRestAccessoriesWithoutStandard, [selectedRemainingsAccessoriesWithoutStandard]) : __spreadArrays(selectedRestAccessoriesWithoutStandard);
    var shouldAdditionalOptionsShow = (sortedRestOptionsWithoutStandard &&
        sortedRestOptionsWithoutStandard.some(function (element) {
            return element.availableOptions.length > 0 &&
                element.availableOptions.some(function (option) { return offer.vehicle.selectedOptions.includes(option.id); });
        })) ||
        (sortedRemainingOptionsWithoutStandard &&
            sortedRemainingOptionsWithoutStandard.availableOptions.length > 0 &&
            sortedRemainingOptionsWithoutStandard.availableOptions.some(function (option) {
                return offer.vehicle.selectedOptions.includes(option.id);
            })) ||
        (sortedRestAccessoriesWithoutStandard &&
            sortedRestAccessoriesWithoutStandard.some(function (element) {
                return element.accessories.length > 0 &&
                    element.accessories.some(function (accessory) { return offer.vehicle.selectedOptions.includes(accessory.id); });
            })) ||
        (sortedRemainingAccessoriesWithoutStandard && sortedRemainingAccessoriesWithoutStandard.accessories.length > 0 &&
            sortedRemainingAccessoriesWithoutStandard.accessories.some(function (accessory) { return offer.vehicle.selectedOptions.includes(accessory.id); }));
    // inne opcje poza pakietami i pozostałymi w standardzie
    var sortedRestOptionsWithStandard = sortedRestOptionsWithSortedAvailableOptions.map(function (sortedRestOptionWithoutStandar) {
        return __assign(__assign({}, sortedRestOptionWithoutStandar), { availableOptions: sortedRestOptionWithoutStandar.availableOptions.filter(function (option) { return option.standard; }) });
    });
    var selectedRestOptionsWithStandard = sortedRestOptionsWithStandard
        .filter(function (option) { return option.availableOptions.length > 0; })
        .map(function (option) {
        return __assign(__assign({}, option), { availableOptions: option.availableOptions.filter(function (availableOption) {
                return offer.vehicle.selectedOptions.includes(availableOption.id);
            }) });
    })
        .filter(function (element) { return element.availableOptions.length > 0; });
    // pozostałe z opcji w standardzie
    var sortedRemainingOptionsWithStandard = remainingsOptions && __assign(__assign({}, remainingsOptions), { availableOptions: remainingsOptions.availableOptions.filter(function (option) { return option.standard; }) });
    var selectedRemainingsOptionsWithStandard = sortedRemainingOptionsWithStandard && sortedRemainingOptionsWithStandard.availableOptions.length > 0
        ? __assign(__assign({}, sortedRemainingOptionsWithStandard), { availableOptions: sortedRemainingOptionsWithStandard.availableOptions.filter(function (availableOption) { return offer.vehicle.selectedOptions.includes(availableOption.id); }) }) : undefined;
    // standardowe opcje do wyświetlenia
    var seletedOptionsWithStandard = selectedRemainingsOptionsWithStandard
        ? __spreadArrays(selectedRestOptionsWithStandard, [selectedRemainingsOptionsWithStandard]) : __spreadArrays(selectedRestOptionsWithStandard);
    var shouldStandardOptionsShow = (sortedRestOptionsWithStandard &&
        sortedRestOptionsWithStandard.some(function (element) {
            return element.availableOptions.length > 0 &&
                element.availableOptions.some(function (option) { return offer.vehicle.selectedOptions.includes(option.id); });
        })) ||
        (sortedRemainingOptionsWithStandard &&
            sortedRemainingOptionsWithStandard.availableOptions.length > 0 &&
            sortedRemainingOptionsWithoutStandard.availableOptions.some(function (option) {
                return offer.vehicle.selectedOptions.includes(option.id);
            }));
    var selectedOptionsPackages = packageOptions && packageOptions.availableOptions.filter(function (packageOpt) {
        return selectedPackages.includes(packageOpt.id);
    });
    var selectedAccessoriesPackages = packageAccessories && packageAccessories.accessories.filter(function (packageAcc) {
        return selectedAccessoryPackages.includes(packageAcc.id);
    });
    var totalOptionsPackages = selectedOptionsPackages && selectedOptionsPackages.length > 0
        ? selectedOptionsPackages.reduce(function (acc, curr) { return acc + curr.price; }, 0)
        : 0;
    var totalAcceessoriesPackages = selectedAccessoriesPackages && selectedAccessoriesPackages.length > 0
        ? selectedAccessoriesPackages.reduce(function (acc, curr) { return acc + curr.price; }, 0)
        : 0;
    var selectedOptionsWithoutStandardAvailableOptions = selectedOptionsWithoutStandard.map(function (option) {
        return option.availableOptions;
    }).flat();
    var selectedOptionsPackagesWithoutStandard = selectedOptionsPackages ? selectedOptionsPackages.filter(function (packageOption) { return !packageOption.standard; }) : [];
    var allAdditionalOptions = __spreadArrays((selectedOptionsPackagesWithoutStandard ? selectedOptionsPackagesWithoutStandard : []), (selectedOptionsWithoutStandardAvailableOptions ? selectedOptionsWithoutStandardAvailableOptions : []));
    var allAdditionalOptionsSum = allAdditionalOptions && allAdditionalOptions.length > 0 ? allAdditionalOptions.reduce(function (acc, curr) { return acc + curr.price; }, 0) : 0;
    var maxAdditionalOptionsLimitExcedeed = allAdditionalOptionsSum > maxAdditionalOptionsLimit;
    var additionalOptionsLimitExcedeed = allAdditionalOptionsSum > additionalOptionsLimit;
    var employeeLeasingInstallment = useEmployeeLeasingInstallment({ offerId: offer.id }).employeeLeasingInstallment;
    return (React.createElement("div", { className: "summaryTabWrapper" },
        React.createElement("div", { className: "basicInformationWrapper", "data-testid": "basicPricesInforation" },
            React.createElement(List.Root, null,
                basicInfo.map(function (infoElement) { return (infoElement.sectionVisible ?
                    React.createElement(List.Item, { cursor: "default", key: infoElement.category },
                        React.createElement(List.LayoutSummaryBasicInfo, null,
                            React.createElement("div", { className: "basicInfoCategoryName" },
                                React.createElement(Label, { bold: true, uppercase: true }, infoElement.category)),
                            React.createElement("div", { className: "basicInfoElements" }, infoElement.elements.map(function (element, index) { return (element.visible ?
                                React.createElement("div", { className: "basicInfoElementWrapper", key: index },
                                    React.createElement("div", { className: "elementLabel" },
                                        React.createElement(Label, null, element.label)),
                                    React.createElement("div", { className: "elementNetAmount" },
                                        React.createElement(NetTextAmountSummary, null, element.netAmount)),
                                    React.createElement("div", { className: "elementGrossAmount" },
                                        React.createElement(TextAmount, { bold: true }, element.grossAmount)))
                                : null); })))) : null); }),
                React.createElement(BasicInformationSummary, { netTotal: offer.priceVariant.totalPriceNet, grossTotal: offer.priceVariant.totalPriceGross }),
                isEmployeeLeasingUser() ? (React.createElement(List.Item, { cursor: "default" },
                    React.createElement(List.LayoutSummaryBasicInfo, null,
                        React.createElement("div", { className: "basicInfoCategoryName" },
                            React.createElement(Label, { bold: true, uppercase: true }, "miesi\u0119czna rata")),
                        React.createElement("div", { className: "basicInfoElements" },
                            React.createElement("div", { className: "basicInfoElementWrapper" },
                                React.createElement("div", { className: "elementLabel" }),
                                React.createElement("div", { className: "elementNetAmount" }),
                                React.createElement("div", { className: "elementGrossAmount", "data-testid": 'employee-leasing-installment-gross' },
                                    React.createElement(TextAmount, { bold: true }, employeeLeasingInstallment &&
                                        employeeLeasingInstallment.employeeLeasingInstallmentPriceGross
                                        ? employeeLeasingInstallment.employeeLeasingInstallmentPriceGross
                                        : 0))))))) : null)),
        React.createElement("div", { className: "technicalDataAndEnvImpact" },
            React.createElement("div", { className: "technicalDataWrapper", "data-testid": "technicalData" },
                React.createElement(TermValueTable.Header, null, "podstawowe dane techniczne"),
                React.createElement(TermValueTable.TwoColumnSummaryLayout, null,
                    React.createElement(TermValueTable.Column, null, basicTechnicalDataLeftColumn.map(function (termValuePair, index) { return (React.createElement(TermValueTable.Row, { key: index, term: termValuePair.term, value: termValuePair.value })); })),
                    React.createElement(TermValueTable.Column, null, basicTechnicalDataRigthColumn.map(function (termValuePair, index) { return (React.createElement(TermValueTable.Row, { key: index, term: termValuePair.term, value: termValuePair.value })); })))),
            React.createElement("div", { className: "envImpactWrapper", "data-testid": "envImpactData" },
                React.createElement(TermValueTable.Header, null, "wp\u0142yw na \u015Brodowisko"),
                React.createElement(TermValueTable.TwoColumnSummaryLayout, null,
                    (offer.vehicle.fuelName.includes('Benzyna') ||
                        offer.vehicle.fuelName.includes('Diesel')) && (React.createElement(React.Fragment, null,
                        React.createElement(TermValueTable.Column, null, mildHybridWltp.leftColumn.map(function (termValuePair, index) { return (React.createElement(TermValueTable.Row, { key: index, term: termValuePair.term, value: termValuePair.value })); })),
                        React.createElement(TermValueTable.Column, null, mildHybridWltp.rightColumn.map(function (termValuePair, index) { return (React.createElement(TermValueTable.Row, { key: index, term: termValuePair.term, value: termValuePair.value })); })))),
                    offer.vehicle.fuelName.includes('Elektryczny/benzynowy') && (React.createElement(React.Fragment, null,
                        React.createElement(TermValueTable.Column, null, plugInHybridWltp.leftColumn.map(function (termValuePair, index) { return (React.createElement(TermValueTable.Row, { key: index, term: termValuePair.term, value: termValuePair.value })); })),
                        React.createElement(TermValueTable.Column, null, plugInHybridWltp.rightColumn.map(function (termValuePair, index) { return (React.createElement(TermValueTable.Row, { key: index, term: termValuePair.term, value: termValuePair.value })); })))),
                    /Elektryczny$/.test(offer.vehicle.fuelName) && (React.createElement(React.Fragment, null,
                        React.createElement(TermValueTable.Column, null, electricWltp.leftColumn.map(function (termValuePair, index) { return (React.createElement(TermValueTable.Row, { key: index, term: termValuePair.term, value: termValuePair.value })); })),
                        React.createElement(TermValueTable.Column, null, electricWltp.rightColumn.map(function (termValuePair, index) { return (React.createElement(TermValueTable.Row, { key: index, term: termValuePair.term, value: termValuePair.value })); }))))))),
        React.createElement("div", { className: "equipmentWrapper", "data-testid": "mainStandardEquipment" },
            React.createElement(Accordion.Root, { defaultValue: ['mainStandardEquipment'] },
                React.createElement(Accordion.Item, { value: "mainStandardEquipment" },
                    React.createElement(Accordion.Trigger, null, "prezentacja wyposa\u017Cenia konfiguracji"),
                    React.createElement(Accordion.Content, null,
                        React.createElement(List.Root, null,
                            standardData.map(function (equipment) { return (React.createElement(List.Item, { key: equipment.category, cursor: "auto" },
                                React.createElement(List.LayoutStandardEquipment, null,
                                    React.createElement(Label, { uppercase: true, bold: true }, equipment.name),
                                    React.createElement(Label, null, equipment.value),
                                    React.createElement(ListItemElement.OptionCode, { gridColumnStart: 3 }, equipment.code),
                                    equipment.price === 0 || equipment.price === '' ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, equipment.price))))); }),
                            interiorFinish.content.length > 0 ? (React.createElement(List.Item, { key: interiorFinish.category, cursor: "auto" },
                                React.createElement(List.LayoutStandardEquipmentForInteriorFinish, null,
                                    React.createElement(Label, { uppercase: true, bold: true }, "wyko\u0144czenie wn\u0119trza"),
                                    React.createElement("div", { className: "interiorFinishWrapper" }, interiorFinish.content.map(function (interiorFinishEl) { return (React.createElement("div", { className: "interiorFinishElement", key: interiorFinishEl.id },
                                        React.createElement("div", null, interiorFinishEl.name),
                                        React.createElement("div", { style: { justifySelf: 'end' } }, interiorFinishEl.id),
                                        React.createElement("div", null))); }))))) : null))))),
        React.createElement("div", { className: "packagesAndOptionsWrapper" },
            React.createElement(Accordion.Root, { defaultValue: ['packages', 'additionalOptions'] },
                (packageOptions && selectedPackages.length > 0) ||
                    (packageAccessories && selectedAccessoryPackages.length > 0) ? (React.createElement(Accordion.Item, { value: "packages" },
                    React.createElement(Accordion.Trigger, null, "pakiety"),
                    React.createElement(Accordion.Content, null,
                        React.createElement(List.Root, null,
                            packages && packages.availableOptions.map(function (availableOption) {
                                return offer.vehicle.selectedOptions.includes(availableOption.id) ? (React.createElement(List.Item, { isPrice: availableOption.price !== null, key: availableOption.id, cursor: "default" },
                                    React.createElement(List.LayoutWithCodeAndChips, { isSummary: true },
                                        React.createElement(Checkbox.Layout, null,
                                            React.createElement(Checkbox.Placeholder, null),
                                            React.createElement(Label, { htmlFor: availableOption.id }, availableOption.name)),
                                        React.createElement(ListItemElement.OptionCode, null, availableOption.optionNo),
                                        React.createElement(ListItemElement.Chips, null,
                                            React.createElement(ChipPlaceholder, null),
                                            React.createElement(ChipPlaceholder, null)),
                                        React.createElement(ListItemElement.Price, null, availableOption.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, availableOption.price)) : availableOption.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, availableOption.price)))),
                                    isPackageWithExchangableOptions(availableOption) && offer.vehicle.packageVariants.length > 0 ?
                                        React.createElement(PackageContent.Root, { isSummary: true }, availableOption.content.map(function (element) { return (element.token ?
                                            isSubOptionSelected(availableOption.id, element.id) ?
                                                React.createElement(PackageContent.Element, { key: element.id, name: element.name, id: element.optionNo })
                                                : null
                                            :
                                                React.createElement(PackageContent.Element, { key: element.id, name: element.name, id: element.optionNo })); })) :
                                        React.createElement(PackageContent.Root, { isSummary: true }, availableOption.content.map(function (element) { return (React.createElement(PackageContent.Element, { key: element.id, name: element.name, id: element.optionNo })); })))) : null;
                            }),
                            sortedPackageAccessories && sortedPackageAccessories.map(function (accessory) {
                                return offer.vehicle.selectedOptions.includes(accessory.id) ? (React.createElement(List.Item, { isPrice: accessory.price !== null, key: accessory.id, cursor: "default" },
                                    React.createElement(List.LayoutWithCodeAndChips, { isSummary: true },
                                        React.createElement(Checkbox.Layout, null,
                                            React.createElement(Checkbox.Placeholder, null),
                                            React.createElement(Label, { htmlFor: accessory.id }, accessory.name)),
                                        React.createElement(ListItemElement.OptionCode, null, accessory.optionNo),
                                        React.createElement(ListItemElement.Chips, null,
                                            React.createElement(ChipPlaceholder, null),
                                            React.createElement(ChipPlaceholder, null)),
                                        React.createElement(ListItemElement.Price, null, accessory.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, accessory.price)) : accessory.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, accessory.price)))),
                                    React.createElement(PackageContent.Root, { isSummary: true }, accessory.content.map(function (element) { return (React.createElement(PackageContent.Element, { key: element.id, name: element.name, id: element.optionNo })); })))) : null;
                            }),
                            React.createElement(List.Item, null,
                                React.createElement(PackagesSummary, { total: totalOptionsPackages + totalAcceessoriesPackages })))))) : null,
                shouldAdditionalOptionsShow ? (React.createElement(Accordion.Item, { value: 'additionalOptions', key: 'additionalOptions' },
                    React.createElement(Accordion.Trigger, null, "opcje dodatkowe"),
                    React.createElement(Accordion.Content, null,
                        React.createElement(Accordion.Root, { defaultValue: selectedWithoutStandard.map(function (option) { return option.category + "-nonStandard"; }) },
                            selectedOptionsWithoutStandard.map(function (selectedOptionWithoutStandard) { return (React.createElement(List.Root, { key: selectedOptionWithoutStandard.category + "-nonStandard" },
                                React.createElement(Accordion.Item, { value: selectedOptionWithoutStandard.category + "-nonStandard", className: 'withoutBorderBottom' },
                                    React.createElement(Accordion.TriggerForSummaryAccordion, { total: selectedOptionWithoutStandard.availableOptions.reduce(function (acc, curr) { return acc + curr.price; }, 0) }, selectedOptionWithoutStandard.category),
                                    React.createElement(Accordion.Content, null, selectedOptionWithoutStandard.availableOptions.map(function (option) { return (React.createElement(List.Item, { isPrice: option.price !== null, key: option.id, cursor: "default", reverseBackground: true },
                                        React.createElement(List.LayoutWithCodeAndChips, { isSummary: true },
                                            React.createElement(Checkbox.Layout, null,
                                                React.createElement(Checkbox.Placeholder, null),
                                                React.createElement(Label, { htmlFor: option.id }, option.name)),
                                            React.createElement(ListItemElement.OptionCode, null, option.optionNo),
                                            React.createElement(ListItemElement.Chips, null,
                                                React.createElement(ChipPlaceholder, null),
                                                React.createElement(ChipPlaceholder, null)),
                                            React.createElement(ListItemElement.Price, null, option.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, option.price)) : option.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, option.price)))))); }))))); }),
                            selectedAccessoriesWithoutStandard.map(function (selectedAccessoryWithoutStandard) { return (React.createElement(List.Root, { key: selectedAccessoryWithoutStandard.category + "-nonStandard" },
                                React.createElement(Accordion.Item, { value: selectedAccessoryWithoutStandard.category + "-nonStandard", className: 'withoutBorderBottom' },
                                    React.createElement(Accordion.TriggerForSummaryAccordion, { total: offer.vehicle.selectedAccessories.length > 0 ? selectedAccessoryWithoutStandard.accessories.reduce(function (acc, curr) {
                                            return acc +
                                                offer.vehicle.selectedAccessories.find(function (element) { return element.id === curr.id; }).price;
                                        }, 0) : selectedAccessoryWithoutStandard.accessories.reduce(function (acc, curr) { return acc + curr.price; }, 0) }, selectedAccessoryWithoutStandard.category),
                                    React.createElement(Accordion.Content, null, selectedAccessoryWithoutStandard.accessories.map(function (option) { return (React.createElement(List.Item, { isPrice: option.price !== null, key: option.id, cursor: "default", reverseBackground: true },
                                        React.createElement(List.LayoutWithCodeAndChips, { isSummary: true },
                                            React.createElement(Checkbox.Layout, null,
                                                React.createElement(Checkbox.Placeholder, null),
                                                React.createElement(Label, { htmlFor: option.id }, option.name)),
                                            React.createElement(ListItemElement.OptionCode, null, option.optionNo),
                                            React.createElement(ListItemElement.Chips, null,
                                                React.createElement(ChipPlaceholder, null),
                                                React.createElement(ChipPlaceholder, null)),
                                            React.createElement(ListItemElement.Price, null,
                                                React.createElement(TextAmount, { bold: true }, offer.vehicle.selectedAccessories.length > 0 ? offer.vehicle.selectedAccessories.find(function (accessory) { return accessory.id === option.id; }).price : option.price))))); }))))); }))))) : null,
                shouldStandardOptionsShow ? (React.createElement(Accordion.Item, { value: 'standardOptions', key: 'standardOptions' },
                    React.createElement(Accordion.Trigger, null, "opcje standardowe"),
                    React.createElement(Accordion.Content, null,
                        React.createElement(Accordion.Root, { defaultValue: seletedOptionsWithStandard.map(function (option) { return option.category + "-standard"; }) }, seletedOptionsWithStandard.map(function (seletedOptionWithStandard) { return (React.createElement(List.Root, { key: seletedOptionWithStandard.category + "-standard" },
                            React.createElement(Accordion.Item, { value: seletedOptionWithStandard.category + "-standard", className: 'withoutBorderBottom' },
                                React.createElement(Accordion.TriggerForSummaryAccordion, { total: seletedOptionWithStandard.availableOptions.reduce(function (acc, curr) { return acc + curr.price; }, 0) }, seletedOptionWithStandard.category),
                                React.createElement(Accordion.Content, null, seletedOptionWithStandard.availableOptions.map(function (option) { return (React.createElement(List.Item, { isPrice: option.price !== null, key: option.id, cursor: "default", reverseBackground: true },
                                    React.createElement(List.LayoutWithCodeAndChips, { isSummary: true },
                                        React.createElement(Checkbox.Layout, null,
                                            React.createElement(Checkbox.Placeholder, null),
                                            React.createElement(Label, { htmlFor: option.id }, option.name)),
                                        React.createElement(ListItemElement.OptionCode, null, option.optionNo),
                                        React.createElement(ListItemElement.Chips, null,
                                            React.createElement(ChipPlaceholder, null),
                                            React.createElement(ChipPlaceholder, null)),
                                        React.createElement(ListItemElement.Price, null, option.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, option.price)) : option.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, option.price)))))); }))))); }))))) : null)),
        React.createElement("div", { className: "additionalInformationWrapper" },
            React.createElement(AccordionHeaderPlaceholder, null, "informacje dodatkowe"),
            React.createElement("div", { className: "additionalInformationTextAreaWrapper" },
                React.createElement(TextArea, { id: 'additionaInformation', name: 'additionaInformation', value: additionalInformation, onChange: onChangeAdditionalInformation, onBlur: onBlurAdditionalInformation, placeholder: "Wprowad\u017A komentarz", accessibleLabel: 'Dodatkowe informacje' }))),
        React.createElement("div", { className: "buttonsWrapper" },
            React.createElement("div", { className: "buttons" }, isEmployeeLeasingUser() ?
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'backButton' },
                        React.createElement(Button, { variant: 'secondary', onClick: onBackClick }, "powr\u00F3t")),
                    React.createElement("div", { className: 'sendButton' },
                        React.createElement(Button, { variant: 'primary', onClick: onSendClick, disabled: maxAdditionalOptionsLimitExcedeed }, "wy\u015Blij")))
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "getSheetButton" },
                            React.createElement(Button, { variant: "secondary", onClick: onGetSheetClick }, "pobierz arkusz")),
                        clientId || selectedParty ?
                            React.createElement("div", { className: 'registerButton' },
                                React.createElement(Button, { variant: 'primary', onClick: onRegisterClick }, "zarejestruj ofert\u0119"))
                            : null))),
        isEmployeeLeasingUser() ?
            React.createElement("div", { className: 'employeeLeasingLimitsMessages' },
                maxAdditionalOptionsLimitExcedeed ?
                    React.createElement("div", { style: { color: 'var(--validate-color)' }, "data-testid": 'max-add-options-limit-el-exceeded' }, MAX_ADDITIONAL_OPTIONS_LIMIT_EXCEEDED_MESSAGE)
                    : null,
                additionalOptionsLimitExcedeed && !maxAdditionalOptionsLimitExcedeed ?
                    React.createElement("div", { "data-testid": 'add-options-limit-el-exceeded' }, ADDITIONAL_OPTIONS_LIMIT_EXCEEDED_MESSAGE)
                    : null)
            : null));
});
