var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { Button } from 'components/button/Button.component';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { Label } from 'components/label/Label.component';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { usePriceListApi } from 'pages/priceListImport/usePriceListApi';
import React, { useEffect, useState } from 'react';
import './generateOrEditPriceList.css';
import InputMask from 'react-input-mask';
import { useHistory, useLocation } from 'react-router-dom';
import { useStatus } from 'common/statusContext';
import ReactDOM from 'react-dom';
import SessionStorage from 'common/sessionStorage';
export var GenerateOrEditPriceList = withCancellation(function (_a) {
    var handleGoToNextWizardStep = _a.handleGoToNextWizardStep, handleCurrentPriceListNameChange = _a.handleCurrentPriceListNameChange, handleGoToStepByValue = _a.handleGoToStepByValue;
    var _b = useState('CREATE'), importMode = _b[0], setImportMode = _b[1];
    var _c = useState(undefined), priceLists = _c[0], setPriceLists = _c[1];
    var _d = useState(undefined), selectedPriceListToEdit = _d[0], setSelectedPriceListToEdit = _d[1];
    var _e = useState(''), newPriceListName = _e[0], setNewPriceList = _e[1];
    var isEmployeeLeasing = SessionStorage.get('employeeLeasingContext');
    var history = useHistory();
    var priceListsApi = isEmployeeLeasing ? usePriceListApi({ context: 'employeeLeasing' }) : usePriceListApi();
    var setStatus = useStatus().setStatus;
    var location = useLocation();
    var priceListsDropdwonOptions = priceLists
        ? priceLists.map(function (priceList) {
            return {
                value: priceList.priceList,
                label: priceList.priceList,
            };
        })
        : [];
    function handleOnImportModeChange(newImportMode) {
        setImportMode(newImportMode);
        if (newImportMode === 'CREATE') {
            setSelectedPriceListToEdit(undefined);
        }
        if (newImportMode === 'EDIT') {
            setNewPriceList('');
        }
    }
    function handleOnCancelClick() {
        SessionStorage.remove('employeeLeasingContext');
        history.push('/price-lists');
    }
    function handlePriceListOptionChange(value) {
        setSelectedPriceListToEdit(value);
    }
    function handleNewPriceListNameChange(event) {
        setNewPriceList(event.currentTarget.value);
    }
    function validatePriceListName(priceListName) {
        return !priceListName.includes('_');
    }
    function handleOnNextStepButtonClick() {
        return __awaiter(this, void 0, void 0, function () {
            var priceListName, createPriceResponse, priceListEditResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(importMode === 'CREATE' && newPriceListName && validatePriceListName(newPriceListName))) return [3 /*break*/, 4];
                        priceListName = newPriceListName.replace(/\s/g, '');
                        handleCurrentPriceListNameChange(priceListName);
                        setStatus('pending');
                        return [4 /*yield*/, priceListsApi.createPrice(priceListName)];
                    case 1:
                        createPriceResponse = _a.sent();
                        if (!(createPriceResponse && createPriceResponse.status === 200)) return [3 /*break*/, 2];
                        setStatus('success');
                        handleGoToNextWizardStep();
                        return [3 /*break*/, 4];
                    case 2:
                        setStatus('error');
                        return [4 /*yield*/, priceListsApi.cancelImport(priceListName)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        if (!(importMode === 'EDIT' && selectedPriceListToEdit)) return [3 /*break*/, 8];
                        handleCurrentPriceListNameChange(selectedPriceListToEdit);
                        setStatus('pending');
                        return [4 /*yield*/, priceListsApi.putPriceListEditImport(selectedPriceListToEdit)];
                    case 5:
                        priceListEditResponse = _a.sent();
                        if (!(priceListEditResponse && priceListEditResponse.status === 200)) return [3 /*break*/, 6];
                        setStatus('success');
                        handleGoToNextWizardStep();
                        return [3 /*break*/, 8];
                    case 6:
                        setStatus('error');
                        return [4 /*yield*/, priceListsApi.cancelImport(selectedPriceListToEdit)];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        function getPriceLists() {
            return __awaiter(this, void 0, void 0, function () {
                var importStatusResponse, priceListsToEditResponse_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, priceListsApi.getImportStatus()];
                        case 1:
                            importStatusResponse = _a.sent();
                            handleCurrentPriceListNameChange(importStatusResponse[0].name);
                            if (!(importStatusResponse[0].status === 'DOWNLOAD' && importStatusResponse[0].step === 'IMPORT')) return [3 /*break*/, 2];
                            setStatus('success');
                            handleGoToStepByValue('import-car-models');
                            return [3 /*break*/, 7];
                        case 2:
                            if (!(importStatusResponse[0].status === 'DOWNLOADED' && importStatusResponse[0].step === 'MODELS')) return [3 /*break*/, 3];
                            setStatus('success');
                            handleGoToStepByValue('range-of-models');
                            return [3 /*break*/, 7];
                        case 3:
                            if (!(importStatusResponse[0].status === 'DOWNLOAD' && importStatusResponse[0].step === 'MODELS')) return [3 /*break*/, 4];
                            setStatus('success');
                            handleGoToStepByValue('import-pricelist-data');
                            return [3 /*break*/, 7];
                        case 4:
                            if (!(importStatusResponse[0].status === 'DOWNLOADED' && importStatusResponse[0].step === 'SUMMARY')) return [3 /*break*/, 5];
                            setStatus('success');
                            handleGoToStepByValue('summary');
                            return [3 /*break*/, 7];
                        case 5: return [4 /*yield*/, priceListsApi.getPriceLists(999)];
                        case 6:
                            priceListsToEditResponse_1 = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setPriceLists(priceListsToEditResponse_1.content);
                                setStatus('success');
                            });
                            _a.label = 7;
                        case 7: return [2 /*return*/];
                    }
                });
            });
        }
        function getStatusAndGoToTheCurrentStep() {
            return __awaiter(this, void 0, void 0, function () {
                var importStatusResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, priceListsApi.getImportStatus()];
                        case 1:
                            importStatusResponse = _a.sent();
                            switch (importStatusResponse[0].step) {
                                case 'GENERATE':
                                    handleGoToStepByValue('generate-or-edit');
                                    break;
                                case 'IMPORT':
                                    handleGoToStepByValue('import-car-models');
                                    break;
                                case 'MODELS':
                                    handleGoToStepByValue('range-of-models');
                                    break;
                                case 'SUMMARY':
                                    handleGoToStepByValue('summary');
                                    break;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (location.state && location.state.priceListName) {
            handleCurrentPriceListNameChange(location.state.priceListName);
            getStatusAndGoToTheCurrentStep();
        }
        else {
            getPriceLists();
        }
    }, []);
    var isNextStepButtonDisabled = (importMode === 'CREATE' && newPriceListName && validatePriceListName(newPriceListName)) ||
        (importMode === 'EDIT' && selectedPriceListToEdit);
    return (React.createElement("div", { className: "generateOrEditWrapper", "data-testid": "generate-or-edit-wrapper" },
        React.createElement("div", { className: "generateOrEditRadioGroup" },
            React.createElement(RadioGroup.Root, { value: importMode, onValueChange: handleOnImportModeChange },
                React.createElement("div", { className: "radioGroupElements" },
                    React.createElement("div", { className: "generateOrEditRadio" },
                        React.createElement(RadioGroup.Layout, null,
                            React.createElement(RadioGroup.Item, { value: "CREATE", id: "create" },
                                React.createElement(RadioGroup.Indicator, null)),
                            React.createElement(Label, { htmlFor: "create", cursor: "pointer" }, "Utw\u00F3rz cennik")),
                        importMode === 'CREATE' ? (React.createElement("div", { className: "createNewPriceListInputWrapper" },
                            React.createElement(InputMask, { mask: "MY 9 9 9 9 W 9 9", maskChar: "_", value: newPriceListName, onChange: handleNewPriceListNameChange, placeholder: "MY _ _ _ _ W _ _ ", className: "createNewPriceListNameInput " + (newPriceListName && validatePriceListName(newPriceListName)
                                    ? 'validate'
                                    : '') }))) : null),
                    React.createElement("div", { className: "generateOrEditRadio" },
                        React.createElement(RadioGroup.Layout, null,
                            React.createElement(RadioGroup.Item, { value: "EDIT", id: "edit" },
                                React.createElement(RadioGroup.Indicator, null)),
                            React.createElement(Label, { htmlFor: "edit", cursor: "pointer" }, "Edytuj istniej\u0105cy cennik")),
                        importMode === 'EDIT' ? (React.createElement("div", { className: "editPriceListDropdown" },
                            React.createElement(Dropdown, { options: priceListsDropdwonOptions, name: "priceListsDropdown", onChange: handlePriceListOptionChange, placeholder: "Wybierz cennik" }))) : null)))),
        React.createElement("div", { className: "generateOrEditActionsWrapper" },
            React.createElement("div", { className: "generateOrEditButtonWrapper" },
                React.createElement(Button, { type: "button", variant: "secondary", onClick: handleOnCancelClick }, "Anuluj")),
            React.createElement("div", { className: "generateOrEditButtonWrapper" },
                React.createElement(Button, { type: "button", onClick: handleOnNextStepButtonClick, disabled: !isNextStepButtonDisabled }, "Dalej")))));
});
