import { Tabs } from 'components/tabs/Tabs.component';
import React from 'react';
import { RequestSubjectPreview } from './RequestSubjectPreview';
export function PreviewRequestSubjectsSection(_a) {
    var requestSubjectSectionProp = _a.requestSubjectSectionProp, tabValue = _a.tabValue, onTabChange = _a.onTabChange;
    var requestSubjectSection = requestSubjectSectionProp;
    var RequestSubjectsTabs = requestSubjectSection.requestSubjects.length > 0 &&
        requestSubjectSection.requestSubjects.map(function (requestSubject, index) {
            return {
                value: index,
                name: requestSubject.car + " " + requestSubject.driver,
                content: React.createElement(RequestSubjectPreview, { requestSubject: requestSubject }),
            };
        });
    return (React.createElement("div", { className: 'previewRequestSubjectTabsWrapper' }, RequestSubjectsTabs ? (React.createElement("div", null,
        React.createElement(Tabs.Root, { value: tabValue, onValueChange: onTabChange },
            React.createElement(Tabs.List, { style: { paddingTop: 0, gap: 0, flexWrap: 'wrap' } }, RequestSubjectsTabs.map(function (tab, index) { return (React.createElement(Tabs.Trigger, { value: tab.value, key: index, style: { maxWidth: '190px', minWidth: '170px', height: '72px' }, borderBottom: true },
                React.createElement("span", { className: 'tabTriggerName' }, tab.name))); })),
            RequestSubjectsTabs.map(function (tab, index) { return (React.createElement(Tabs.Content, { value: tab.value, key: index },
                React.createElement("div", { id: "tabContentWrapper", className: "tabContentWrapper" }, tab.content))); })))) : null));
}
